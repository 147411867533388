import { DataDragon } from 'poro'

import { useVersion } from '@yordlelabs/shared/web/data-access/client'
import { useLocale } from '@yordlelabs/shared/web/util'

import { Patch, usePatchToVersion } from './patch'

const map = new Map<string, DataDragon>()

const types = ['locale', 'default'] as const
export type DataDragonType = (typeof types)[number]

export function useDataDragon(
  type: 'default' | 'locale',
  patch?: Patch,
): DataDragon | null
export function useDataDragon(patch?: Patch): DataDragon | null
export function useDataDragon(
  typeOrPatch?: DataDragonType | Patch,
  maybePatch?: Patch,
) {
  const hasType = (types as readonly any[]).includes(typeOrPatch)
  const type = hasType ? typeOrPatch : types[0]
  const patch = hasType ? maybePatch : typeOrPatch

  const version = usePatchToVersion(patch)
  const defaultVersion = useVersion()
  const locale = useLocale()
  if (!defaultVersion) return null

  return getDataDragon(
    version ?? defaultVersion,
    type === 'default' ? 'en_US' : locale,
  )
}

function getDataDragon(version: string, language: string) {
  const key = `/${version}/${language}`
  let ddragon = map.get(key)
  if (!ddragon) {
    ddragon = new DataDragon(version, language)
    map.set(key, ddragon)
  }
  return ddragon
}
