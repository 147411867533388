import { Dialog } from '@headlessui/react'
import clsx from 'clsx'

import { ClassName } from '../utils'

export interface ModalProps extends ClassName {
  isOpen: boolean
  close: () => void
}

export const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  close,
  className,
}) => {
  return (
    <Dialog open={isOpen} onClose={close} className="relative z-50">
      <Dialog.Overlay className="fixed inset-0 bg-black/40" />
      <div
        className={clsx(
          'fixed top-1/4 left-1/2 -translate-x-1/2 p-6 bg-surface rounded-xl shadow-3',
          className,
        )}
      >
        {children}
      </div>
    </Dialog>
  )
}
