import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

import { useTranslation } from '@yordlelabs/shared/web/util'

interface SelectProps<T>
  extends Omit<ComponentPropsWithoutRef<'select'>, 'onChange'> {
  name: string
  labelClassName?: string
  options: readonly T[]
  onChange: (value: T) => void
  getId?: (option: T) => string
}

export function Select<T extends string | number>({
  options,
  name,
  labelClassName,
  onChange,
  getId = String,
  ...restProps
}: SelectProps<T>) {
  const { t } = useTranslation()

  return (
    <label className="flex flex-col gap-2">
      <span
        id={name}
        className={clsx(
          'text-on-surface-variant typescale-label-medium',
          labelClassName,
        )}
      >
        {t(name)}
      </span>
      <select
        aria-labelledby={name}
        onChange={(e) => onChange(e.target.value as T)}
        className={'bg-surface text-on-surface typescale-label-large rounded'}
        {...restProps}
      >
        {options.map((option) => (
          <option value={option} key={option}>
            {getId(option)}
          </option>
        ))}
      </select>
    </label>
  )
}
