import { useTranslation } from '../../utils'
import { Link } from '../base'

export const Unlock: React.FC = () => {
  const { t } = useTranslation()

  return (
    <div className="typescale-body-medium text-on-surface-variant">
      {t('unlock')}
      <Link
        className="text-primary"
        href={process.env.NEXT_PUBLIC_SUBSCRIPTION!}
      >
        {t('subscribe')}
      </Link>
    </div>
  )
}
