import urlcat, { ParamMap } from 'urlcat'

function removeEmptyArrayProperty(obj: Record<string, any>) {
  Object.keys(obj).forEach((key) => {
    const value = obj[key]
    if (Array.isArray(value) && !value.length) {
      delete obj[key]
    }
  })

  return obj
}

const url = (baseTemplate: string, params: ParamMap = {}) => {
  removeEmptyArrayProperty(params)
  return urlcat(baseTemplate, params)
}

export const serverurl = (baseTemplate: string, params?: ParamMap) => {
  return url(process.env.NEXT_PUBLIC_SERVER + baseTemplate, params)
}
