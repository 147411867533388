import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

export interface CardProps extends ComponentPropsWithoutRef<'div'> {
  fit?: boolean
}
export const Card: React.FC<CardProps> = ({
  children,
  className,
  fit = false,
  ...props
}) => {
  return (
    <div
      className={clsx(
        'rounded-xl px-4 py-3 bg-surface1',
        fit && 'w-fit',
        { 'cursor-pointer select-none': props.onClick },
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}
