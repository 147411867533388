import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'
import { classed } from '@tw-classed/react'

export const Root = DropdownMenuPrimitive.Root

export const Trigger = DropdownMenuPrimitive.Trigger

export const Portal = DropdownMenuPrimitive.Portal

export const Content = classed(
  DropdownMenuPrimitive.Content,
  'overflow-hidden rounded-md bg-surface shadow-2 animate-in slide-in-from-top-1',
)

export const Item = classed(
  DropdownMenuPrimitive.Item,
  'flex cursor-pointer select-none items-center py-2 px-3 text-sm text-on-surface outline-none hover:bg-surface2',
)

export const Separator = classed(
  DropdownMenuPrimitive.Separator,
  'h-px bg-surface-variant',
)
