import { customAlphabet } from 'nanoid'

const alpha = Array.from(Array(26)).map((e, i) => i + 97)
const alphabet = alpha.map((x) => String.fromCharCode(x))
const ALPHA = Array.from(Array(26)).map((e, i) => i + 65)
const ALPHABET = ALPHA.map((x) => String.fromCharCode(x))
export const nanoid = customAlphabet(
  [...Array(10).keys(), ...alphabet, ...ALPHABET].join(''),
  8,
)
