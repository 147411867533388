import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'
import { TableOptions, useTable } from 'react-table'

type UseTableProps<T> = T extends object ? TableOptions<T> : never

type TableProps<T> = {
  caption?: React.ReactNode
  options: UseTableProps<T>
} & ComponentPropsWithoutRef<'table'>

export function Table<T>({
  caption,
  options,
  className,
  ...props
}: TableProps<T>) {
  const table = useTable(options)
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    table

  const showHeader = options.columns.some((c) => c.Header)

  return (
    <table
      className={clsx('w-full sm:w-fit', className)}
      {...props}
      {...getTableProps()}
    >
      {caption && (
        <caption className="mb-2 text-center typescale-title-small text-on-surface-variant">
          {caption}
        </caption>
      )}
      {showHeader && (
        <thead className="border-b border-surface-variant">
          {headerGroups.map((headerGroup) => (
            // eslint-disable-next-line react/jsx-key
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // eslint-disable-next-line react/jsx-key
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
      )}
      <tbody
        className="divide-y divide-surface-variant"
        {...getTableBodyProps()}
      >
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <tr {...row.getRowProps()}>
              {row.cells.map((cell, j) => (
                // eslint-disable-next-line react/jsx-key
                <td
                  className={clsx({
                    invisible:
                      j === 0 && rows[i - 1]?.cells[0].value === cell.value,
                  })}
                  {...cell.getCellProps()}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}
