import { forwardRef } from 'react'

import { Link, LinkPropsWithoutHref } from '@yordlelabs/website/util-components'

interface SummonerLinkProps extends LinkPropsWithoutHref {
  gameName: string
  tagLine: string
}

function getSummonerLink(gameName: string, tagLine: string) {
  return `/summoner/${encodeURIComponent(gameName)}/${encodeURIComponent(
    tagLine,
  )}`
}

export const SummonerLink = forwardRef<HTMLAnchorElement, SummonerLinkProps>(
  function SummonerLink({ gameName, tagLine, children, ...props }, ref) {
    return (
      <Link
        href={getSummonerLink(gameName, tagLine)}
        title={`${gameName}#${tagLine}`}
        ref={ref}
        {...props}
      >
        {children ?? gameName}
        <span className="ml-1 text-outline/40">#{tagLine}</span>
      </Link>
    )
  },
)
