import { User } from '@prisma/client'
import ms from 'ms'
import useSWR from 'swr'

export function useUser() {
  const { data: user, ...rest } = useSWR<User>('/api/auth/user', null, {
    refreshInterval: ms('1h'),
  })
  return { user, ...rest }
}
