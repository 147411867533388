import { useEffect, useRef } from 'react'
import urlcat from 'urlcat'

import {
  SubscriptionScope,
  useSubscribeCurrentGames,
} from '@yordlelabs/shared/web/data-access/server'
import { createAtom, createUseRouterQuery } from '@yordlelabs/shared/web/util'
import { ExtendedCurrentGameInfo } from '@yordlelabs/spectate/types'

export function createCurrentGamesAtom<Path extends string>(
  scope: SubscriptionScope,
  subpath: Path,
) {
  const [, useCurrentGames, , useSetCurrentGamesState] = createAtom<
    ExtendedCurrentGameInfo[]
  >(`${scope}CurrentGames`, [])

  const useRouterQuery = createUseRouterQuery()

  function useInitCurrentGames() {
    const { tab, ...query } = useRouterQuery()
    const games = useSubscribeCurrentGames(scope, urlcat(subpath, query))
    const set = useSetCurrentGamesState()

    useEffect(() => {
      set(games)
    }, [games, set])

    return games
  }

  function usePostCurrentGameEnd(callback: () => void) {
    const ref = useRef(callback)
    ref.current = callback

    const [currentGame] = useCurrentGames()

    useEffect(() => {
      if (!currentGame) {
        ref.current()
      }
    }, [currentGame])
  }

  return [useCurrentGames, useInitCurrentGames, usePostCurrentGameEnd] as const
}
