import clsx from 'clsx'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { MdMenu, MdMenuOpen } from 'react-icons/md'
import { useMedia } from 'react-use'

import { useNow, usePlatform } from '@yordlelabs/shared/web/data-access/client'
import {
  Beta,
  ClassName,
  IconButton,
  StateLayer,
} from '@yordlelabs/shared/web/ui'
import {
  createAtom,
  formatTime,
  useIsDesktop,
  useTranslation,
} from '@yordlelabs/shared/web/util'
import { mapToTimeZone } from '@yordlelabs/spectate/utils'
import { noop } from '@yordlelabs/util'
import { Link } from '@yordlelabs/website/util-components'

export const [, useNav, useNavState, useSetNav] = createAtom<boolean>('nav')

export const NavigationToggle: React.FC = () => {
  const [isOpen, setIsOpen] = useNavState()
  return (
    <IconButton
      Icon={isOpen ? MdMenuOpen : MdMenu}
      onClick={() => setIsOpen(!isOpen)}
    />
  )
}

export const Navigation: React.FC<ClassName> = ({ className }) => {
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const [isOpen, setIsOpen] = useNavState()
  const [shouldRenderOverlay, setShouldRenderOverlay] = useState(false)
  const sm = useMedia('(min-width: 640px)')

  useEffect(() => {
    setIsOpen(sm)
  }, [setIsOpen, sm])

  useEffect(() => {
    if (isOpen !== undefined) {
      setShouldRenderOverlay(isOpen && !sm)
    }
    // Changes to `sm`(media query) will update `isOpen`, so ignore it to avoid unnecessary re-renders.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const close = useCallback(() => setIsOpen(false), [setIsOpen])

  if (isDesktop === undefined) return null

  return (
    <>
      <nav
        className={clsx(
          isOpen === undefined ? 'hidden sm:flex' : isOpen ? 'flex' : 'hidden',
          'flex-col p-3',
          className,
        )}
      >
        <ul className={clsx('space-y-1.5')}>
          {Object.entries({
            spectate: { href: '/spectate' },
            ...(isDesktop && {
              auto_spectate: { href: '/auto_spectate', beta: true },
            }),
            settings: { href: '/settings' },
          }).map(
            ([route, { href, beta = false }]) =>
              href && (
                <li key={route} onClick={sm ? noop : close}>
                  <NavLink href={href}>
                    {t(route)}
                    {beta && <Beta className="ml-1.5" />}
                  </NavLink>
                </li>
              ),
          )}
        </ul>

        <ul
          className={clsx(
            'space-y-2.5 mt-auto typescale-title-small px-4 text-on-surface-variant',
          )}
        >
          {Object.entries({
            ...(!isDesktop && {
              download_app: '/desktop',
            }),
            faq: '/faq',
            about: '/about',
          }).map(
            ([route, href]) =>
              href && (
                <li key={route} onClick={sm ? noop : close}>
                  <Link href={href}>{t(route)}</Link>
                </li>
              ),
          )}
        </ul>
        <div className="flex gap-3 mt-3 px-4 py-2 items-end justify-between text-outline typescale-label-medium">
          <Time />
        </div>
      </nav>

      {shouldRenderOverlay && (
        <div
          className={clsx('fixed inset-0 bg-black/40 z-10')}
          onClick={close}
        />
      )}
    </>
  )
}

const Time: React.FC = () => {
  const now = useNow()
  const platform = usePlatform()
  const { t } = useTranslation()

  if (!platform) return null

  return (
    <div title={t('server_time')}>
      {formatTime(now, mapToTimeZone[platform])}
    </div>
  )
}

const NavLink: React.FC<{ href: string }> = ({ href, children }) => {
  const { pathname, asPath } = useRouter()
  const active = href === '/' ? pathname === '/' : asPath.startsWith(href)

  return (
    <Link
      className={clsx(
        'block px-4 py-2.5 typescale-label-large rounded-lg relative overflow-hidden',
        active ? 'bg-surface1' : 'text-on-surface-variant',
      )}
      href={href}
    >
      <StateLayer />
      {children}
    </Link>
  )
}
