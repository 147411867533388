import { ComponentPropsWithoutRef } from 'react'
import { IconType } from 'react-icons'

import { Button } from './Button'

export interface IconButtonProps extends ComponentPropsWithoutRef<'button'> {
  Icon?: IconType
}

export const IconButton: React.FC<IconButtonProps> = ({
  className,
  ...restProps
}) => {
  return <Button className={className} variant="icon" {...restProps} />
}
