import clsx from 'clsx'

interface TagProps {
  className?: string
}
export const Tag: React.FC<TagProps> = ({ className, children }) => {
  return (
    <span
      className={clsx(
        'bg-green-400/20 text-green-600 dark:text-green-300 typescale-label-medium rounded-sm w-fit px-1',
        className,
      )}
    >
      {children}
    </span>
  )
}

interface InGameProps extends TagProps {}
export const InGame: React.FC<InGameProps> = (props) => {
  return <Tag {...props}>Online</Tag>
}

interface BetaProps extends TagProps {}
export const Beta: React.FC<BetaProps> = (props) => {
  return <Tag {...props}>Beta</Tag>
}
