import { QueryHookOptions, QueryResult } from '@apollo/client'
import { NumberParam, useQueryParams, withDefault } from 'next-query-params'
import { useEffect, useRef } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import { IconButton } from './button'
import { Select } from './select'

type QueryHook<D, V> = (options?: QueryHookOptions<D, V>) => QueryResult<D, V>

const sizeOptions = [5, 10, 20, 50]

export function usePagination() {
  const [{ page, size }, setPagination] = useQueryParams({
    page: withDefault(NumberParam, 1),
    size: withDefault(NumberParam, 10),
  })

  return { page, size, setPagination }
}

type PaginationProps = {
  count: number | undefined
}

const Pagination: React.FC<PaginationProps> = ({ count }) => {
  const { page, size, setPagination } = usePagination()

  if (!count) return null

  const pageCount = Math.ceil(count / size)
  const lastPage = Math.max(1, pageCount)

  const isFirstPage = page === 1
  const isLastPage = page === lastPage

  return (
    <div className="flex justify-between items-center mt-8">
      <div className="flex items-center gap-4">
        <Select
          name="page_size"
          options={sizeOptions}
          value={size}
          onChange={(size) => setPagination({ page: 1, size })}
          labelClassName="hidden"
        />
        {pageCount === 0 || (
          <div className="typescale-label-large text-on-surface-variant">
            {count} results
          </div>
        )}
      </div>
      <div className="flex items-center gap-2 text-on-surface-variant">
        <IconButton
          Icon={MdChevronLeft}
          onClick={() => setPagination({ page: page - 1 })}
          disabled={isFirstPage}
        />
        <div className="space-x-1 typescale-label-large">
          <span className="text-on-surface">{page}</span>
          <span>/</span>
          <span className="text-on-surface">{lastPage}</span>
        </div>
        <IconButton
          Icon={MdChevronRight}
          onClick={() => setPagination({ page: page + 1 })}
          disabled={isLastPage}
        />
      </div>
    </div>
  )
}

interface PageProps<D, V> {
  variables: V
  useQuery: QueryHook<D, V>
  children: (data?: D) => React.ReactNode
  prefetch?: number
}

function Page<D, V>({
  children,
  variables,
  useQuery,
  prefetch = 0,
}: PageProps<D, V>) {
  const { page, size } = usePagination()
  const pageToFetch = page + prefetch

  const { data, previousData } = useQuery({
    variables: {
      ...variables,
      skip: (pageToFetch - 1) * size,
      take: size,
    },
  })

  return prefetch ? null : <div>{children(data ?? previousData)}</div>
}

interface PageContainerProps<D, V> extends PageProps<D, V>, PaginationProps {}

export function PageContainer<D, V>({
  count,
  ...props
}: PageContainerProps<D, V>) {
  const ref = useRef<HTMLDivElement>(null)
  const { page } = usePagination()

  useEffect(() => {
    if (ref.current) {
      window.scrollTo(0, ref.current.offsetTop - 48 - 16)
    }
  }, [page])

  return (
    <div ref={ref}>
      <Page {...props} />
      <Page {...props} prefetch={1} />
      <Pagination count={count} />
    </div>
  )
}
