import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: any;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type Account = {
  __typename?: 'Account';
  access_token?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expires_at?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  id_token?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type AccountAvgAggregate = {
  __typename?: 'AccountAvgAggregate';
  expires_at?: Maybe<Scalars['Float']>;
};

export type AccountAvgOrderByAggregateInput = {
  expires_at?: InputMaybe<SortOrder>;
};

export type AccountCountAggregate = {
  __typename?: 'AccountCountAggregate';
  _all: Scalars['Int'];
  access_token: Scalars['Int'];
  createdAt: Scalars['Int'];
  expires_at: Scalars['Int'];
  id: Scalars['Int'];
  id_token: Scalars['Int'];
  provider: Scalars['Int'];
  providerAccountId: Scalars['Int'];
  refresh_token: Scalars['Int'];
  scope: Scalars['Int'];
  session_state: Scalars['Int'];
  token_type: Scalars['Int'];
  type: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type AccountCountOrderByAggregateInput = {
  access_token?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expires_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  id_token?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  providerAccountId?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  scope?: InputMaybe<SortOrder>;
  session_state?: InputMaybe<SortOrder>;
  token_type?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccountCreateInput = {
  access_token?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires_at?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  id_token?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutAccountsInput;
};

export type AccountCreateManyInput = {
  access_token?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires_at?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  id_token?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type AccountCreateManyUserInput = {
  access_token?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires_at?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  id_token?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AccountCreateManyUserInputEnvelope = {
  data: Array<AccountCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type AccountCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutUserInput>>;
  createMany?: InputMaybe<AccountCreateManyUserInputEnvelope>;
};

export type AccountCreateOrConnectWithoutUserInput = {
  create: AccountCreateWithoutUserInput;
  where: AccountWhereUniqueInput;
};

export type AccountCreateWithoutUserInput = {
  access_token?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires_at?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  id_token?: InputMaybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: InputMaybe<Scalars['String']>;
  scope?: InputMaybe<Scalars['String']>;
  session_state?: InputMaybe<Scalars['String']>;
  token_type?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type AccountGroupBy = {
  __typename?: 'AccountGroupBy';
  _avg?: Maybe<AccountAvgAggregate>;
  _count?: Maybe<AccountCountAggregate>;
  _max?: Maybe<AccountMaxAggregate>;
  _min?: Maybe<AccountMinAggregate>;
  _sum?: Maybe<AccountSumAggregate>;
  access_token?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  expires_at?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  id_token?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type AccountListRelationFilter = {
  every?: InputMaybe<AccountWhereInput>;
  none?: InputMaybe<AccountWhereInput>;
  some?: InputMaybe<AccountWhereInput>;
};

export type AccountMaxAggregate = {
  __typename?: 'AccountMaxAggregate';
  access_token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_token?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  providerAccountId?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type AccountMaxOrderByAggregateInput = {
  access_token?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expires_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  id_token?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  providerAccountId?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  scope?: InputMaybe<SortOrder>;
  session_state?: InputMaybe<SortOrder>;
  token_type?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccountMinAggregate = {
  __typename?: 'AccountMinAggregate';
  access_token?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  id_token?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  providerAccountId?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  session_state?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type AccountMinOrderByAggregateInput = {
  access_token?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expires_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  id_token?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  providerAccountId?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  scope?: InputMaybe<SortOrder>;
  session_state?: InputMaybe<SortOrder>;
  token_type?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccountOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type AccountOrderByWithAggregationInput = {
  _avg?: InputMaybe<AccountAvgOrderByAggregateInput>;
  _count?: InputMaybe<AccountCountOrderByAggregateInput>;
  _max?: InputMaybe<AccountMaxOrderByAggregateInput>;
  _min?: InputMaybe<AccountMinOrderByAggregateInput>;
  _sum?: InputMaybe<AccountSumOrderByAggregateInput>;
  access_token?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expires_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  id_token?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  providerAccountId?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  scope?: InputMaybe<SortOrder>;
  session_state?: InputMaybe<SortOrder>;
  token_type?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type AccountOrderByWithRelationInput = {
  access_token?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  expires_at?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  id_token?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  providerAccountId?: InputMaybe<SortOrder>;
  refresh_token?: InputMaybe<SortOrder>;
  scope?: InputMaybe<SortOrder>;
  session_state?: InputMaybe<SortOrder>;
  token_type?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export type AccountProviderProviderAccountIdCompoundUniqueInput = {
  provider: Scalars['String'];
  providerAccountId: Scalars['String'];
};

export enum AccountScalarFieldEnum {
  AccessToken = 'access_token',
  CreatedAt = 'createdAt',
  ExpiresAt = 'expires_at',
  Id = 'id',
  IdToken = 'id_token',
  Provider = 'provider',
  ProviderAccountId = 'providerAccountId',
  RefreshToken = 'refresh_token',
  Scope = 'scope',
  SessionState = 'session_state',
  TokenType = 'token_type',
  Type = 'type',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type AccountScalarWhereInput = {
  AND?: InputMaybe<Array<AccountScalarWhereInput>>;
  NOT?: InputMaybe<Array<AccountScalarWhereInput>>;
  OR?: InputMaybe<Array<AccountScalarWhereInput>>;
  access_token?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expires_at?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  id_token?: InputMaybe<StringNullableFilter>;
  provider?: InputMaybe<StringFilter>;
  providerAccountId?: InputMaybe<StringFilter>;
  refresh_token?: InputMaybe<StringNullableFilter>;
  scope?: InputMaybe<StringNullableFilter>;
  session_state?: InputMaybe<StringNullableFilter>;
  token_type?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AccountScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<AccountScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<AccountScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<AccountScalarWhereWithAggregatesInput>>;
  access_token?: InputMaybe<StringNullableWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  expires_at?: InputMaybe<IntNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  id_token?: InputMaybe<StringNullableWithAggregatesFilter>;
  provider?: InputMaybe<StringWithAggregatesFilter>;
  providerAccountId?: InputMaybe<StringWithAggregatesFilter>;
  refresh_token?: InputMaybe<StringNullableWithAggregatesFilter>;
  scope?: InputMaybe<StringNullableWithAggregatesFilter>;
  session_state?: InputMaybe<StringNullableWithAggregatesFilter>;
  token_type?: InputMaybe<StringNullableWithAggregatesFilter>;
  type?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type AccountSumAggregate = {
  __typename?: 'AccountSumAggregate';
  expires_at?: Maybe<Scalars['Int']>;
};

export type AccountSumOrderByAggregateInput = {
  expires_at?: InputMaybe<SortOrder>;
};

export type AccountUpdateInput = {
  access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  id_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<StringFieldUpdateOperationsInput>;
  providerAccountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scope?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  session_state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  token_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutAccountsInput>;
};

export type AccountUpdateManyMutationInput = {
  access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  id_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<StringFieldUpdateOperationsInput>;
  providerAccountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scope?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  session_state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  token_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountUpdateManyWithWhereWithoutUserInput = {
  data: AccountUpdateManyMutationInput;
  where: AccountScalarWhereInput;
};

export type AccountUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<AccountCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<AccountCreateWithoutUserInput>>;
  createMany?: InputMaybe<AccountCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<AccountWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<AccountScalarWhereInput>>;
  disconnect?: InputMaybe<Array<AccountWhereUniqueInput>>;
  set?: InputMaybe<Array<AccountWhereUniqueInput>>;
  update?: InputMaybe<Array<AccountUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<AccountUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<AccountUpsertWithWhereUniqueWithoutUserInput>>;
};

export type AccountUpdateWithWhereUniqueWithoutUserInput = {
  data: AccountUpdateWithoutUserInput;
  where: AccountWhereUniqueInput;
};

export type AccountUpdateWithoutUserInput = {
  access_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires_at?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  id_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  provider?: InputMaybe<StringFieldUpdateOperationsInput>;
  providerAccountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  refresh_token?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  scope?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  session_state?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  token_type?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  type?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type AccountUpsertWithWhereUniqueWithoutUserInput = {
  create: AccountCreateWithoutUserInput;
  update: AccountUpdateWithoutUserInput;
  where: AccountWhereUniqueInput;
};

export type AccountWhereInput = {
  AND?: InputMaybe<Array<AccountWhereInput>>;
  NOT?: InputMaybe<Array<AccountWhereInput>>;
  OR?: InputMaybe<Array<AccountWhereInput>>;
  access_token?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expires_at?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<StringFilter>;
  id_token?: InputMaybe<StringNullableFilter>;
  provider?: InputMaybe<StringFilter>;
  providerAccountId?: InputMaybe<StringFilter>;
  refresh_token?: InputMaybe<StringNullableFilter>;
  scope?: InputMaybe<StringNullableFilter>;
  session_state?: InputMaybe<StringNullableFilter>;
  token_type?: InputMaybe<StringNullableFilter>;
  type?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type AccountWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  provider_providerAccountId?: InputMaybe<AccountProviderProviderAccountIdCompoundUniqueInput>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type AggregateAccount = {
  __typename?: 'AggregateAccount';
  _avg?: Maybe<AccountAvgAggregate>;
  _count?: Maybe<AccountCountAggregate>;
  _max?: Maybe<AccountMaxAggregate>;
  _min?: Maybe<AccountMinAggregate>;
  _sum?: Maybe<AccountSumAggregate>;
};

export type AggregateLeague = {
  __typename?: 'AggregateLeague';
  _avg?: Maybe<LeagueAvgAggregate>;
  _count?: Maybe<LeagueCountAggregate>;
  _max?: Maybe<LeagueMaxAggregate>;
  _min?: Maybe<LeagueMinAggregate>;
  _sum?: Maybe<LeagueSumAggregate>;
};

export type AggregateLeagueEntry = {
  __typename?: 'AggregateLeagueEntry';
  _avg?: Maybe<LeagueEntryAvgAggregate>;
  _count?: Maybe<LeagueEntryCountAggregate>;
  _max?: Maybe<LeagueEntryMaxAggregate>;
  _min?: Maybe<LeagueEntryMinAggregate>;
  _sum?: Maybe<LeagueEntrySumAggregate>;
};

export type AggregateMatch = {
  __typename?: 'AggregateMatch';
  _avg?: Maybe<MatchAvgAggregate>;
  _count?: Maybe<MatchCountAggregate>;
  _max?: Maybe<MatchMaxAggregate>;
  _min?: Maybe<MatchMinAggregate>;
  _sum?: Maybe<MatchSumAggregate>;
};

export type AggregateMatchParticipant = {
  __typename?: 'AggregateMatchParticipant';
  _avg?: Maybe<MatchParticipantAvgAggregate>;
  _count?: Maybe<MatchParticipantCountAggregate>;
  _max?: Maybe<MatchParticipantMaxAggregate>;
  _min?: Maybe<MatchParticipantMinAggregate>;
  _sum?: Maybe<MatchParticipantSumAggregate>;
};

export type AggregateMatchTeam = {
  __typename?: 'AggregateMatchTeam';
  _avg?: Maybe<MatchTeamAvgAggregate>;
  _count?: Maybe<MatchTeamCountAggregate>;
  _max?: Maybe<MatchTeamMaxAggregate>;
  _min?: Maybe<MatchTeamMinAggregate>;
  _sum?: Maybe<MatchTeamSumAggregate>;
};

export type AggregatePlayer = {
  __typename?: 'AggregatePlayer';
  _avg?: Maybe<PlayerAvgAggregate>;
  _count?: Maybe<PlayerCountAggregate>;
  _max?: Maybe<PlayerMaxAggregate>;
  _min?: Maybe<PlayerMinAggregate>;
  _sum?: Maybe<PlayerSumAggregate>;
};

export type AggregatePlayerRedirect = {
  __typename?: 'AggregatePlayerRedirect';
  _avg?: Maybe<PlayerRedirectAvgAggregate>;
  _count?: Maybe<PlayerRedirectCountAggregate>;
  _max?: Maybe<PlayerRedirectMaxAggregate>;
  _min?: Maybe<PlayerRedirectMinAggregate>;
  _sum?: Maybe<PlayerRedirectSumAggregate>;
};

export type AggregateRegion = {
  __typename?: 'AggregateRegion';
  _avg?: Maybe<RegionAvgAggregate>;
  _count?: Maybe<RegionCountAggregate>;
  _max?: Maybe<RegionMaxAggregate>;
  _min?: Maybe<RegionMinAggregate>;
  _sum?: Maybe<RegionSumAggregate>;
};

export type AggregateSession = {
  __typename?: 'AggregateSession';
  _count?: Maybe<SessionCountAggregate>;
  _max?: Maybe<SessionMaxAggregate>;
  _min?: Maybe<SessionMinAggregate>;
};

export type AggregateSummoner = {
  __typename?: 'AggregateSummoner';
  _avg?: Maybe<SummonerAvgAggregate>;
  _count?: Maybe<SummonerCountAggregate>;
  _max?: Maybe<SummonerMaxAggregate>;
  _min?: Maybe<SummonerMinAggregate>;
  _sum?: Maybe<SummonerSumAggregate>;
};

export type AggregateTeam = {
  __typename?: 'AggregateTeam';
  _avg?: Maybe<TeamAvgAggregate>;
  _count?: Maybe<TeamCountAggregate>;
  _max?: Maybe<TeamMaxAggregate>;
  _min?: Maybe<TeamMinAggregate>;
  _sum?: Maybe<TeamSumAggregate>;
};

export type AggregateTeamRedirect = {
  __typename?: 'AggregateTeamRedirect';
  _avg?: Maybe<TeamRedirectAvgAggregate>;
  _count?: Maybe<TeamRedirectCountAggregate>;
  _max?: Maybe<TeamRedirectMaxAggregate>;
  _min?: Maybe<TeamRedirectMinAggregate>;
  _sum?: Maybe<TeamRedirectSumAggregate>;
};

export type AggregateUser = {
  __typename?: 'AggregateUser';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
};

export type AggregateVerificationToken = {
  __typename?: 'AggregateVerificationToken';
  _count?: Maybe<VerificationTokenCountAggregate>;
  _max?: Maybe<VerificationTokenMaxAggregate>;
  _min?: Maybe<VerificationTokenMinAggregate>;
};

export type BigIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['BigInt']>;
  divide?: InputMaybe<Scalars['BigInt']>;
  increment?: InputMaybe<Scalars['BigInt']>;
  multiply?: InputMaybe<Scalars['BigInt']>;
  set?: InputMaybe<Scalars['BigInt']>;
};

export type BigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type BigIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBigIntFilter>;
  _min?: InputMaybe<NestedBigIntFilter>;
  _sum?: InputMaybe<NestedBigIntFilter>;
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type BoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type BoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export enum Division {
  I = 'I',
  Ii = 'II',
  Iii = 'III',
  Iv = 'IV'
}

export type EnumDivisionFieldUpdateOperationsInput = {
  set?: InputMaybe<Division>;
};

export type EnumDivisionFilter = {
  equals?: InputMaybe<Division>;
  in?: InputMaybe<Array<Division>>;
  not?: InputMaybe<NestedEnumDivisionFilter>;
  notIn?: InputMaybe<Array<Division>>;
};

export type EnumDivisionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDivisionFilter>;
  _min?: InputMaybe<NestedEnumDivisionFilter>;
  equals?: InputMaybe<Division>;
  in?: InputMaybe<Array<Division>>;
  not?: InputMaybe<NestedEnumDivisionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Division>>;
};

export type EnumPlatformFieldUpdateOperationsInput = {
  set?: InputMaybe<Platform>;
};

export type EnumPlatformFilter = {
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<NestedEnumPlatformFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type EnumPlatformWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPlatformFilter>;
  _min?: InputMaybe<NestedEnumPlatformFilter>;
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<NestedEnumPlatformWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type EnumQueueFieldUpdateOperationsInput = {
  set?: InputMaybe<Queue>;
};

export type EnumQueueFilter = {
  equals?: InputMaybe<Queue>;
  in?: InputMaybe<Array<Queue>>;
  not?: InputMaybe<NestedEnumQueueFilter>;
  notIn?: InputMaybe<Array<Queue>>;
};

export type EnumQueueWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumQueueFilter>;
  _min?: InputMaybe<NestedEnumQueueFilter>;
  equals?: InputMaybe<Queue>;
  in?: InputMaybe<Array<Queue>>;
  not?: InputMaybe<NestedEnumQueueWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Queue>>;
};

export type EnumTierFieldUpdateOperationsInput = {
  set?: InputMaybe<Tier>;
};

export type EnumTierFilter = {
  equals?: InputMaybe<Tier>;
  in?: InputMaybe<Array<Tier>>;
  not?: InputMaybe<NestedEnumTierFilter>;
  notIn?: InputMaybe<Array<Tier>>;
};

export type EnumTierWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTierFilter>;
  _min?: InputMaybe<NestedEnumTierFilter>;
  equals?: InputMaybe<Tier>;
  in?: InputMaybe<Array<Tier>>;
  not?: InputMaybe<NestedEnumTierWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Tier>>;
};

export type IntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type JsonFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type JsonNullableFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type JsonNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['JSON']>>;
  has?: InputMaybe<Scalars['JSON']>;
  hasEvery?: InputMaybe<Array<Scalars['JSON']>>;
  hasSome?: InputMaybe<Array<Scalars['JSON']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type JsonNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedJsonNullableFilter>;
  _min?: InputMaybe<NestedJsonNullableFilter>;
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type JsonWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedJsonFilter>;
  _min?: InputMaybe<NestedJsonFilter>;
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type League = {
  __typename?: 'League';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  /** Is this recognized by Riot and part of an official circuit? */
  IsOfficial: Scalars['String'];
  League: Scalars['String'];
  League_Short: Scalars['String'];
  Level: Scalars['String'];
  Region: Scalars['String'];
  region: Region;
};

export type LeagueAvgAggregate = {
  __typename?: 'LeagueAvgAggregate';
  Cargo_ID?: Maybe<Scalars['Float']>;
  Cargo_pageID?: Maybe<Scalars['Float']>;
  Cargo_pageNamespace?: Maybe<Scalars['Float']>;
};

export type LeagueAvgOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type LeagueCountAggregate = {
  __typename?: 'LeagueCountAggregate';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['Int'];
  IsOfficial: Scalars['Int'];
  League: Scalars['Int'];
  League_Short: Scalars['Int'];
  Level: Scalars['Int'];
  Region: Scalars['Int'];
  _all: Scalars['Int'];
};

export type LeagueCountOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsOfficial?: InputMaybe<SortOrder>;
  League?: InputMaybe<SortOrder>;
  League_Short?: InputMaybe<SortOrder>;
  Level?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
};

export type LeagueCreateInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsOfficial: Scalars['String'];
  League: Scalars['String'];
  League_Short: Scalars['String'];
  Level: Scalars['String'];
  region: RegionCreateNestedOneWithoutLeaguesInput;
};

export type LeagueCreateManyInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsOfficial: Scalars['String'];
  League: Scalars['String'];
  League_Short: Scalars['String'];
  Level: Scalars['String'];
  Region: Scalars['String'];
};

export type LeagueCreateManyRegionInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsOfficial: Scalars['String'];
  League: Scalars['String'];
  League_Short: Scalars['String'];
  Level: Scalars['String'];
};

export type LeagueCreateManyRegionInputEnvelope = {
  data: Array<LeagueCreateManyRegionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type LeagueCreateNestedManyWithoutRegionInput = {
  connect?: InputMaybe<Array<LeagueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeagueCreateOrConnectWithoutRegionInput>>;
  create?: InputMaybe<Array<LeagueCreateWithoutRegionInput>>;
  createMany?: InputMaybe<LeagueCreateManyRegionInputEnvelope>;
};

export type LeagueCreateOrConnectWithoutRegionInput = {
  create: LeagueCreateWithoutRegionInput;
  where: LeagueWhereUniqueInput;
};

export type LeagueCreateWithoutRegionInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsOfficial: Scalars['String'];
  League: Scalars['String'];
  League_Short: Scalars['String'];
  Level: Scalars['String'];
};

export type LeagueEntry = {
  __typename?: 'LeagueEntry';
  freshBlood: Scalars['Boolean'];
  hotStreak: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  leagueId: Scalars['String'];
  leaguePoints: Scalars['Int'];
  /** Losing team on Summoners Rift. Second through eighth placement in Teamfight Tactics. */
  losses: Scalars['Int'];
  miniSeries?: Maybe<Scalars['JSON']>;
  platform: Platform;
  queueType: Queue;
  /** The player's division within a tier. */
  rank: Division;
  summoner: Summoner;
  /** Player's summonerId (Encrypted) */
  summonerId: Scalars['String'];
  summonerName: Scalars['String'];
  tier: Tier;
  veteran: Scalars['Boolean'];
  /** Winning team on Summoners Rift. First placement in Teamfight Tactics. */
  wins: Scalars['Int'];
};

export type LeagueEntryAvgAggregate = {
  __typename?: 'LeagueEntryAvgAggregate';
  leaguePoints?: Maybe<Scalars['Float']>;
  losses?: Maybe<Scalars['Float']>;
  wins?: Maybe<Scalars['Float']>;
};

export type LeagueEntryAvgOrderByAggregateInput = {
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntryCountAggregate = {
  __typename?: 'LeagueEntryCountAggregate';
  _all: Scalars['Int'];
  freshBlood: Scalars['Int'];
  hotStreak: Scalars['Int'];
  inactive: Scalars['Int'];
  leagueId: Scalars['Int'];
  leaguePoints: Scalars['Int'];
  losses: Scalars['Int'];
  miniSeries: Scalars['Int'];
  platform: Scalars['Int'];
  queueType: Scalars['Int'];
  rank: Scalars['Int'];
  summonerId: Scalars['Int'];
  summonerName: Scalars['Int'];
  tier: Scalars['Int'];
  veteran: Scalars['Int'];
  wins: Scalars['Int'];
};

export type LeagueEntryCountOrderByAggregateInput = {
  freshBlood?: InputMaybe<SortOrder>;
  hotStreak?: InputMaybe<SortOrder>;
  inactive?: InputMaybe<SortOrder>;
  leagueId?: InputMaybe<SortOrder>;
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  miniSeries?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  queueType?: InputMaybe<SortOrder>;
  rank?: InputMaybe<SortOrder>;
  summonerId?: InputMaybe<SortOrder>;
  summonerName?: InputMaybe<SortOrder>;
  tier?: InputMaybe<SortOrder>;
  veteran?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntryCreateInput = {
  freshBlood: Scalars['Boolean'];
  hotStreak: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  leagueId: Scalars['String'];
  leaguePoints: Scalars['Int'];
  losses: Scalars['Int'];
  miniSeries?: InputMaybe<Scalars['JSON']>;
  queueType: Queue;
  rank: Division;
  summoner: SummonerCreateNestedOneWithoutLeagueEntryInput;
  summonerName: Scalars['String'];
  tier: Tier;
  veteran: Scalars['Boolean'];
  wins: Scalars['Int'];
};

export type LeagueEntryCreateManyInput = {
  freshBlood: Scalars['Boolean'];
  hotStreak: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  leagueId: Scalars['String'];
  leaguePoints: Scalars['Int'];
  losses: Scalars['Int'];
  miniSeries?: InputMaybe<Scalars['JSON']>;
  platform: Platform;
  queueType: Queue;
  rank: Division;
  summonerId: Scalars['String'];
  summonerName: Scalars['String'];
  tier: Tier;
  veteran: Scalars['Boolean'];
  wins: Scalars['Int'];
};

export type LeagueEntryCreateNestedOneWithoutSummonerInput = {
  connect?: InputMaybe<LeagueEntryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeagueEntryCreateOrConnectWithoutSummonerInput>;
  create?: InputMaybe<LeagueEntryCreateWithoutSummonerInput>;
};

export type LeagueEntryCreateOrConnectWithoutSummonerInput = {
  create: LeagueEntryCreateWithoutSummonerInput;
  where: LeagueEntryWhereUniqueInput;
};

export type LeagueEntryCreateWithoutSummonerInput = {
  freshBlood: Scalars['Boolean'];
  hotStreak: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  leagueId: Scalars['String'];
  leaguePoints: Scalars['Int'];
  losses: Scalars['Int'];
  miniSeries?: InputMaybe<Scalars['JSON']>;
  queueType: Queue;
  rank: Division;
  summonerName: Scalars['String'];
  tier: Tier;
  veteran: Scalars['Boolean'];
  wins: Scalars['Int'];
};

export type LeagueEntryGroupBy = {
  __typename?: 'LeagueEntryGroupBy';
  _avg?: Maybe<LeagueEntryAvgAggregate>;
  _count?: Maybe<LeagueEntryCountAggregate>;
  _max?: Maybe<LeagueEntryMaxAggregate>;
  _min?: Maybe<LeagueEntryMinAggregate>;
  _sum?: Maybe<LeagueEntrySumAggregate>;
  freshBlood: Scalars['Boolean'];
  hotStreak: Scalars['Boolean'];
  inactive: Scalars['Boolean'];
  leagueId: Scalars['String'];
  leaguePoints: Scalars['Int'];
  losses: Scalars['Int'];
  miniSeries?: Maybe<Scalars['JSON']>;
  platform: Platform;
  queueType: Queue;
  rank: Division;
  summonerId: Scalars['String'];
  summonerName: Scalars['String'];
  tier: Tier;
  veteran: Scalars['Boolean'];
  wins: Scalars['Int'];
};

export type LeagueEntryMaxAggregate = {
  __typename?: 'LeagueEntryMaxAggregate';
  freshBlood?: Maybe<Scalars['Boolean']>;
  hotStreak?: Maybe<Scalars['Boolean']>;
  inactive?: Maybe<Scalars['Boolean']>;
  leagueId?: Maybe<Scalars['String']>;
  leaguePoints?: Maybe<Scalars['Int']>;
  losses?: Maybe<Scalars['Int']>;
  platform?: Maybe<Platform>;
  queueType?: Maybe<Queue>;
  rank?: Maybe<Division>;
  summonerId?: Maybe<Scalars['String']>;
  summonerName?: Maybe<Scalars['String']>;
  tier?: Maybe<Tier>;
  veteran?: Maybe<Scalars['Boolean']>;
  wins?: Maybe<Scalars['Int']>;
};

export type LeagueEntryMaxOrderByAggregateInput = {
  freshBlood?: InputMaybe<SortOrder>;
  hotStreak?: InputMaybe<SortOrder>;
  inactive?: InputMaybe<SortOrder>;
  leagueId?: InputMaybe<SortOrder>;
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  queueType?: InputMaybe<SortOrder>;
  rank?: InputMaybe<SortOrder>;
  summonerId?: InputMaybe<SortOrder>;
  summonerName?: InputMaybe<SortOrder>;
  tier?: InputMaybe<SortOrder>;
  veteran?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntryMinAggregate = {
  __typename?: 'LeagueEntryMinAggregate';
  freshBlood?: Maybe<Scalars['Boolean']>;
  hotStreak?: Maybe<Scalars['Boolean']>;
  inactive?: Maybe<Scalars['Boolean']>;
  leagueId?: Maybe<Scalars['String']>;
  leaguePoints?: Maybe<Scalars['Int']>;
  losses?: Maybe<Scalars['Int']>;
  platform?: Maybe<Platform>;
  queueType?: Maybe<Queue>;
  rank?: Maybe<Division>;
  summonerId?: Maybe<Scalars['String']>;
  summonerName?: Maybe<Scalars['String']>;
  tier?: Maybe<Tier>;
  veteran?: Maybe<Scalars['Boolean']>;
  wins?: Maybe<Scalars['Int']>;
};

export type LeagueEntryMinOrderByAggregateInput = {
  freshBlood?: InputMaybe<SortOrder>;
  hotStreak?: InputMaybe<SortOrder>;
  inactive?: InputMaybe<SortOrder>;
  leagueId?: InputMaybe<SortOrder>;
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  queueType?: InputMaybe<SortOrder>;
  rank?: InputMaybe<SortOrder>;
  summonerId?: InputMaybe<SortOrder>;
  summonerName?: InputMaybe<SortOrder>;
  tier?: InputMaybe<SortOrder>;
  veteran?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntryOrderByWithAggregationInput = {
  _avg?: InputMaybe<LeagueEntryAvgOrderByAggregateInput>;
  _count?: InputMaybe<LeagueEntryCountOrderByAggregateInput>;
  _max?: InputMaybe<LeagueEntryMaxOrderByAggregateInput>;
  _min?: InputMaybe<LeagueEntryMinOrderByAggregateInput>;
  _sum?: InputMaybe<LeagueEntrySumOrderByAggregateInput>;
  freshBlood?: InputMaybe<SortOrder>;
  hotStreak?: InputMaybe<SortOrder>;
  inactive?: InputMaybe<SortOrder>;
  leagueId?: InputMaybe<SortOrder>;
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  miniSeries?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  queueType?: InputMaybe<SortOrder>;
  rank?: InputMaybe<SortOrder>;
  summonerId?: InputMaybe<SortOrder>;
  summonerName?: InputMaybe<SortOrder>;
  tier?: InputMaybe<SortOrder>;
  veteran?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntryOrderByWithRelationInput = {
  freshBlood?: InputMaybe<SortOrder>;
  hotStreak?: InputMaybe<SortOrder>;
  inactive?: InputMaybe<SortOrder>;
  leagueId?: InputMaybe<SortOrder>;
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  miniSeries?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  queueType?: InputMaybe<SortOrder>;
  rank?: InputMaybe<SortOrder>;
  summoner?: InputMaybe<SummonerOrderByWithRelationInput>;
  summonerId?: InputMaybe<SortOrder>;
  summonerName?: InputMaybe<SortOrder>;
  tier?: InputMaybe<SortOrder>;
  veteran?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntryRelationFilter = {
  is?: InputMaybe<LeagueEntryWhereInput>;
  isNot?: InputMaybe<LeagueEntryWhereInput>;
};

export enum LeagueEntryScalarFieldEnum {
  FreshBlood = 'freshBlood',
  HotStreak = 'hotStreak',
  Inactive = 'inactive',
  LeagueId = 'leagueId',
  LeaguePoints = 'leaguePoints',
  Losses = 'losses',
  MiniSeries = 'miniSeries',
  Platform = 'platform',
  QueueType = 'queueType',
  Rank = 'rank',
  SummonerId = 'summonerId',
  SummonerName = 'summonerName',
  Tier = 'tier',
  Veteran = 'veteran',
  Wins = 'wins'
}

export type LeagueEntryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LeagueEntryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<LeagueEntryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LeagueEntryScalarWhereWithAggregatesInput>>;
  freshBlood?: InputMaybe<BoolWithAggregatesFilter>;
  hotStreak?: InputMaybe<BoolWithAggregatesFilter>;
  inactive?: InputMaybe<BoolWithAggregatesFilter>;
  leagueId?: InputMaybe<StringWithAggregatesFilter>;
  leaguePoints?: InputMaybe<IntWithAggregatesFilter>;
  losses?: InputMaybe<IntWithAggregatesFilter>;
  miniSeries?: InputMaybe<JsonNullableWithAggregatesFilter>;
  platform?: InputMaybe<EnumPlatformWithAggregatesFilter>;
  queueType?: InputMaybe<EnumQueueWithAggregatesFilter>;
  rank?: InputMaybe<EnumDivisionWithAggregatesFilter>;
  summonerId?: InputMaybe<StringWithAggregatesFilter>;
  summonerName?: InputMaybe<StringWithAggregatesFilter>;
  tier?: InputMaybe<EnumTierWithAggregatesFilter>;
  veteran?: InputMaybe<BoolWithAggregatesFilter>;
  wins?: InputMaybe<IntWithAggregatesFilter>;
};

export type LeagueEntrySumAggregate = {
  __typename?: 'LeagueEntrySumAggregate';
  leaguePoints?: Maybe<Scalars['Int']>;
  losses?: Maybe<Scalars['Int']>;
  wins?: Maybe<Scalars['Int']>;
};

export type LeagueEntrySumOrderByAggregateInput = {
  leaguePoints?: InputMaybe<SortOrder>;
  losses?: InputMaybe<SortOrder>;
  wins?: InputMaybe<SortOrder>;
};

export type LeagueEntrySummonerIdPlatformCompoundUniqueInput = {
  platform: Platform;
  summonerId: Scalars['String'];
};

export type LeagueEntryUpdateInput = {
  freshBlood?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hotStreak?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leagueId?: InputMaybe<StringFieldUpdateOperationsInput>;
  leaguePoints?: InputMaybe<IntFieldUpdateOperationsInput>;
  losses?: InputMaybe<IntFieldUpdateOperationsInput>;
  miniSeries?: InputMaybe<Scalars['JSON']>;
  queueType?: InputMaybe<EnumQueueFieldUpdateOperationsInput>;
  rank?: InputMaybe<EnumDivisionFieldUpdateOperationsInput>;
  summoner?: InputMaybe<SummonerUpdateOneRequiredWithoutLeagueEntryInput>;
  summonerName?: InputMaybe<StringFieldUpdateOperationsInput>;
  tier?: InputMaybe<EnumTierFieldUpdateOperationsInput>;
  veteran?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wins?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type LeagueEntryUpdateManyMutationInput = {
  freshBlood?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hotStreak?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leagueId?: InputMaybe<StringFieldUpdateOperationsInput>;
  leaguePoints?: InputMaybe<IntFieldUpdateOperationsInput>;
  losses?: InputMaybe<IntFieldUpdateOperationsInput>;
  miniSeries?: InputMaybe<Scalars['JSON']>;
  queueType?: InputMaybe<EnumQueueFieldUpdateOperationsInput>;
  rank?: InputMaybe<EnumDivisionFieldUpdateOperationsInput>;
  summonerName?: InputMaybe<StringFieldUpdateOperationsInput>;
  tier?: InputMaybe<EnumTierFieldUpdateOperationsInput>;
  veteran?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wins?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type LeagueEntryUpdateOneWithoutSummonerInput = {
  connect?: InputMaybe<LeagueEntryWhereUniqueInput>;
  connectOrCreate?: InputMaybe<LeagueEntryCreateOrConnectWithoutSummonerInput>;
  create?: InputMaybe<LeagueEntryCreateWithoutSummonerInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<LeagueEntryUpdateWithoutSummonerInput>;
  upsert?: InputMaybe<LeagueEntryUpsertWithoutSummonerInput>;
};

export type LeagueEntryUpdateWithoutSummonerInput = {
  freshBlood?: InputMaybe<BoolFieldUpdateOperationsInput>;
  hotStreak?: InputMaybe<BoolFieldUpdateOperationsInput>;
  inactive?: InputMaybe<BoolFieldUpdateOperationsInput>;
  leagueId?: InputMaybe<StringFieldUpdateOperationsInput>;
  leaguePoints?: InputMaybe<IntFieldUpdateOperationsInput>;
  losses?: InputMaybe<IntFieldUpdateOperationsInput>;
  miniSeries?: InputMaybe<Scalars['JSON']>;
  queueType?: InputMaybe<EnumQueueFieldUpdateOperationsInput>;
  rank?: InputMaybe<EnumDivisionFieldUpdateOperationsInput>;
  summonerName?: InputMaybe<StringFieldUpdateOperationsInput>;
  tier?: InputMaybe<EnumTierFieldUpdateOperationsInput>;
  veteran?: InputMaybe<BoolFieldUpdateOperationsInput>;
  wins?: InputMaybe<IntFieldUpdateOperationsInput>;
};

export type LeagueEntryUpsertWithoutSummonerInput = {
  create: LeagueEntryCreateWithoutSummonerInput;
  update: LeagueEntryUpdateWithoutSummonerInput;
};

export type LeagueEntryWhereInput = {
  AND?: InputMaybe<Array<LeagueEntryWhereInput>>;
  NOT?: InputMaybe<Array<LeagueEntryWhereInput>>;
  OR?: InputMaybe<Array<LeagueEntryWhereInput>>;
  freshBlood?: InputMaybe<BoolFilter>;
  hotStreak?: InputMaybe<BoolFilter>;
  inactive?: InputMaybe<BoolFilter>;
  leagueId?: InputMaybe<StringFilter>;
  leaguePoints?: InputMaybe<IntFilter>;
  losses?: InputMaybe<IntFilter>;
  miniSeries?: InputMaybe<JsonNullableFilter>;
  platform?: InputMaybe<EnumPlatformFilter>;
  queueType?: InputMaybe<EnumQueueFilter>;
  rank?: InputMaybe<EnumDivisionFilter>;
  summoner?: InputMaybe<SummonerRelationFilter>;
  summonerId?: InputMaybe<StringFilter>;
  summonerName?: InputMaybe<StringFilter>;
  tier?: InputMaybe<EnumTierFilter>;
  veteran?: InputMaybe<BoolFilter>;
  wins?: InputMaybe<IntFilter>;
};

export type LeagueEntryWhereUniqueInput = {
  summonerId_platform?: InputMaybe<LeagueEntrySummonerIdPlatformCompoundUniqueInput>;
};

export type LeagueGroupBy = {
  __typename?: 'LeagueGroupBy';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsOfficial: Scalars['String'];
  League: Scalars['String'];
  League_Short: Scalars['String'];
  Level: Scalars['String'];
  Region: Scalars['String'];
  _avg?: Maybe<LeagueAvgAggregate>;
  _count?: Maybe<LeagueCountAggregate>;
  _max?: Maybe<LeagueMaxAggregate>;
  _min?: Maybe<LeagueMinAggregate>;
  _sum?: Maybe<LeagueSumAggregate>;
};

export type LeagueListRelationFilter = {
  every?: InputMaybe<LeagueWhereInput>;
  none?: InputMaybe<LeagueWhereInput>;
  some?: InputMaybe<LeagueWhereInput>;
};

export type LeagueMaxAggregate = {
  __typename?: 'LeagueMaxAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  IsOfficial?: Maybe<Scalars['String']>;
  League?: Maybe<Scalars['String']>;
  League_Short?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
};

export type LeagueMaxOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsOfficial?: InputMaybe<SortOrder>;
  League?: InputMaybe<SortOrder>;
  League_Short?: InputMaybe<SortOrder>;
  Level?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
};

export type LeagueMinAggregate = {
  __typename?: 'LeagueMinAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  IsOfficial?: Maybe<Scalars['String']>;
  League?: Maybe<Scalars['String']>;
  League_Short?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
};

export type LeagueMinOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsOfficial?: InputMaybe<SortOrder>;
  League?: InputMaybe<SortOrder>;
  League_Short?: InputMaybe<SortOrder>;
  Level?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
};

export type LeagueOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type LeagueOrderByWithAggregationInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsOfficial?: InputMaybe<SortOrder>;
  League?: InputMaybe<SortOrder>;
  League_Short?: InputMaybe<SortOrder>;
  Level?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<LeagueAvgOrderByAggregateInput>;
  _count?: InputMaybe<LeagueCountOrderByAggregateInput>;
  _max?: InputMaybe<LeagueMaxOrderByAggregateInput>;
  _min?: InputMaybe<LeagueMinOrderByAggregateInput>;
  _sum?: InputMaybe<LeagueSumOrderByAggregateInput>;
};

export type LeagueOrderByWithRelationInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsOfficial?: InputMaybe<SortOrder>;
  League?: InputMaybe<SortOrder>;
  League_Short?: InputMaybe<SortOrder>;
  Level?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  region?: InputMaybe<RegionOrderByWithRelationInput>;
};

export enum LeagueScalarFieldEnum {
  CargoId = 'Cargo_ID',
  CargoPageId = 'Cargo_pageID',
  CargoPageName = 'Cargo_pageName',
  CargoPageNamespace = 'Cargo_pageNamespace',
  CargoPageTitle = 'Cargo_pageTitle',
  IsOfficial = 'IsOfficial',
  League = 'League',
  LeagueShort = 'League_Short',
  Level = 'Level',
  Region = 'Region'
}

export type LeagueScalarWhereInput = {
  AND?: InputMaybe<Array<LeagueScalarWhereInput>>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  IsOfficial?: InputMaybe<StringFilter>;
  League?: InputMaybe<StringFilter>;
  League_Short?: InputMaybe<StringFilter>;
  Level?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LeagueScalarWhereInput>>;
  OR?: InputMaybe<Array<LeagueScalarWhereInput>>;
  Region?: InputMaybe<StringFilter>;
};

export type LeagueScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<LeagueScalarWhereWithAggregatesInput>>;
  Cargo_ID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_pageNamespace?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageTitle?: InputMaybe<StringWithAggregatesFilter>;
  IsOfficial?: InputMaybe<StringWithAggregatesFilter>;
  League?: InputMaybe<StringWithAggregatesFilter>;
  League_Short?: InputMaybe<StringWithAggregatesFilter>;
  Level?: InputMaybe<StringWithAggregatesFilter>;
  NOT?: InputMaybe<Array<LeagueScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<LeagueScalarWhereWithAggregatesInput>>;
  Region?: InputMaybe<StringWithAggregatesFilter>;
};

export type LeagueSumAggregate = {
  __typename?: 'LeagueSumAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
};

export type LeagueSumOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type LeagueUpdateInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsOfficial?: InputMaybe<StringFieldUpdateOperationsInput>;
  League?: InputMaybe<StringFieldUpdateOperationsInput>;
  League_Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Level?: InputMaybe<StringFieldUpdateOperationsInput>;
  region?: InputMaybe<RegionUpdateOneRequiredWithoutLeaguesInput>;
};

export type LeagueUpdateManyMutationInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsOfficial?: InputMaybe<StringFieldUpdateOperationsInput>;
  League?: InputMaybe<StringFieldUpdateOperationsInput>;
  League_Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Level?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LeagueUpdateManyWithWhereWithoutRegionInput = {
  data: LeagueUpdateManyMutationInput;
  where: LeagueScalarWhereInput;
};

export type LeagueUpdateManyWithoutRegionInput = {
  connect?: InputMaybe<Array<LeagueWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<LeagueCreateOrConnectWithoutRegionInput>>;
  create?: InputMaybe<Array<LeagueCreateWithoutRegionInput>>;
  createMany?: InputMaybe<LeagueCreateManyRegionInputEnvelope>;
  delete?: InputMaybe<Array<LeagueWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<LeagueScalarWhereInput>>;
  disconnect?: InputMaybe<Array<LeagueWhereUniqueInput>>;
  set?: InputMaybe<Array<LeagueWhereUniqueInput>>;
  update?: InputMaybe<Array<LeagueUpdateWithWhereUniqueWithoutRegionInput>>;
  updateMany?: InputMaybe<Array<LeagueUpdateManyWithWhereWithoutRegionInput>>;
  upsert?: InputMaybe<Array<LeagueUpsertWithWhereUniqueWithoutRegionInput>>;
};

export type LeagueUpdateWithWhereUniqueWithoutRegionInput = {
  data: LeagueUpdateWithoutRegionInput;
  where: LeagueWhereUniqueInput;
};

export type LeagueUpdateWithoutRegionInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsOfficial?: InputMaybe<StringFieldUpdateOperationsInput>;
  League?: InputMaybe<StringFieldUpdateOperationsInput>;
  League_Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Level?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type LeagueUpsertWithWhereUniqueWithoutRegionInput = {
  create: LeagueCreateWithoutRegionInput;
  update: LeagueUpdateWithoutRegionInput;
  where: LeagueWhereUniqueInput;
};

export type LeagueWhereInput = {
  AND?: InputMaybe<Array<LeagueWhereInput>>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  IsOfficial?: InputMaybe<StringFilter>;
  League?: InputMaybe<StringFilter>;
  League_Short?: InputMaybe<StringFilter>;
  Level?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<LeagueWhereInput>>;
  OR?: InputMaybe<Array<LeagueWhereInput>>;
  Region?: InputMaybe<StringFilter>;
  region?: InputMaybe<RegionRelationFilter>;
};

export type LeagueWhereUniqueInput = {
  League?: InputMaybe<Scalars['String']>;
};

export type Match = {
  __typename?: 'Match';
  _count?: Maybe<MatchCount>;
  createdAt: Scalars['DateTime'];
  /** Match data version. */
  dataVersion: Scalars['String'];
  /**
   * info
   * Unix timestamp for when the game is created on the game server (i.e., the loading screen).
   */
  gameCreation: Scalars['BigInt'];
  /** Prior to patch 11.20, this field returns the game length in milliseconds calculated from gameEndTimestamp - gameStartTimestamp. Post patch 11.20, this field returns the max timePlayed of any participant in the game in seconds, which makes the behavior of this field consistent with that of match-v4. The best way to handling the change in this field is to treat the value as milliseconds if the gameEndTimestamp field isn't in the response and to treat the value as seconds if gameEndTimestamp is in the response. */
  gameDuration: Scalars['Int'];
  /** Unix timestamp for when match ends on the game server. This timestamp can occasionally be significantly longer than when the match "ends". The most reliable way of determining the timestamp for the end of the match would be to add the max time played of any participant to the gameStartTimestamp. This field was added to match-v5 in patch 11.20 on Oct 5th, 2021. */
  gameEndTimestamp: Scalars['BigInt'];
  gameId: Scalars['BigInt'];
  /** Refer to the Game Constants documentation. */
  gameMode: Scalars['String'];
  gameName: Scalars['String'];
  /** Unix timestamp for when match starts on the game server. */
  gameStartTimestamp: Scalars['BigInt'];
  gameType: Scalars['String'];
  /** The first two parts can be used to determine the patch a game was played on. */
  gameVersion: Scalars['String'];
  /**
   * metadata
   * Match id.
   */
  id: Scalars['String'];
  /** Refer to the Game Constants documentation. */
  mapId: Scalars['Int'];
  participants: Array<MatchParticipant>;
  /** Platform where the match was played. */
  platformId: Scalars['String'];
  /** Refer to the Game Constants documentation. */
  queueId: Scalars['Int'];
  teams: Array<MatchTeam>;
  /** Tournament code used to generate the match. This field was added to match-v5 in patch 11.13 on June 23rd, 2021. */
  tournamentCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};


export type MatchParticipantsArgs = {
  cursor?: InputMaybe<MatchParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type MatchTeamsArgs = {
  cursor?: InputMaybe<MatchTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchTeamWhereInput>;
};

export type MatchAvgAggregate = {
  __typename?: 'MatchAvgAggregate';
  gameCreation?: Maybe<Scalars['Float']>;
  gameDuration?: Maybe<Scalars['Float']>;
  gameEndTimestamp?: Maybe<Scalars['Float']>;
  gameId?: Maybe<Scalars['Float']>;
  gameStartTimestamp?: Maybe<Scalars['Float']>;
  mapId?: Maybe<Scalars['Float']>;
  queueId?: Maybe<Scalars['Float']>;
};

export type MatchAvgOrderByAggregateInput = {
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
};

export type MatchCount = {
  __typename?: 'MatchCount';
  participants: Scalars['Int'];
  teams: Scalars['Int'];
};

export type MatchCountAggregate = {
  __typename?: 'MatchCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  dataVersion: Scalars['Int'];
  gameCreation: Scalars['Int'];
  gameDuration: Scalars['Int'];
  gameEndTimestamp: Scalars['Int'];
  gameId: Scalars['Int'];
  gameMode: Scalars['Int'];
  gameName: Scalars['Int'];
  gameStartTimestamp: Scalars['Int'];
  gameType: Scalars['Int'];
  gameVersion: Scalars['Int'];
  id: Scalars['Int'];
  mapId: Scalars['Int'];
  platformId: Scalars['Int'];
  queueId: Scalars['Int'];
  tournamentCode: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MatchCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  dataVersion?: InputMaybe<SortOrder>;
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  gameType?: InputMaybe<SortOrder>;
  gameVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  platformId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
  tournamentCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dataVersion: Scalars['String'];
  gameCreation: Scalars['BigInt'];
  gameDuration: Scalars['Int'];
  gameEndTimestamp: Scalars['BigInt'];
  gameId: Scalars['BigInt'];
  gameMode: Scalars['String'];
  gameName: Scalars['String'];
  gameStartTimestamp: Scalars['BigInt'];
  gameType: Scalars['String'];
  gameVersion: Scalars['String'];
  id: Scalars['String'];
  mapId: Scalars['Int'];
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutMatchInput>;
  platformId: Scalars['String'];
  queueId: Scalars['Int'];
  teams?: InputMaybe<MatchTeamCreateNestedManyWithoutMatchInput>;
  tournamentCode: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dataVersion: Scalars['String'];
  gameCreation: Scalars['BigInt'];
  gameDuration: Scalars['Int'];
  gameEndTimestamp: Scalars['BigInt'];
  gameId: Scalars['BigInt'];
  gameMode: Scalars['String'];
  gameName: Scalars['String'];
  gameStartTimestamp: Scalars['BigInt'];
  gameType: Scalars['String'];
  gameVersion: Scalars['String'];
  id: Scalars['String'];
  mapId: Scalars['Int'];
  platformId: Scalars['String'];
  queueId: Scalars['Int'];
  tournamentCode: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchCreateNestedOneWithoutParticipantsInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<MatchCreateWithoutParticipantsInput>;
};

export type MatchCreateNestedOneWithoutTeamsInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<MatchCreateWithoutTeamsInput>;
};

export type MatchCreateOrConnectWithoutParticipantsInput = {
  create: MatchCreateWithoutParticipantsInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateOrConnectWithoutTeamsInput = {
  create: MatchCreateWithoutTeamsInput;
  where: MatchWhereUniqueInput;
};

export type MatchCreateWithoutParticipantsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dataVersion: Scalars['String'];
  gameCreation: Scalars['BigInt'];
  gameDuration: Scalars['Int'];
  gameEndTimestamp: Scalars['BigInt'];
  gameId: Scalars['BigInt'];
  gameMode: Scalars['String'];
  gameName: Scalars['String'];
  gameStartTimestamp: Scalars['BigInt'];
  gameType: Scalars['String'];
  gameVersion: Scalars['String'];
  id: Scalars['String'];
  mapId: Scalars['Int'];
  platformId: Scalars['String'];
  queueId: Scalars['Int'];
  teams?: InputMaybe<MatchTeamCreateNestedManyWithoutMatchInput>;
  tournamentCode: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchCreateWithoutTeamsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  dataVersion: Scalars['String'];
  gameCreation: Scalars['BigInt'];
  gameDuration: Scalars['Int'];
  gameEndTimestamp: Scalars['BigInt'];
  gameId: Scalars['BigInt'];
  gameMode: Scalars['String'];
  gameName: Scalars['String'];
  gameStartTimestamp: Scalars['BigInt'];
  gameType: Scalars['String'];
  gameVersion: Scalars['String'];
  id: Scalars['String'];
  mapId: Scalars['Int'];
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutMatchInput>;
  platformId: Scalars['String'];
  queueId: Scalars['Int'];
  tournamentCode: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchGroupBy = {
  __typename?: 'MatchGroupBy';
  _avg?: Maybe<MatchAvgAggregate>;
  _count?: Maybe<MatchCountAggregate>;
  _max?: Maybe<MatchMaxAggregate>;
  _min?: Maybe<MatchMinAggregate>;
  _sum?: Maybe<MatchSumAggregate>;
  createdAt: Scalars['DateTime'];
  dataVersion: Scalars['String'];
  gameCreation: Scalars['BigInt'];
  gameDuration: Scalars['Int'];
  gameEndTimestamp: Scalars['BigInt'];
  gameId: Scalars['BigInt'];
  gameMode: Scalars['String'];
  gameName: Scalars['String'];
  gameStartTimestamp: Scalars['BigInt'];
  gameType: Scalars['String'];
  gameVersion: Scalars['String'];
  id: Scalars['String'];
  mapId: Scalars['Int'];
  platformId: Scalars['String'];
  queueId: Scalars['Int'];
  tournamentCode: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type MatchMaxAggregate = {
  __typename?: 'MatchMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  dataVersion?: Maybe<Scalars['String']>;
  gameCreation?: Maybe<Scalars['BigInt']>;
  gameDuration?: Maybe<Scalars['Int']>;
  gameEndTimestamp?: Maybe<Scalars['BigInt']>;
  gameId?: Maybe<Scalars['BigInt']>;
  gameMode?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  gameStartTimestamp?: Maybe<Scalars['BigInt']>;
  gameType?: Maybe<Scalars['String']>;
  gameVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mapId?: Maybe<Scalars['Int']>;
  platformId?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['Int']>;
  tournamentCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MatchMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  dataVersion?: InputMaybe<SortOrder>;
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  gameType?: InputMaybe<SortOrder>;
  gameVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  platformId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
  tournamentCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchMinAggregate = {
  __typename?: 'MatchMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  dataVersion?: Maybe<Scalars['String']>;
  gameCreation?: Maybe<Scalars['BigInt']>;
  gameDuration?: Maybe<Scalars['Int']>;
  gameEndTimestamp?: Maybe<Scalars['BigInt']>;
  gameId?: Maybe<Scalars['BigInt']>;
  gameMode?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  gameStartTimestamp?: Maybe<Scalars['BigInt']>;
  gameType?: Maybe<Scalars['String']>;
  gameVersion?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  mapId?: Maybe<Scalars['Int']>;
  platformId?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['Int']>;
  tournamentCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MatchMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  dataVersion?: InputMaybe<SortOrder>;
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  gameType?: InputMaybe<SortOrder>;
  gameVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  platformId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
  tournamentCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchOrderByWithAggregationInput = {
  _avg?: InputMaybe<MatchAvgOrderByAggregateInput>;
  _count?: InputMaybe<MatchCountOrderByAggregateInput>;
  _max?: InputMaybe<MatchMaxOrderByAggregateInput>;
  _min?: InputMaybe<MatchMinOrderByAggregateInput>;
  _sum?: InputMaybe<MatchSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  dataVersion?: InputMaybe<SortOrder>;
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  gameType?: InputMaybe<SortOrder>;
  gameVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  platformId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
  tournamentCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  dataVersion?: InputMaybe<SortOrder>;
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameMode?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  gameType?: InputMaybe<SortOrder>;
  gameVersion?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  participants?: InputMaybe<MatchParticipantOrderByRelationAggregateInput>;
  platformId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
  teams?: InputMaybe<MatchTeamOrderByRelationAggregateInput>;
  tournamentCode?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchParticipant = {
  __typename?: 'MatchParticipant';
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  match: Match;
  matchId: Scalars['String'];
  puuid: Scalars['String'];
  summoner: Summoner;
  team: MatchTeam;
  teamId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MatchParticipantAvgAggregate = {
  __typename?: 'MatchParticipantAvgAggregate';
  teamId?: Maybe<Scalars['Float']>;
};

export type MatchParticipantAvgOrderByAggregateInput = {
  teamId?: InputMaybe<SortOrder>;
};

export type MatchParticipantCountAggregate = {
  __typename?: 'MatchParticipantCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  data: Scalars['Int'];
  matchId: Scalars['Int'];
  puuid: Scalars['Int'];
  teamId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type MatchParticipantCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchParticipantCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  match: MatchCreateNestedOneWithoutParticipantsInput;
  summoner: SummonerCreateNestedOneWithoutParticipantsInput;
  team: MatchTeamCreateNestedOneWithoutParticipantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  matchId: Scalars['String'];
  puuid: Scalars['String'];
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateManyMatchInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  puuid: Scalars['String'];
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateManyMatchInputEnvelope = {
  data: Array<MatchParticipantCreateManyMatchInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MatchParticipantCreateManySummonerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  matchId: Scalars['String'];
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateManySummonerInputEnvelope = {
  data: Array<MatchParticipantCreateManySummonerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MatchParticipantCreateManyTeamInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  puuid: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateManyTeamInputEnvelope = {
  data: Array<MatchParticipantCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MatchParticipantCreateNestedManyWithoutMatchInput = {
  connect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchParticipantCreateOrConnectWithoutMatchInput>>;
  create?: InputMaybe<Array<MatchParticipantCreateWithoutMatchInput>>;
  createMany?: InputMaybe<MatchParticipantCreateManyMatchInputEnvelope>;
};

export type MatchParticipantCreateNestedManyWithoutSummonerInput = {
  connect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchParticipantCreateOrConnectWithoutSummonerInput>>;
  create?: InputMaybe<Array<MatchParticipantCreateWithoutSummonerInput>>;
  createMany?: InputMaybe<MatchParticipantCreateManySummonerInputEnvelope>;
};

export type MatchParticipantCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchParticipantCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<MatchParticipantCreateWithoutTeamInput>>;
  createMany?: InputMaybe<MatchParticipantCreateManyTeamInputEnvelope>;
};

export type MatchParticipantCreateOrConnectWithoutMatchInput = {
  create: MatchParticipantCreateWithoutMatchInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantCreateOrConnectWithoutSummonerInput = {
  create: MatchParticipantCreateWithoutSummonerInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantCreateOrConnectWithoutTeamInput = {
  create: MatchParticipantCreateWithoutTeamInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantCreateWithoutMatchInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  summoner: SummonerCreateNestedOneWithoutParticipantsInput;
  team: MatchTeamCreateNestedOneWithoutParticipantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateWithoutSummonerInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  match: MatchCreateNestedOneWithoutParticipantsInput;
  team: MatchTeamCreateNestedOneWithoutParticipantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantCreateWithoutTeamInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  data: Scalars['JSON'];
  match: MatchCreateNestedOneWithoutParticipantsInput;
  summoner: SummonerCreateNestedOneWithoutParticipantsInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type MatchParticipantGroupBy = {
  __typename?: 'MatchParticipantGroupBy';
  _avg?: Maybe<MatchParticipantAvgAggregate>;
  _count?: Maybe<MatchParticipantCountAggregate>;
  _max?: Maybe<MatchParticipantMaxAggregate>;
  _min?: Maybe<MatchParticipantMinAggregate>;
  _sum?: Maybe<MatchParticipantSumAggregate>;
  createdAt: Scalars['DateTime'];
  data: Scalars['JSON'];
  matchId: Scalars['String'];
  puuid: Scalars['String'];
  teamId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type MatchParticipantListRelationFilter = {
  every?: InputMaybe<MatchParticipantWhereInput>;
  none?: InputMaybe<MatchParticipantWhereInput>;
  some?: InputMaybe<MatchParticipantWhereInput>;
};

export type MatchParticipantMatchIdPuuidCompoundUniqueInput = {
  matchId: Scalars['String'];
  puuid: Scalars['String'];
};

export type MatchParticipantMaxAggregate = {
  __typename?: 'MatchParticipantMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  matchId?: Maybe<Scalars['String']>;
  puuid?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MatchParticipantMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchParticipantMinAggregate = {
  __typename?: 'MatchParticipantMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  matchId?: Maybe<Scalars['String']>;
  puuid?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MatchParticipantMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchParticipantOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MatchParticipantOrderByWithAggregationInput = {
  _avg?: InputMaybe<MatchParticipantAvgOrderByAggregateInput>;
  _count?: InputMaybe<MatchParticipantCountOrderByAggregateInput>;
  _max?: InputMaybe<MatchParticipantMaxOrderByAggregateInput>;
  _min?: InputMaybe<MatchParticipantMinOrderByAggregateInput>;
  _sum?: InputMaybe<MatchParticipantSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type MatchParticipantOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  data?: InputMaybe<SortOrder>;
  match?: InputMaybe<MatchOrderByWithRelationInput>;
  matchId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  summoner?: InputMaybe<SummonerOrderByWithRelationInput>;
  team?: InputMaybe<MatchTeamOrderByWithRelationInput>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum MatchParticipantScalarFieldEnum {
  CreatedAt = 'createdAt',
  Data = 'data',
  MatchId = 'matchId',
  Puuid = 'puuid',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt'
}

export type MatchParticipantScalarWhereInput = {
  AND?: InputMaybe<Array<MatchParticipantScalarWhereInput>>;
  NOT?: InputMaybe<Array<MatchParticipantScalarWhereInput>>;
  OR?: InputMaybe<Array<MatchParticipantScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonFilter>;
  matchId?: InputMaybe<StringFilter>;
  puuid?: InputMaybe<StringFilter>;
  teamId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MatchParticipantScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MatchParticipantScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MatchParticipantScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MatchParticipantScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  data?: InputMaybe<JsonWithAggregatesFilter>;
  matchId?: InputMaybe<StringWithAggregatesFilter>;
  puuid?: InputMaybe<StringWithAggregatesFilter>;
  teamId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MatchParticipantSumAggregate = {
  __typename?: 'MatchParticipantSumAggregate';
  teamId?: Maybe<Scalars['Int']>;
};

export type MatchParticipantSumOrderByAggregateInput = {
  teamId?: InputMaybe<SortOrder>;
};

export type MatchParticipantUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutParticipantsInput>;
  summoner?: InputMaybe<SummonerUpdateOneRequiredWithoutParticipantsInput>;
  team?: InputMaybe<MatchTeamUpdateOneRequiredWithoutParticipantsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchParticipantUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchParticipantUpdateManyWithWhereWithoutMatchInput = {
  data: MatchParticipantUpdateManyMutationInput;
  where: MatchParticipantScalarWhereInput;
};

export type MatchParticipantUpdateManyWithWhereWithoutSummonerInput = {
  data: MatchParticipantUpdateManyMutationInput;
  where: MatchParticipantScalarWhereInput;
};

export type MatchParticipantUpdateManyWithWhereWithoutTeamInput = {
  data: MatchParticipantUpdateManyMutationInput;
  where: MatchParticipantScalarWhereInput;
};

export type MatchParticipantUpdateManyWithoutMatchInput = {
  connect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchParticipantCreateOrConnectWithoutMatchInput>>;
  create?: InputMaybe<Array<MatchParticipantCreateWithoutMatchInput>>;
  createMany?: InputMaybe<MatchParticipantCreateManyMatchInputEnvelope>;
  delete?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchParticipantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchParticipantUpdateWithWhereUniqueWithoutMatchInput>>;
  updateMany?: InputMaybe<Array<MatchParticipantUpdateManyWithWhereWithoutMatchInput>>;
  upsert?: InputMaybe<Array<MatchParticipantUpsertWithWhereUniqueWithoutMatchInput>>;
};

export type MatchParticipantUpdateManyWithoutSummonerInput = {
  connect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchParticipantCreateOrConnectWithoutSummonerInput>>;
  create?: InputMaybe<Array<MatchParticipantCreateWithoutSummonerInput>>;
  createMany?: InputMaybe<MatchParticipantCreateManySummonerInputEnvelope>;
  delete?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchParticipantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchParticipantUpdateWithWhereUniqueWithoutSummonerInput>>;
  updateMany?: InputMaybe<Array<MatchParticipantUpdateManyWithWhereWithoutSummonerInput>>;
  upsert?: InputMaybe<Array<MatchParticipantUpsertWithWhereUniqueWithoutSummonerInput>>;
};

export type MatchParticipantUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchParticipantCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<MatchParticipantCreateWithoutTeamInput>>;
  createMany?: InputMaybe<MatchParticipantCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchParticipantScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchParticipantWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchParticipantUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<MatchParticipantUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<MatchParticipantUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type MatchParticipantUpdateWithWhereUniqueWithoutMatchInput = {
  data: MatchParticipantUpdateWithoutMatchInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantUpdateWithWhereUniqueWithoutSummonerInput = {
  data: MatchParticipantUpdateWithoutSummonerInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantUpdateWithWhereUniqueWithoutTeamInput = {
  data: MatchParticipantUpdateWithoutTeamInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantUpdateWithoutMatchInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  summoner?: InputMaybe<SummonerUpdateOneRequiredWithoutParticipantsInput>;
  team?: InputMaybe<MatchTeamUpdateOneRequiredWithoutParticipantsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchParticipantUpdateWithoutSummonerInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutParticipantsInput>;
  team?: InputMaybe<MatchTeamUpdateOneRequiredWithoutParticipantsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchParticipantUpdateWithoutTeamInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  data?: InputMaybe<Scalars['JSON']>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutParticipantsInput>;
  summoner?: InputMaybe<SummonerUpdateOneRequiredWithoutParticipantsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchParticipantUpsertWithWhereUniqueWithoutMatchInput = {
  create: MatchParticipantCreateWithoutMatchInput;
  update: MatchParticipantUpdateWithoutMatchInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantUpsertWithWhereUniqueWithoutSummonerInput = {
  create: MatchParticipantCreateWithoutSummonerInput;
  update: MatchParticipantUpdateWithoutSummonerInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantUpsertWithWhereUniqueWithoutTeamInput = {
  create: MatchParticipantCreateWithoutTeamInput;
  update: MatchParticipantUpdateWithoutTeamInput;
  where: MatchParticipantWhereUniqueInput;
};

export type MatchParticipantWhereInput = {
  AND?: InputMaybe<Array<MatchParticipantWhereInput>>;
  NOT?: InputMaybe<Array<MatchParticipantWhereInput>>;
  OR?: InputMaybe<Array<MatchParticipantWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  data?: InputMaybe<JsonFilter>;
  match?: InputMaybe<MatchRelationFilter>;
  matchId?: InputMaybe<StringFilter>;
  puuid?: InputMaybe<StringFilter>;
  summoner?: InputMaybe<SummonerRelationFilter>;
  team?: InputMaybe<MatchTeamRelationFilter>;
  teamId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MatchParticipantWhereUniqueInput = {
  matchId_puuid?: InputMaybe<MatchParticipantMatchIdPuuidCompoundUniqueInput>;
};

export type MatchRelationFilter = {
  is?: InputMaybe<MatchWhereInput>;
  isNot?: InputMaybe<MatchWhereInput>;
};

export enum MatchScalarFieldEnum {
  CreatedAt = 'createdAt',
  DataVersion = 'dataVersion',
  GameCreation = 'gameCreation',
  GameDuration = 'gameDuration',
  GameEndTimestamp = 'gameEndTimestamp',
  GameId = 'gameId',
  GameMode = 'gameMode',
  GameName = 'gameName',
  GameStartTimestamp = 'gameStartTimestamp',
  GameType = 'gameType',
  GameVersion = 'gameVersion',
  Id = 'id',
  MapId = 'mapId',
  PlatformId = 'platformId',
  QueueId = 'queueId',
  TournamentCode = 'tournamentCode',
  UpdatedAt = 'updatedAt'
}

export type MatchScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MatchScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MatchScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MatchScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  dataVersion?: InputMaybe<StringWithAggregatesFilter>;
  gameCreation?: InputMaybe<BigIntWithAggregatesFilter>;
  gameDuration?: InputMaybe<IntWithAggregatesFilter>;
  gameEndTimestamp?: InputMaybe<BigIntWithAggregatesFilter>;
  gameId?: InputMaybe<BigIntWithAggregatesFilter>;
  gameMode?: InputMaybe<StringWithAggregatesFilter>;
  gameName?: InputMaybe<StringWithAggregatesFilter>;
  gameStartTimestamp?: InputMaybe<BigIntWithAggregatesFilter>;
  gameType?: InputMaybe<StringWithAggregatesFilter>;
  gameVersion?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  mapId?: InputMaybe<IntWithAggregatesFilter>;
  platformId?: InputMaybe<StringWithAggregatesFilter>;
  queueId?: InputMaybe<IntWithAggregatesFilter>;
  tournamentCode?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type MatchSumAggregate = {
  __typename?: 'MatchSumAggregate';
  gameCreation?: Maybe<Scalars['BigInt']>;
  gameDuration?: Maybe<Scalars['Int']>;
  gameEndTimestamp?: Maybe<Scalars['BigInt']>;
  gameId?: Maybe<Scalars['BigInt']>;
  gameStartTimestamp?: Maybe<Scalars['BigInt']>;
  mapId?: Maybe<Scalars['Int']>;
  queueId?: Maybe<Scalars['Int']>;
};

export type MatchSumOrderByAggregateInput = {
  gameCreation?: InputMaybe<SortOrder>;
  gameDuration?: InputMaybe<SortOrder>;
  gameEndTimestamp?: InputMaybe<SortOrder>;
  gameId?: InputMaybe<SortOrder>;
  gameStartTimestamp?: InputMaybe<SortOrder>;
  mapId?: InputMaybe<SortOrder>;
  queueId?: InputMaybe<SortOrder>;
};

export type MatchTeam = {
  __typename?: 'MatchTeam';
  _count?: Maybe<MatchTeamCount>;
  bans: Array<Scalars['JSON']>;
  createdAt: Scalars['DateTime'];
  match: Match;
  matchId: Scalars['String'];
  objectives?: Maybe<Scalars['JSON']>;
  participants: Array<MatchParticipant>;
  teamId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  win: Scalars['Boolean'];
};


export type MatchTeamParticipantsArgs = {
  cursor?: InputMaybe<MatchParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};

export type MatchTeamAvgAggregate = {
  __typename?: 'MatchTeamAvgAggregate';
  teamId?: Maybe<Scalars['Float']>;
};

export type MatchTeamAvgOrderByAggregateInput = {
  teamId?: InputMaybe<SortOrder>;
};

export type MatchTeamCount = {
  __typename?: 'MatchTeamCount';
  participants: Scalars['Int'];
};

export type MatchTeamCountAggregate = {
  __typename?: 'MatchTeamCountAggregate';
  _all: Scalars['Int'];
  bans: Scalars['Int'];
  createdAt: Scalars['Int'];
  matchId: Scalars['Int'];
  objectives: Scalars['Int'];
  teamId: Scalars['Int'];
  updatedAt: Scalars['Int'];
  win: Scalars['Int'];
};

export type MatchTeamCountOrderByAggregateInput = {
  bans?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  objectives?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  win?: InputMaybe<SortOrder>;
};

export type MatchTeamCreateInput = {
  bans?: InputMaybe<MatchTeamCreatebansInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  match: MatchCreateNestedOneWithoutTeamsInput;
  objectives?: InputMaybe<Scalars['JSON']>;
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutTeamInput>;
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  win: Scalars['Boolean'];
};

export type MatchTeamCreateManyInput = {
  bans?: InputMaybe<MatchTeamCreatebansInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  matchId: Scalars['String'];
  objectives?: InputMaybe<Scalars['JSON']>;
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  win: Scalars['Boolean'];
};

export type MatchTeamCreateManyMatchInput = {
  bans?: InputMaybe<MatchTeamCreatebansInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  objectives?: InputMaybe<Scalars['JSON']>;
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  win: Scalars['Boolean'];
};

export type MatchTeamCreateManyMatchInputEnvelope = {
  data: Array<MatchTeamCreateManyMatchInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type MatchTeamCreateNestedManyWithoutMatchInput = {
  connect?: InputMaybe<Array<MatchTeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchTeamCreateOrConnectWithoutMatchInput>>;
  create?: InputMaybe<Array<MatchTeamCreateWithoutMatchInput>>;
  createMany?: InputMaybe<MatchTeamCreateManyMatchInputEnvelope>;
};

export type MatchTeamCreateNestedOneWithoutParticipantsInput = {
  connect?: InputMaybe<MatchTeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchTeamCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<MatchTeamCreateWithoutParticipantsInput>;
};

export type MatchTeamCreateOrConnectWithoutMatchInput = {
  create: MatchTeamCreateWithoutMatchInput;
  where: MatchTeamWhereUniqueInput;
};

export type MatchTeamCreateOrConnectWithoutParticipantsInput = {
  create: MatchTeamCreateWithoutParticipantsInput;
  where: MatchTeamWhereUniqueInput;
};

export type MatchTeamCreateWithoutMatchInput = {
  bans?: InputMaybe<MatchTeamCreatebansInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  objectives?: InputMaybe<Scalars['JSON']>;
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutTeamInput>;
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  win: Scalars['Boolean'];
};

export type MatchTeamCreateWithoutParticipantsInput = {
  bans?: InputMaybe<MatchTeamCreatebansInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  match: MatchCreateNestedOneWithoutTeamsInput;
  objectives?: InputMaybe<Scalars['JSON']>;
  teamId: Scalars['Int'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  win: Scalars['Boolean'];
};

export type MatchTeamCreatebansInput = {
  set: Array<Scalars['JSON']>;
};

export type MatchTeamGroupBy = {
  __typename?: 'MatchTeamGroupBy';
  _avg?: Maybe<MatchTeamAvgAggregate>;
  _count?: Maybe<MatchTeamCountAggregate>;
  _max?: Maybe<MatchTeamMaxAggregate>;
  _min?: Maybe<MatchTeamMinAggregate>;
  _sum?: Maybe<MatchTeamSumAggregate>;
  bans?: Maybe<Array<Scalars['JSON']>>;
  createdAt: Scalars['DateTime'];
  matchId: Scalars['String'];
  objectives?: Maybe<Scalars['JSON']>;
  teamId: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  win: Scalars['Boolean'];
};

export type MatchTeamListRelationFilter = {
  every?: InputMaybe<MatchTeamWhereInput>;
  none?: InputMaybe<MatchTeamWhereInput>;
  some?: InputMaybe<MatchTeamWhereInput>;
};

export type MatchTeamMatchIdTeamIdCompoundUniqueInput = {
  matchId: Scalars['String'];
  teamId: Scalars['Int'];
};

export type MatchTeamMaxAggregate = {
  __typename?: 'MatchTeamMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  matchId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  win?: Maybe<Scalars['Boolean']>;
};

export type MatchTeamMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  win?: InputMaybe<SortOrder>;
};

export type MatchTeamMinAggregate = {
  __typename?: 'MatchTeamMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  matchId?: Maybe<Scalars['String']>;
  teamId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  win?: Maybe<Scalars['Boolean']>;
};

export type MatchTeamMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  win?: InputMaybe<SortOrder>;
};

export type MatchTeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type MatchTeamOrderByWithAggregationInput = {
  _avg?: InputMaybe<MatchTeamAvgOrderByAggregateInput>;
  _count?: InputMaybe<MatchTeamCountOrderByAggregateInput>;
  _max?: InputMaybe<MatchTeamMaxOrderByAggregateInput>;
  _min?: InputMaybe<MatchTeamMinOrderByAggregateInput>;
  _sum?: InputMaybe<MatchTeamSumOrderByAggregateInput>;
  bans?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  matchId?: InputMaybe<SortOrder>;
  objectives?: InputMaybe<SortOrder>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  win?: InputMaybe<SortOrder>;
};

export type MatchTeamOrderByWithRelationInput = {
  bans?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  match?: InputMaybe<MatchOrderByWithRelationInput>;
  matchId?: InputMaybe<SortOrder>;
  objectives?: InputMaybe<SortOrder>;
  participants?: InputMaybe<MatchParticipantOrderByRelationAggregateInput>;
  teamId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  win?: InputMaybe<SortOrder>;
};

export type MatchTeamRelationFilter = {
  is?: InputMaybe<MatchTeamWhereInput>;
  isNot?: InputMaybe<MatchTeamWhereInput>;
};

export enum MatchTeamScalarFieldEnum {
  Bans = 'bans',
  CreatedAt = 'createdAt',
  MatchId = 'matchId',
  Objectives = 'objectives',
  TeamId = 'teamId',
  UpdatedAt = 'updatedAt',
  Win = 'win'
}

export type MatchTeamScalarWhereInput = {
  AND?: InputMaybe<Array<MatchTeamScalarWhereInput>>;
  NOT?: InputMaybe<Array<MatchTeamScalarWhereInput>>;
  OR?: InputMaybe<Array<MatchTeamScalarWhereInput>>;
  bans?: InputMaybe<JsonNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  matchId?: InputMaybe<StringFilter>;
  objectives?: InputMaybe<JsonNullableFilter>;
  teamId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  win?: InputMaybe<BoolFilter>;
};

export type MatchTeamScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<MatchTeamScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<MatchTeamScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<MatchTeamScalarWhereWithAggregatesInput>>;
  bans?: InputMaybe<JsonNullableListFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  matchId?: InputMaybe<StringWithAggregatesFilter>;
  objectives?: InputMaybe<JsonNullableWithAggregatesFilter>;
  teamId?: InputMaybe<IntWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  win?: InputMaybe<BoolWithAggregatesFilter>;
};

export type MatchTeamSumAggregate = {
  __typename?: 'MatchTeamSumAggregate';
  teamId?: Maybe<Scalars['Int']>;
};

export type MatchTeamSumOrderByAggregateInput = {
  teamId?: InputMaybe<SortOrder>;
};

export type MatchTeamUpdateInput = {
  bans?: InputMaybe<MatchTeamUpdatebansInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutTeamsInput>;
  objectives?: InputMaybe<Scalars['JSON']>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutTeamInput>;
  teamId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  win?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type MatchTeamUpdateManyMutationInput = {
  bans?: InputMaybe<MatchTeamUpdatebansInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  objectives?: InputMaybe<Scalars['JSON']>;
  teamId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  win?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type MatchTeamUpdateManyWithWhereWithoutMatchInput = {
  data: MatchTeamUpdateManyMutationInput;
  where: MatchTeamScalarWhereInput;
};

export type MatchTeamUpdateManyWithoutMatchInput = {
  connect?: InputMaybe<Array<MatchTeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<MatchTeamCreateOrConnectWithoutMatchInput>>;
  create?: InputMaybe<Array<MatchTeamCreateWithoutMatchInput>>;
  createMany?: InputMaybe<MatchTeamCreateManyMatchInputEnvelope>;
  delete?: InputMaybe<Array<MatchTeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<MatchTeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<MatchTeamWhereUniqueInput>>;
  set?: InputMaybe<Array<MatchTeamWhereUniqueInput>>;
  update?: InputMaybe<Array<MatchTeamUpdateWithWhereUniqueWithoutMatchInput>>;
  updateMany?: InputMaybe<Array<MatchTeamUpdateManyWithWhereWithoutMatchInput>>;
  upsert?: InputMaybe<Array<MatchTeamUpsertWithWhereUniqueWithoutMatchInput>>;
};

export type MatchTeamUpdateOneRequiredWithoutParticipantsInput = {
  connect?: InputMaybe<MatchTeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchTeamCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<MatchTeamCreateWithoutParticipantsInput>;
  update?: InputMaybe<MatchTeamUpdateWithoutParticipantsInput>;
  upsert?: InputMaybe<MatchTeamUpsertWithoutParticipantsInput>;
};

export type MatchTeamUpdateWithWhereUniqueWithoutMatchInput = {
  data: MatchTeamUpdateWithoutMatchInput;
  where: MatchTeamWhereUniqueInput;
};

export type MatchTeamUpdateWithoutMatchInput = {
  bans?: InputMaybe<MatchTeamUpdatebansInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  objectives?: InputMaybe<Scalars['JSON']>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutTeamInput>;
  teamId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  win?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type MatchTeamUpdateWithoutParticipantsInput = {
  bans?: InputMaybe<MatchTeamUpdatebansInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  match?: InputMaybe<MatchUpdateOneRequiredWithoutTeamsInput>;
  objectives?: InputMaybe<Scalars['JSON']>;
  teamId?: InputMaybe<IntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  win?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type MatchTeamUpdatebansInput = {
  push?: InputMaybe<Array<Scalars['JSON']>>;
  set?: InputMaybe<Array<Scalars['JSON']>>;
};

export type MatchTeamUpsertWithWhereUniqueWithoutMatchInput = {
  create: MatchTeamCreateWithoutMatchInput;
  update: MatchTeamUpdateWithoutMatchInput;
  where: MatchTeamWhereUniqueInput;
};

export type MatchTeamUpsertWithoutParticipantsInput = {
  create: MatchTeamCreateWithoutParticipantsInput;
  update: MatchTeamUpdateWithoutParticipantsInput;
};

export type MatchTeamWhereInput = {
  AND?: InputMaybe<Array<MatchTeamWhereInput>>;
  NOT?: InputMaybe<Array<MatchTeamWhereInput>>;
  OR?: InputMaybe<Array<MatchTeamWhereInput>>;
  bans?: InputMaybe<JsonNullableListFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  match?: InputMaybe<MatchRelationFilter>;
  matchId?: InputMaybe<StringFilter>;
  objectives?: InputMaybe<JsonNullableFilter>;
  participants?: InputMaybe<MatchParticipantListRelationFilter>;
  teamId?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  win?: InputMaybe<BoolFilter>;
};

export type MatchTeamWhereUniqueInput = {
  matchId_teamId?: InputMaybe<MatchTeamMatchIdTeamIdCompoundUniqueInput>;
};

export type MatchUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameCreation?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameDuration?: InputMaybe<IntFieldUpdateOperationsInput>;
  gameEndTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameId?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameMode?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameStartTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameType?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mapId?: InputMaybe<IntFieldUpdateOperationsInput>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutMatchInput>;
  platformId?: InputMaybe<StringFieldUpdateOperationsInput>;
  queueId?: InputMaybe<IntFieldUpdateOperationsInput>;
  teams?: InputMaybe<MatchTeamUpdateManyWithoutMatchInput>;
  tournamentCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameCreation?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameDuration?: InputMaybe<IntFieldUpdateOperationsInput>;
  gameEndTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameId?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameMode?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameStartTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameType?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mapId?: InputMaybe<IntFieldUpdateOperationsInput>;
  platformId?: InputMaybe<StringFieldUpdateOperationsInput>;
  queueId?: InputMaybe<IntFieldUpdateOperationsInput>;
  tournamentCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchUpdateOneRequiredWithoutParticipantsInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<MatchCreateWithoutParticipantsInput>;
  update?: InputMaybe<MatchUpdateWithoutParticipantsInput>;
  upsert?: InputMaybe<MatchUpsertWithoutParticipantsInput>;
};

export type MatchUpdateOneRequiredWithoutTeamsInput = {
  connect?: InputMaybe<MatchWhereUniqueInput>;
  connectOrCreate?: InputMaybe<MatchCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<MatchCreateWithoutTeamsInput>;
  update?: InputMaybe<MatchUpdateWithoutTeamsInput>;
  upsert?: InputMaybe<MatchUpsertWithoutTeamsInput>;
};

export type MatchUpdateWithoutParticipantsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameCreation?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameDuration?: InputMaybe<IntFieldUpdateOperationsInput>;
  gameEndTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameId?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameMode?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameStartTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameType?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mapId?: InputMaybe<IntFieldUpdateOperationsInput>;
  platformId?: InputMaybe<StringFieldUpdateOperationsInput>;
  queueId?: InputMaybe<IntFieldUpdateOperationsInput>;
  teams?: InputMaybe<MatchTeamUpdateManyWithoutMatchInput>;
  tournamentCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchUpdateWithoutTeamsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  dataVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameCreation?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameDuration?: InputMaybe<IntFieldUpdateOperationsInput>;
  gameEndTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameId?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameMode?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameStartTimestamp?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  gameType?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameVersion?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  mapId?: InputMaybe<IntFieldUpdateOperationsInput>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutMatchInput>;
  platformId?: InputMaybe<StringFieldUpdateOperationsInput>;
  queueId?: InputMaybe<IntFieldUpdateOperationsInput>;
  tournamentCode?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type MatchUpsertWithoutParticipantsInput = {
  create: MatchCreateWithoutParticipantsInput;
  update: MatchUpdateWithoutParticipantsInput;
};

export type MatchUpsertWithoutTeamsInput = {
  create: MatchCreateWithoutTeamsInput;
  update: MatchUpdateWithoutTeamsInput;
};

export type MatchWhereInput = {
  AND?: InputMaybe<Array<MatchWhereInput>>;
  NOT?: InputMaybe<Array<MatchWhereInput>>;
  OR?: InputMaybe<Array<MatchWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  dataVersion?: InputMaybe<StringFilter>;
  gameCreation?: InputMaybe<BigIntFilter>;
  gameDuration?: InputMaybe<IntFilter>;
  gameEndTimestamp?: InputMaybe<BigIntFilter>;
  gameId?: InputMaybe<BigIntFilter>;
  gameMode?: InputMaybe<StringFilter>;
  gameName?: InputMaybe<StringFilter>;
  gameStartTimestamp?: InputMaybe<BigIntFilter>;
  gameType?: InputMaybe<StringFilter>;
  gameVersion?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mapId?: InputMaybe<IntFilter>;
  participants?: InputMaybe<MatchParticipantListRelationFilter>;
  platformId?: InputMaybe<StringFilter>;
  queueId?: InputMaybe<IntFilter>;
  teams?: InputMaybe<MatchTeamListRelationFilter>;
  tournamentCode?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type MatchWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAccount: Account;
  createLeague: League;
  createLeagueEntry: LeagueEntry;
  createManyAccount: AffectedRowsOutput;
  createManyLeague: AffectedRowsOutput;
  createManyLeagueEntry: AffectedRowsOutput;
  createManyMatch: AffectedRowsOutput;
  createManyMatchParticipant: AffectedRowsOutput;
  createManyMatchTeam: AffectedRowsOutput;
  createManyPlayer: AffectedRowsOutput;
  createManyPlayerRedirect: AffectedRowsOutput;
  createManyRegion: AffectedRowsOutput;
  createManySession: AffectedRowsOutput;
  createManySummoner: AffectedRowsOutput;
  createManyTeam: AffectedRowsOutput;
  createManyTeamRedirect: AffectedRowsOutput;
  createManyUser: AffectedRowsOutput;
  createManyVerificationToken: AffectedRowsOutput;
  createMatch: Match;
  createMatchParticipant: MatchParticipant;
  createMatchTeam: MatchTeam;
  createPlayer: Player;
  createPlayerRedirect: PlayerRedirect;
  createRegion: Region;
  createSession: Session;
  createSummoner: Summoner;
  createTeam: Team;
  createTeamRedirect: TeamRedirect;
  createUser: User;
  createVerificationToken: VerificationToken;
  deleteAccount?: Maybe<Account>;
  deleteLeague?: Maybe<League>;
  deleteLeagueEntry?: Maybe<LeagueEntry>;
  deleteManyAccount: AffectedRowsOutput;
  deleteManyLeague: AffectedRowsOutput;
  deleteManyLeagueEntry: AffectedRowsOutput;
  deleteManyMatch: AffectedRowsOutput;
  deleteManyMatchParticipant: AffectedRowsOutput;
  deleteManyMatchTeam: AffectedRowsOutput;
  deleteManyPlayer: AffectedRowsOutput;
  deleteManyPlayerRedirect: AffectedRowsOutput;
  deleteManyRegion: AffectedRowsOutput;
  deleteManySession: AffectedRowsOutput;
  deleteManySummoner: AffectedRowsOutput;
  deleteManyTeam: AffectedRowsOutput;
  deleteManyTeamRedirect: AffectedRowsOutput;
  deleteManyUser: AffectedRowsOutput;
  deleteManyVerificationToken: AffectedRowsOutput;
  deleteMatch?: Maybe<Match>;
  deleteMatchParticipant?: Maybe<MatchParticipant>;
  deleteMatchTeam?: Maybe<MatchTeam>;
  deletePlayer?: Maybe<Player>;
  deletePlayerRedirect?: Maybe<PlayerRedirect>;
  deleteRegion?: Maybe<Region>;
  deleteSession?: Maybe<Session>;
  deleteSummoner?: Maybe<Summoner>;
  deleteTeam?: Maybe<Team>;
  deleteTeamRedirect?: Maybe<TeamRedirect>;
  deleteUser?: Maybe<User>;
  deleteVerificationToken?: Maybe<VerificationToken>;
  updateAccount?: Maybe<Account>;
  updateLeague?: Maybe<League>;
  updateLeagueEntry?: Maybe<LeagueEntry>;
  updateManyAccount: AffectedRowsOutput;
  updateManyLeague: AffectedRowsOutput;
  updateManyLeagueEntry: AffectedRowsOutput;
  updateManyMatch: AffectedRowsOutput;
  updateManyMatchParticipant: AffectedRowsOutput;
  updateManyMatchTeam: AffectedRowsOutput;
  updateManyPlayer: AffectedRowsOutput;
  updateManyPlayerRedirect: AffectedRowsOutput;
  updateManyRegion: AffectedRowsOutput;
  updateManySession: AffectedRowsOutput;
  updateManySummoner: AffectedRowsOutput;
  updateManyTeam: AffectedRowsOutput;
  updateManyTeamRedirect: AffectedRowsOutput;
  updateManyUser: AffectedRowsOutput;
  updateManyVerificationToken: AffectedRowsOutput;
  updateMatch?: Maybe<Match>;
  updateMatchParticipant?: Maybe<MatchParticipant>;
  updateMatchTeam?: Maybe<MatchTeam>;
  updatePlayer?: Maybe<Player>;
  updatePlayerRedirect?: Maybe<PlayerRedirect>;
  updateRegion?: Maybe<Region>;
  updateSession?: Maybe<Session>;
  updateSummoner?: Maybe<Summoner>;
  updateTeam?: Maybe<Team>;
  updateTeamRedirect?: Maybe<TeamRedirect>;
  updateUser?: Maybe<User>;
  updateVerificationToken?: Maybe<VerificationToken>;
  upsertAccount: Account;
  upsertLeague: League;
  upsertLeagueEntry: LeagueEntry;
  upsertMatch: Match;
  upsertMatchParticipant: MatchParticipant;
  upsertMatchTeam: MatchTeam;
  upsertPlayer: Player;
  upsertPlayerRedirect: PlayerRedirect;
  upsertRegion: Region;
  upsertSession: Session;
  upsertSummoner: Summoner;
  upsertTeam: Team;
  upsertTeamRedirect: TeamRedirect;
  upsertUser: User;
  upsertVerificationToken: VerificationToken;
};


export type MutationCreateAccountArgs = {
  data: AccountCreateInput;
};


export type MutationCreateLeagueArgs = {
  data: LeagueCreateInput;
};


export type MutationCreateLeagueEntryArgs = {
  data: LeagueEntryCreateInput;
};


export type MutationCreateManyAccountArgs = {
  data: Array<AccountCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyLeagueArgs = {
  data: Array<LeagueCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyLeagueEntryArgs = {
  data: Array<LeagueEntryCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyMatchArgs = {
  data: Array<MatchCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyMatchParticipantArgs = {
  data: Array<MatchParticipantCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyMatchTeamArgs = {
  data: Array<MatchTeamCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPlayerArgs = {
  data: Array<PlayerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyPlayerRedirectArgs = {
  data: Array<PlayerRedirectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyRegionArgs = {
  data: Array<RegionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySessionArgs = {
  data: Array<SessionCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManySummonerArgs = {
  data: Array<SummonerCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTeamArgs = {
  data: Array<TeamCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyTeamRedirectArgs = {
  data: Array<TeamRedirectCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyUserArgs = {
  data: Array<UserCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateManyVerificationTokenArgs = {
  data: Array<VerificationTokenCreateManyInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateMatchArgs = {
  data: MatchCreateInput;
};


export type MutationCreateMatchParticipantArgs = {
  data: MatchParticipantCreateInput;
};


export type MutationCreateMatchTeamArgs = {
  data: MatchTeamCreateInput;
};


export type MutationCreatePlayerArgs = {
  data: PlayerCreateInput;
};


export type MutationCreatePlayerRedirectArgs = {
  data: PlayerRedirectCreateInput;
};


export type MutationCreateRegionArgs = {
  data: RegionCreateInput;
};


export type MutationCreateSessionArgs = {
  data: SessionCreateInput;
};


export type MutationCreateSummonerArgs = {
  data: SummonerCreateInput;
};


export type MutationCreateTeamArgs = {
  data: TeamCreateInput;
};


export type MutationCreateTeamRedirectArgs = {
  data: TeamRedirectCreateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationCreateVerificationTokenArgs = {
  data: VerificationTokenCreateInput;
};


export type MutationDeleteAccountArgs = {
  where: AccountWhereUniqueInput;
};


export type MutationDeleteLeagueArgs = {
  where: LeagueWhereUniqueInput;
};


export type MutationDeleteLeagueEntryArgs = {
  where: LeagueEntryWhereUniqueInput;
};


export type MutationDeleteManyAccountArgs = {
  where?: InputMaybe<AccountWhereInput>;
};


export type MutationDeleteManyLeagueArgs = {
  where?: InputMaybe<LeagueWhereInput>;
};


export type MutationDeleteManyLeagueEntryArgs = {
  where?: InputMaybe<LeagueEntryWhereInput>;
};


export type MutationDeleteManyMatchArgs = {
  where?: InputMaybe<MatchWhereInput>;
};


export type MutationDeleteManyMatchParticipantArgs = {
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type MutationDeleteManyMatchTeamArgs = {
  where?: InputMaybe<MatchTeamWhereInput>;
};


export type MutationDeleteManyPlayerArgs = {
  where?: InputMaybe<PlayerWhereInput>;
};


export type MutationDeleteManyPlayerRedirectArgs = {
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type MutationDeleteManyRegionArgs = {
  where?: InputMaybe<RegionWhereInput>;
};


export type MutationDeleteManySessionArgs = {
  where?: InputMaybe<SessionWhereInput>;
};


export type MutationDeleteManySummonerArgs = {
  where?: InputMaybe<SummonerWhereInput>;
};


export type MutationDeleteManyTeamArgs = {
  where?: InputMaybe<TeamWhereInput>;
};


export type MutationDeleteManyTeamRedirectArgs = {
  where?: InputMaybe<TeamRedirectWhereInput>;
};


export type MutationDeleteManyUserArgs = {
  where?: InputMaybe<UserWhereInput>;
};


export type MutationDeleteManyVerificationTokenArgs = {
  where?: InputMaybe<VerificationTokenWhereInput>;
};


export type MutationDeleteMatchArgs = {
  where: MatchWhereUniqueInput;
};


export type MutationDeleteMatchParticipantArgs = {
  where: MatchParticipantWhereUniqueInput;
};


export type MutationDeleteMatchTeamArgs = {
  where: MatchTeamWhereUniqueInput;
};


export type MutationDeletePlayerArgs = {
  where: PlayerWhereUniqueInput;
};


export type MutationDeletePlayerRedirectArgs = {
  where: PlayerRedirectWhereUniqueInput;
};


export type MutationDeleteRegionArgs = {
  where: RegionWhereUniqueInput;
};


export type MutationDeleteSessionArgs = {
  where: SessionWhereUniqueInput;
};


export type MutationDeleteSummonerArgs = {
  where: SummonerWhereUniqueInput;
};


export type MutationDeleteTeamArgs = {
  where: TeamWhereUniqueInput;
};


export type MutationDeleteTeamRedirectArgs = {
  where: TeamRedirectWhereUniqueInput;
};


export type MutationDeleteUserArgs = {
  where: UserWhereUniqueInput;
};


export type MutationDeleteVerificationTokenArgs = {
  where: VerificationTokenWhereUniqueInput;
};


export type MutationUpdateAccountArgs = {
  data: AccountUpdateInput;
  where: AccountWhereUniqueInput;
};


export type MutationUpdateLeagueArgs = {
  data: LeagueUpdateInput;
  where: LeagueWhereUniqueInput;
};


export type MutationUpdateLeagueEntryArgs = {
  data: LeagueEntryUpdateInput;
  where: LeagueEntryWhereUniqueInput;
};


export type MutationUpdateManyAccountArgs = {
  data: AccountUpdateManyMutationInput;
  where?: InputMaybe<AccountWhereInput>;
};


export type MutationUpdateManyLeagueArgs = {
  data: LeagueUpdateManyMutationInput;
  where?: InputMaybe<LeagueWhereInput>;
};


export type MutationUpdateManyLeagueEntryArgs = {
  data: LeagueEntryUpdateManyMutationInput;
  where?: InputMaybe<LeagueEntryWhereInput>;
};


export type MutationUpdateManyMatchArgs = {
  data: MatchUpdateManyMutationInput;
  where?: InputMaybe<MatchWhereInput>;
};


export type MutationUpdateManyMatchParticipantArgs = {
  data: MatchParticipantUpdateManyMutationInput;
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type MutationUpdateManyMatchTeamArgs = {
  data: MatchTeamUpdateManyMutationInput;
  where?: InputMaybe<MatchTeamWhereInput>;
};


export type MutationUpdateManyPlayerArgs = {
  data: PlayerUpdateManyMutationInput;
  where?: InputMaybe<PlayerWhereInput>;
};


export type MutationUpdateManyPlayerRedirectArgs = {
  data: PlayerRedirectUpdateManyMutationInput;
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type MutationUpdateManyRegionArgs = {
  data: RegionUpdateManyMutationInput;
  where?: InputMaybe<RegionWhereInput>;
};


export type MutationUpdateManySessionArgs = {
  data: SessionUpdateManyMutationInput;
  where?: InputMaybe<SessionWhereInput>;
};


export type MutationUpdateManySummonerArgs = {
  data: SummonerUpdateManyMutationInput;
  where?: InputMaybe<SummonerWhereInput>;
};


export type MutationUpdateManyTeamArgs = {
  data: TeamUpdateManyMutationInput;
  where?: InputMaybe<TeamWhereInput>;
};


export type MutationUpdateManyTeamRedirectArgs = {
  data: TeamRedirectUpdateManyMutationInput;
  where?: InputMaybe<TeamRedirectWhereInput>;
};


export type MutationUpdateManyUserArgs = {
  data: UserUpdateManyMutationInput;
  where?: InputMaybe<UserWhereInput>;
};


export type MutationUpdateManyVerificationTokenArgs = {
  data: VerificationTokenUpdateManyMutationInput;
  where?: InputMaybe<VerificationTokenWhereInput>;
};


export type MutationUpdateMatchArgs = {
  data: MatchUpdateInput;
  where: MatchWhereUniqueInput;
};


export type MutationUpdateMatchParticipantArgs = {
  data: MatchParticipantUpdateInput;
  where: MatchParticipantWhereUniqueInput;
};


export type MutationUpdateMatchTeamArgs = {
  data: MatchTeamUpdateInput;
  where: MatchTeamWhereUniqueInput;
};


export type MutationUpdatePlayerArgs = {
  data: PlayerUpdateInput;
  where: PlayerWhereUniqueInput;
};


export type MutationUpdatePlayerRedirectArgs = {
  data: PlayerRedirectUpdateInput;
  where: PlayerRedirectWhereUniqueInput;
};


export type MutationUpdateRegionArgs = {
  data: RegionUpdateInput;
  where: RegionWhereUniqueInput;
};


export type MutationUpdateSessionArgs = {
  data: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};


export type MutationUpdateSummonerArgs = {
  data: SummonerUpdateInput;
  where: SummonerWhereUniqueInput;
};


export type MutationUpdateTeamArgs = {
  data: TeamUpdateInput;
  where: TeamWhereUniqueInput;
};


export type MutationUpdateTeamRedirectArgs = {
  data: TeamRedirectUpdateInput;
  where: TeamRedirectWhereUniqueInput;
};


export type MutationUpdateUserArgs = {
  data: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpdateVerificationTokenArgs = {
  data: VerificationTokenUpdateInput;
  where: VerificationTokenWhereUniqueInput;
};


export type MutationUpsertAccountArgs = {
  create: AccountCreateInput;
  update: AccountUpdateInput;
  where: AccountWhereUniqueInput;
};


export type MutationUpsertLeagueArgs = {
  create: LeagueCreateInput;
  update: LeagueUpdateInput;
  where: LeagueWhereUniqueInput;
};


export type MutationUpsertLeagueEntryArgs = {
  create: LeagueEntryCreateInput;
  update: LeagueEntryUpdateInput;
  where: LeagueEntryWhereUniqueInput;
};


export type MutationUpsertMatchArgs = {
  create: MatchCreateInput;
  update: MatchUpdateInput;
  where: MatchWhereUniqueInput;
};


export type MutationUpsertMatchParticipantArgs = {
  create: MatchParticipantCreateInput;
  update: MatchParticipantUpdateInput;
  where: MatchParticipantWhereUniqueInput;
};


export type MutationUpsertMatchTeamArgs = {
  create: MatchTeamCreateInput;
  update: MatchTeamUpdateInput;
  where: MatchTeamWhereUniqueInput;
};


export type MutationUpsertPlayerArgs = {
  create: PlayerCreateInput;
  update: PlayerUpdateInput;
  where: PlayerWhereUniqueInput;
};


export type MutationUpsertPlayerRedirectArgs = {
  create: PlayerRedirectCreateInput;
  update: PlayerRedirectUpdateInput;
  where: PlayerRedirectWhereUniqueInput;
};


export type MutationUpsertRegionArgs = {
  create: RegionCreateInput;
  update: RegionUpdateInput;
  where: RegionWhereUniqueInput;
};


export type MutationUpsertSessionArgs = {
  create: SessionCreateInput;
  update: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};


export type MutationUpsertSummonerArgs = {
  create: SummonerCreateInput;
  update: SummonerUpdateInput;
  where: SummonerWhereUniqueInput;
};


export type MutationUpsertTeamArgs = {
  create: TeamCreateInput;
  update: TeamUpdateInput;
  where: TeamWhereUniqueInput;
};


export type MutationUpsertTeamRedirectArgs = {
  create: TeamRedirectCreateInput;
  update: TeamRedirectUpdateInput;
  where: TeamRedirectWhereUniqueInput;
};


export type MutationUpsertUserArgs = {
  create: UserCreateInput;
  update: UserUpdateInput;
  where: UserWhereUniqueInput;
};


export type MutationUpsertVerificationTokenArgs = {
  create: VerificationTokenCreateInput;
  update: VerificationTokenUpdateInput;
  where: VerificationTokenWhereUniqueInput;
};

export type NestedBigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type NestedBigIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBigIntFilter>;
  _min?: InputMaybe<NestedBigIntFilter>;
  _sum?: InputMaybe<NestedBigIntFilter>;
  equals?: InputMaybe<Scalars['BigInt']>;
  gt?: InputMaybe<Scalars['BigInt']>;
  gte?: InputMaybe<Scalars['BigInt']>;
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  lt?: InputMaybe<Scalars['BigInt']>;
  lte?: InputMaybe<Scalars['BigInt']>;
  not?: InputMaybe<NestedBigIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableFilter>;
};

export type NestedBoolNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedBoolNullableFilter>;
  _min?: InputMaybe<NestedBoolNullableFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolNullableWithAggregatesFilter>;
};

export type NestedBoolWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedBoolFilter>;
  _min?: InputMaybe<NestedBoolFilter>;
  equals?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<NestedBoolWithAggregatesFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumDivisionFilter = {
  equals?: InputMaybe<Division>;
  in?: InputMaybe<Array<Division>>;
  not?: InputMaybe<NestedEnumDivisionFilter>;
  notIn?: InputMaybe<Array<Division>>;
};

export type NestedEnumDivisionWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumDivisionFilter>;
  _min?: InputMaybe<NestedEnumDivisionFilter>;
  equals?: InputMaybe<Division>;
  in?: InputMaybe<Array<Division>>;
  not?: InputMaybe<NestedEnumDivisionWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Division>>;
};

export type NestedEnumPlatformFilter = {
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<NestedEnumPlatformFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type NestedEnumPlatformWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumPlatformFilter>;
  _min?: InputMaybe<NestedEnumPlatformFilter>;
  equals?: InputMaybe<Platform>;
  in?: InputMaybe<Array<Platform>>;
  not?: InputMaybe<NestedEnumPlatformWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Platform>>;
};

export type NestedEnumQueueFilter = {
  equals?: InputMaybe<Queue>;
  in?: InputMaybe<Array<Queue>>;
  not?: InputMaybe<NestedEnumQueueFilter>;
  notIn?: InputMaybe<Array<Queue>>;
};

export type NestedEnumQueueWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumQueueFilter>;
  _min?: InputMaybe<NestedEnumQueueFilter>;
  equals?: InputMaybe<Queue>;
  in?: InputMaybe<Array<Queue>>;
  not?: InputMaybe<NestedEnumQueueWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Queue>>;
};

export type NestedEnumTierFilter = {
  equals?: InputMaybe<Tier>;
  in?: InputMaybe<Array<Tier>>;
  not?: InputMaybe<NestedEnumTierFilter>;
  notIn?: InputMaybe<Array<Tier>>;
};

export type NestedEnumTierWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumTierFilter>;
  _min?: InputMaybe<NestedEnumTierFilter>;
  equals?: InputMaybe<Tier>;
  in?: InputMaybe<Array<Tier>>;
  not?: InputMaybe<NestedEnumTierWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Tier>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedFloatNullableFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatNullableFilter>;
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedIntNullableFilter>;
  _min?: InputMaybe<NestedIntNullableFilter>;
  _sum?: InputMaybe<NestedIntNullableFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntWithAggregatesFilter = {
  _avg?: InputMaybe<NestedFloatFilter>;
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedIntFilter>;
  _min?: InputMaybe<NestedIntFilter>;
  _sum?: InputMaybe<NestedIntFilter>;
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedJsonFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type NestedJsonNullableFilter = {
  equals?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<Scalars['JSON']>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  decrement?: InputMaybe<Scalars['Int']>;
  divide?: InputMaybe<Scalars['Int']>;
  increment?: InputMaybe<Scalars['Int']>;
  multiply?: InputMaybe<Scalars['Int']>;
  set?: InputMaybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export enum Platform {
  Br1 = 'BR1',
  Eun1 = 'EUN1',
  Euw1 = 'EUW1',
  Jp1 = 'JP1',
  Kr = 'KR',
  La1 = 'LA1',
  La2 = 'LA2',
  Na1 = 'NA1',
  Oc1 = 'OC1',
  Ru = 'RU',
  Tr1 = 'TR1'
}

export type Player = {
  __typename?: 'Player';
  /** Player's age */
  Age?: Maybe<Scalars['Int']>;
  /** Player's AskFM (username only) */
  Askfm?: Maybe<Scalars['String']>;
  /** Player's birthday */
  Birthdate?: Maybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  /** Contract end date */
  Contract?: Maybe<Scalars['DateTime']>;
  /** Country of birth */
  Country?: Maybe<Scalars['String']>;
  /** List of all current teams */
  CurrentTeams: Array<Scalars['String']>;
  /** Player's Discord server (full URL) */
  Discord?: Maybe<Scalars['String']>;
  /** Player's Facebook (full URL) */
  Facebook?: Maybe<Scalars['String']>;
  /** Favorite champions - this value is filled manually and may have nothing to do with performance */
  FavChamps: Array<Scalars['String']>;
  /** Player's current IGN */
  ID: Scalars['String'];
  /** File name of the player's image on the wiki, not prefixed by "File:". Able to be joined to _pageData._pageTitle. */
  Image?: Maybe<Scalars['String']>;
  /** Player's Instagram (Username only) */
  Instagram?: Maybe<Scalars['String']>;
  /** Is the team auto generated from roster portal? */
  IsAutoTeam: Scalars['Boolean'];
  /** Is this a low-content page? For internal use only. */
  IsLowContent: Scalars['Boolean'];
  /** Should the title of this page be lowercased? */
  IsLowercase: Scalars['Boolean'];
  /** Is this a page about a personality rather than a player? */
  IsPersonality: Scalars['Boolean'];
  /** Is the player retired? */
  IsRetired: Scalars['Boolean'];
  /** Is the player a substitute on their current team? */
  IsSubstitute?: Maybe<Scalars['Boolean']>;
  /** Is the player a trainee on their current team? */
  IsTrainee?: Maybe<Scalars['Boolean']>;
  /** Player's LoLPros webpage (full URL */
  Lolpros?: Maybe<Scalars['String']>;
  /** IRL name of the player */
  Name?: Maybe<Scalars['String']>;
  /** Alphabet used in NativeName field, if applicable */
  NameAlphabet?: Maybe<Scalars['String']>;
  /** Transliterated name alongside native name, if native name is defined; this field can be derived from Name and NativeName but exists for convenience */
  NameFull?: Maybe<Scalars['String']>;
  /** Country of nationality */
  Nationality: Array<Scalars['String']>;
  /** Only the first country of nationality */
  NationalityPrimary?: Maybe<Scalars['String']>;
  /** IRL name of the player, not transliterated to English characters, nil if the player's name is already in English characters */
  NativeName?: Maybe<Scalars['String']>;
  /** alias for _pageName, use this instead */
  OverviewPage: Scalars['String'];
  /** Case-sensitive version of OverviewPage */
  Player: Scalars['String'];
  /** Player's reddit (Username only) */
  Reddit?: Maybe<Scalars['String']>;
  /** Official Riot residency */
  Residency?: Maybe<Scalars['String']>;
  /** Previous Riot-official residency the player has held, if any */
  ResidencyFormer?: Maybe<Scalars['String']>;
  /** Player's primary role */
  Role?: Maybe<Scalars['String']>;
  /** Last role the player had, may be same as current role */
  RoleLast: Array<Scalars['String']>;
  /** Player's Snapchat account (username only) */
  Snapchat?: Maybe<Scalars['String']>;
  /** List of soloqueue IDs, this is manually maintained and may be extremely incomplete or inaccurate */
  SoloqueueIds?: Maybe<Scalars['String']>;
  /** Player's Stream (full URL) */
  Stream?: Maybe<Scalars['String']>;
  /** Current team */
  Team?: Maybe<Scalars['String']>;
  /** Second team, if any - more than 2 teams may be displayed but only two are stored */
  Team2?: Maybe<Scalars['String']>;
  /** Team 2 System (PC/XBox/etc) - may not apply to individual wikis but added to all for consistency */
  Team2System: Scalars['String'];
  /** Last team the player played on, may be same as current team */
  TeamLast?: Maybe<Scalars['String']>;
  /** Team 1 System (PC/XBox/etc) - may not apply to individual wikis but added to all for consistency */
  TeamSystem: Scalars['String'];
  /** Did the player move to WildRift? Will only be True if IsRetired is also True */
  ToWildrift: Scalars['Boolean'];
  /** Player's Twitter ID (username only) */
  Twitter?: Maybe<Scalars['String']>;
  /** Player's VKontakte (full URL) */
  Vk?: Maybe<Scalars['String']>;
  /** Player's website or fansite (full URL) */
  Website?: Maybe<Scalars['String']>;
  /** Player's Weibo (full URL) */
  Weibo?: Maybe<Scalars['String']>;
  /** Player's YouTube (full URL) */
  Youtube?: Maybe<Scalars['String']>;
  _count?: Maybe<PlayerCount>;
  playerRedirects: Array<PlayerRedirect>;
  recent?: Maybe<Scalars['DateTime']>;
  summoners: Array<Summoner>;
  team?: Maybe<Team>;
};


export type PlayerPlayerRedirectsArgs = {
  cursor?: InputMaybe<PlayerRedirectWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerRedirectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type PlayerSummonersArgs = {
  cursor?: InputMaybe<SummonerWhereUniqueInput>;
  distinct?: InputMaybe<Array<SummonerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SummonerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SummonerWhereInput>;
};

export type PlayerAvgAggregate = {
  __typename?: 'PlayerAvgAggregate';
  Age?: Maybe<Scalars['Float']>;
  Cargo_ID?: Maybe<Scalars['Float']>;
  Cargo_pageID?: Maybe<Scalars['Float']>;
  Cargo_pageNamespace?: Maybe<Scalars['Float']>;
};

export type PlayerAvgOrderByAggregateInput = {
  Age?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type PlayerCount = {
  __typename?: 'PlayerCount';
  playerRedirects: Scalars['Int'];
  summoners: Scalars['Int'];
};

export type PlayerCountAggregate = {
  __typename?: 'PlayerCountAggregate';
  Age: Scalars['Int'];
  Askfm: Scalars['Int'];
  Birthdate: Scalars['Int'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['Int'];
  Contract: Scalars['Int'];
  Country: Scalars['Int'];
  CurrentTeams: Scalars['Int'];
  Discord: Scalars['Int'];
  Facebook: Scalars['Int'];
  FavChamps: Scalars['Int'];
  ID: Scalars['Int'];
  Image: Scalars['Int'];
  Instagram: Scalars['Int'];
  IsAutoTeam: Scalars['Int'];
  IsLowContent: Scalars['Int'];
  IsLowercase: Scalars['Int'];
  IsPersonality: Scalars['Int'];
  IsRetired: Scalars['Int'];
  IsSubstitute: Scalars['Int'];
  IsTrainee: Scalars['Int'];
  Lolpros: Scalars['Int'];
  Name: Scalars['Int'];
  NameAlphabet: Scalars['Int'];
  NameFull: Scalars['Int'];
  Nationality: Scalars['Int'];
  NationalityPrimary: Scalars['Int'];
  NativeName: Scalars['Int'];
  OverviewPage: Scalars['Int'];
  Player: Scalars['Int'];
  Reddit: Scalars['Int'];
  Residency: Scalars['Int'];
  ResidencyFormer: Scalars['Int'];
  Role: Scalars['Int'];
  RoleLast: Scalars['Int'];
  Snapchat: Scalars['Int'];
  SoloqueueIds: Scalars['Int'];
  Stream: Scalars['Int'];
  Team: Scalars['Int'];
  Team2: Scalars['Int'];
  Team2System: Scalars['Int'];
  TeamLast: Scalars['Int'];
  TeamSystem: Scalars['Int'];
  ToWildrift: Scalars['Int'];
  Twitter: Scalars['Int'];
  Vk: Scalars['Int'];
  Website: Scalars['Int'];
  Weibo: Scalars['Int'];
  Youtube: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PlayerCountOrderByAggregateInput = {
  Age?: InputMaybe<SortOrder>;
  Askfm?: InputMaybe<SortOrder>;
  Birthdate?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Contract?: InputMaybe<SortOrder>;
  Country?: InputMaybe<SortOrder>;
  CurrentTeams?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  FavChamps?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsAutoTeam?: InputMaybe<SortOrder>;
  IsLowContent?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  IsPersonality?: InputMaybe<SortOrder>;
  IsRetired?: InputMaybe<SortOrder>;
  IsSubstitute?: InputMaybe<SortOrder>;
  IsTrainee?: InputMaybe<SortOrder>;
  Lolpros?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  NameAlphabet?: InputMaybe<SortOrder>;
  NameFull?: InputMaybe<SortOrder>;
  Nationality?: InputMaybe<SortOrder>;
  NationalityPrimary?: InputMaybe<SortOrder>;
  NativeName?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Player?: InputMaybe<SortOrder>;
  Reddit?: InputMaybe<SortOrder>;
  Residency?: InputMaybe<SortOrder>;
  ResidencyFormer?: InputMaybe<SortOrder>;
  Role?: InputMaybe<SortOrder>;
  RoleLast?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  SoloqueueIds?: InputMaybe<SortOrder>;
  Stream?: InputMaybe<SortOrder>;
  Team?: InputMaybe<SortOrder>;
  Team2?: InputMaybe<SortOrder>;
  Team2System?: InputMaybe<SortOrder>;
  TeamLast?: InputMaybe<SortOrder>;
  TeamSystem?: InputMaybe<SortOrder>;
  ToWildrift?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Weibo?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
};

export type PlayerCreateCurrentTeamsInput = {
  set: Array<Scalars['String']>;
};

export type PlayerCreateFavChampsInput = {
  set: Array<Scalars['String']>;
};

export type PlayerCreateInput = {
  Age?: InputMaybe<Scalars['Int']>;
  Askfm?: InputMaybe<Scalars['String']>;
  Birthdate?: InputMaybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: InputMaybe<Scalars['DateTime']>;
  Country?: InputMaybe<Scalars['String']>;
  CurrentTeams?: InputMaybe<PlayerCreateCurrentTeamsInput>;
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  FavChamps?: InputMaybe<PlayerCreateFavChampsInput>;
  ID: Scalars['String'];
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: InputMaybe<Scalars['Boolean']>;
  IsTrainee?: InputMaybe<Scalars['Boolean']>;
  Lolpros?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  NameAlphabet?: InputMaybe<Scalars['String']>;
  NameFull?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<PlayerCreateNationalityInput>;
  NationalityPrimary?: InputMaybe<Scalars['String']>;
  NativeName?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: InputMaybe<Scalars['String']>;
  Residency?: InputMaybe<Scalars['String']>;
  ResidencyFormer?: InputMaybe<Scalars['String']>;
  Role?: InputMaybe<Scalars['String']>;
  RoleLast?: InputMaybe<PlayerCreateRoleLastInput>;
  Snapchat?: InputMaybe<Scalars['String']>;
  SoloqueueIds?: InputMaybe<Scalars['String']>;
  Stream?: InputMaybe<Scalars['String']>;
  Team2?: InputMaybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: InputMaybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Weibo?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  playerRedirects?: InputMaybe<PlayerRedirectCreateNestedManyWithoutPlayerInput>;
  summoners?: InputMaybe<SummonerCreateNestedManyWithoutPlayerInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutPlayersInput>;
};

export type PlayerCreateManyInput = {
  Age?: InputMaybe<Scalars['Int']>;
  Askfm?: InputMaybe<Scalars['String']>;
  Birthdate?: InputMaybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: InputMaybe<Scalars['DateTime']>;
  Country?: InputMaybe<Scalars['String']>;
  CurrentTeams?: InputMaybe<PlayerCreateCurrentTeamsInput>;
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  FavChamps?: InputMaybe<PlayerCreateFavChampsInput>;
  ID: Scalars['String'];
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: InputMaybe<Scalars['Boolean']>;
  IsTrainee?: InputMaybe<Scalars['Boolean']>;
  Lolpros?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  NameAlphabet?: InputMaybe<Scalars['String']>;
  NameFull?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<PlayerCreateNationalityInput>;
  NationalityPrimary?: InputMaybe<Scalars['String']>;
  NativeName?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: InputMaybe<Scalars['String']>;
  Residency?: InputMaybe<Scalars['String']>;
  ResidencyFormer?: InputMaybe<Scalars['String']>;
  Role?: InputMaybe<Scalars['String']>;
  RoleLast?: InputMaybe<PlayerCreateRoleLastInput>;
  Snapchat?: InputMaybe<Scalars['String']>;
  SoloqueueIds?: InputMaybe<Scalars['String']>;
  Stream?: InputMaybe<Scalars['String']>;
  Team?: InputMaybe<Scalars['String']>;
  Team2?: InputMaybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: InputMaybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Weibo?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
};

export type PlayerCreateManyTeamInput = {
  Age?: InputMaybe<Scalars['Int']>;
  Askfm?: InputMaybe<Scalars['String']>;
  Birthdate?: InputMaybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: InputMaybe<Scalars['DateTime']>;
  Country?: InputMaybe<Scalars['String']>;
  CurrentTeams?: InputMaybe<PlayerCreateCurrentTeamsInput>;
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  FavChamps?: InputMaybe<PlayerCreateFavChampsInput>;
  ID: Scalars['String'];
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: InputMaybe<Scalars['Boolean']>;
  IsTrainee?: InputMaybe<Scalars['Boolean']>;
  Lolpros?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  NameAlphabet?: InputMaybe<Scalars['String']>;
  NameFull?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<PlayerCreateNationalityInput>;
  NationalityPrimary?: InputMaybe<Scalars['String']>;
  NativeName?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: InputMaybe<Scalars['String']>;
  Residency?: InputMaybe<Scalars['String']>;
  ResidencyFormer?: InputMaybe<Scalars['String']>;
  Role?: InputMaybe<Scalars['String']>;
  RoleLast?: InputMaybe<PlayerCreateRoleLastInput>;
  Snapchat?: InputMaybe<Scalars['String']>;
  SoloqueueIds?: InputMaybe<Scalars['String']>;
  Stream?: InputMaybe<Scalars['String']>;
  Team2?: InputMaybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: InputMaybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Weibo?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
};

export type PlayerCreateManyTeamInputEnvelope = {
  data: Array<PlayerCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PlayerCreateNationalityInput = {
  set: Array<Scalars['String']>;
};

export type PlayerCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlayerCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<PlayerCreateWithoutTeamInput>>;
  createMany?: InputMaybe<PlayerCreateManyTeamInputEnvelope>;
};

export type PlayerCreateNestedOneWithoutPlayerRedirectsInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutPlayerRedirectsInput>;
  create?: InputMaybe<PlayerCreateWithoutPlayerRedirectsInput>;
};

export type PlayerCreateNestedOneWithoutSummonersInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutSummonersInput>;
  create?: InputMaybe<PlayerCreateWithoutSummonersInput>;
};

export type PlayerCreateOrConnectWithoutPlayerRedirectsInput = {
  create: PlayerCreateWithoutPlayerRedirectsInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateOrConnectWithoutSummonersInput = {
  create: PlayerCreateWithoutSummonersInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateOrConnectWithoutTeamInput = {
  create: PlayerCreateWithoutTeamInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerCreateRoleLastInput = {
  set: Array<Scalars['String']>;
};

export type PlayerCreateWithoutPlayerRedirectsInput = {
  Age?: InputMaybe<Scalars['Int']>;
  Askfm?: InputMaybe<Scalars['String']>;
  Birthdate?: InputMaybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: InputMaybe<Scalars['DateTime']>;
  Country?: InputMaybe<Scalars['String']>;
  CurrentTeams?: InputMaybe<PlayerCreateCurrentTeamsInput>;
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  FavChamps?: InputMaybe<PlayerCreateFavChampsInput>;
  ID: Scalars['String'];
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: InputMaybe<Scalars['Boolean']>;
  IsTrainee?: InputMaybe<Scalars['Boolean']>;
  Lolpros?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  NameAlphabet?: InputMaybe<Scalars['String']>;
  NameFull?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<PlayerCreateNationalityInput>;
  NationalityPrimary?: InputMaybe<Scalars['String']>;
  NativeName?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: InputMaybe<Scalars['String']>;
  Residency?: InputMaybe<Scalars['String']>;
  ResidencyFormer?: InputMaybe<Scalars['String']>;
  Role?: InputMaybe<Scalars['String']>;
  RoleLast?: InputMaybe<PlayerCreateRoleLastInput>;
  Snapchat?: InputMaybe<Scalars['String']>;
  SoloqueueIds?: InputMaybe<Scalars['String']>;
  Stream?: InputMaybe<Scalars['String']>;
  Team2?: InputMaybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: InputMaybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Weibo?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  summoners?: InputMaybe<SummonerCreateNestedManyWithoutPlayerInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutPlayersInput>;
};

export type PlayerCreateWithoutSummonersInput = {
  Age?: InputMaybe<Scalars['Int']>;
  Askfm?: InputMaybe<Scalars['String']>;
  Birthdate?: InputMaybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: InputMaybe<Scalars['DateTime']>;
  Country?: InputMaybe<Scalars['String']>;
  CurrentTeams?: InputMaybe<PlayerCreateCurrentTeamsInput>;
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  FavChamps?: InputMaybe<PlayerCreateFavChampsInput>;
  ID: Scalars['String'];
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: InputMaybe<Scalars['Boolean']>;
  IsTrainee?: InputMaybe<Scalars['Boolean']>;
  Lolpros?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  NameAlphabet?: InputMaybe<Scalars['String']>;
  NameFull?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<PlayerCreateNationalityInput>;
  NationalityPrimary?: InputMaybe<Scalars['String']>;
  NativeName?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: InputMaybe<Scalars['String']>;
  Residency?: InputMaybe<Scalars['String']>;
  ResidencyFormer?: InputMaybe<Scalars['String']>;
  Role?: InputMaybe<Scalars['String']>;
  RoleLast?: InputMaybe<PlayerCreateRoleLastInput>;
  Snapchat?: InputMaybe<Scalars['String']>;
  SoloqueueIds?: InputMaybe<Scalars['String']>;
  Stream?: InputMaybe<Scalars['String']>;
  Team2?: InputMaybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: InputMaybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Weibo?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  playerRedirects?: InputMaybe<PlayerRedirectCreateNestedManyWithoutPlayerInput>;
  team?: InputMaybe<TeamCreateNestedOneWithoutPlayersInput>;
};

export type PlayerCreateWithoutTeamInput = {
  Age?: InputMaybe<Scalars['Int']>;
  Askfm?: InputMaybe<Scalars['String']>;
  Birthdate?: InputMaybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: InputMaybe<Scalars['DateTime']>;
  Country?: InputMaybe<Scalars['String']>;
  CurrentTeams?: InputMaybe<PlayerCreateCurrentTeamsInput>;
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  FavChamps?: InputMaybe<PlayerCreateFavChampsInput>;
  ID: Scalars['String'];
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: InputMaybe<Scalars['Boolean']>;
  IsTrainee?: InputMaybe<Scalars['Boolean']>;
  Lolpros?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  NameAlphabet?: InputMaybe<Scalars['String']>;
  NameFull?: InputMaybe<Scalars['String']>;
  Nationality?: InputMaybe<PlayerCreateNationalityInput>;
  NationalityPrimary?: InputMaybe<Scalars['String']>;
  NativeName?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: InputMaybe<Scalars['String']>;
  Residency?: InputMaybe<Scalars['String']>;
  ResidencyFormer?: InputMaybe<Scalars['String']>;
  Role?: InputMaybe<Scalars['String']>;
  RoleLast?: InputMaybe<PlayerCreateRoleLastInput>;
  Snapchat?: InputMaybe<Scalars['String']>;
  SoloqueueIds?: InputMaybe<Scalars['String']>;
  Stream?: InputMaybe<Scalars['String']>;
  Team2?: InputMaybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: InputMaybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Weibo?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  playerRedirects?: InputMaybe<PlayerRedirectCreateNestedManyWithoutPlayerInput>;
  summoners?: InputMaybe<SummonerCreateNestedManyWithoutPlayerInput>;
};

export type PlayerGroupBy = {
  __typename?: 'PlayerGroupBy';
  Age?: Maybe<Scalars['Int']>;
  Askfm?: Maybe<Scalars['String']>;
  Birthdate?: Maybe<Scalars['DateTime']>;
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Contract?: Maybe<Scalars['DateTime']>;
  Country?: Maybe<Scalars['String']>;
  CurrentTeams?: Maybe<Array<Scalars['String']>>;
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  FavChamps?: Maybe<Array<Scalars['String']>>;
  ID: Scalars['String'];
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsAutoTeam: Scalars['Boolean'];
  IsLowContent: Scalars['Boolean'];
  IsLowercase: Scalars['Boolean'];
  IsPersonality: Scalars['Boolean'];
  IsRetired: Scalars['Boolean'];
  IsSubstitute?: Maybe<Scalars['Boolean']>;
  IsTrainee?: Maybe<Scalars['Boolean']>;
  Lolpros?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NameAlphabet?: Maybe<Scalars['String']>;
  NameFull?: Maybe<Scalars['String']>;
  Nationality?: Maybe<Array<Scalars['String']>>;
  NationalityPrimary?: Maybe<Scalars['String']>;
  NativeName?: Maybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Player: Scalars['String'];
  Reddit?: Maybe<Scalars['String']>;
  Residency?: Maybe<Scalars['String']>;
  ResidencyFormer?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  RoleLast?: Maybe<Array<Scalars['String']>>;
  Snapchat?: Maybe<Scalars['String']>;
  SoloqueueIds?: Maybe<Scalars['String']>;
  Stream?: Maybe<Scalars['String']>;
  Team?: Maybe<Scalars['String']>;
  Team2?: Maybe<Scalars['String']>;
  Team2System: Scalars['String'];
  TeamLast?: Maybe<Scalars['String']>;
  TeamSystem: Scalars['String'];
  ToWildrift: Scalars['Boolean'];
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
  Weibo?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
  _avg?: Maybe<PlayerAvgAggregate>;
  _count?: Maybe<PlayerCountAggregate>;
  _max?: Maybe<PlayerMaxAggregate>;
  _min?: Maybe<PlayerMinAggregate>;
  _sum?: Maybe<PlayerSumAggregate>;
};

export type PlayerListRelationFilter = {
  every?: InputMaybe<PlayerWhereInput>;
  none?: InputMaybe<PlayerWhereInput>;
  some?: InputMaybe<PlayerWhereInput>;
};

export type PlayerMaxAggregate = {
  __typename?: 'PlayerMaxAggregate';
  Age?: Maybe<Scalars['Int']>;
  Askfm?: Maybe<Scalars['String']>;
  Birthdate?: Maybe<Scalars['DateTime']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  Contract?: Maybe<Scalars['DateTime']>;
  Country?: Maybe<Scalars['String']>;
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsAutoTeam?: Maybe<Scalars['Boolean']>;
  IsLowContent?: Maybe<Scalars['Boolean']>;
  IsLowercase?: Maybe<Scalars['Boolean']>;
  IsPersonality?: Maybe<Scalars['Boolean']>;
  IsRetired?: Maybe<Scalars['Boolean']>;
  IsSubstitute?: Maybe<Scalars['Boolean']>;
  IsTrainee?: Maybe<Scalars['Boolean']>;
  Lolpros?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NameAlphabet?: Maybe<Scalars['String']>;
  NameFull?: Maybe<Scalars['String']>;
  NationalityPrimary?: Maybe<Scalars['String']>;
  NativeName?: Maybe<Scalars['String']>;
  OverviewPage?: Maybe<Scalars['String']>;
  Player?: Maybe<Scalars['String']>;
  Reddit?: Maybe<Scalars['String']>;
  Residency?: Maybe<Scalars['String']>;
  ResidencyFormer?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  Snapchat?: Maybe<Scalars['String']>;
  SoloqueueIds?: Maybe<Scalars['String']>;
  Stream?: Maybe<Scalars['String']>;
  Team?: Maybe<Scalars['String']>;
  Team2?: Maybe<Scalars['String']>;
  Team2System?: Maybe<Scalars['String']>;
  TeamLast?: Maybe<Scalars['String']>;
  TeamSystem?: Maybe<Scalars['String']>;
  ToWildrift?: Maybe<Scalars['Boolean']>;
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
  Weibo?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
};

export type PlayerMaxOrderByAggregateInput = {
  Age?: InputMaybe<SortOrder>;
  Askfm?: InputMaybe<SortOrder>;
  Birthdate?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Contract?: InputMaybe<SortOrder>;
  Country?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsAutoTeam?: InputMaybe<SortOrder>;
  IsLowContent?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  IsPersonality?: InputMaybe<SortOrder>;
  IsRetired?: InputMaybe<SortOrder>;
  IsSubstitute?: InputMaybe<SortOrder>;
  IsTrainee?: InputMaybe<SortOrder>;
  Lolpros?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  NameAlphabet?: InputMaybe<SortOrder>;
  NameFull?: InputMaybe<SortOrder>;
  NationalityPrimary?: InputMaybe<SortOrder>;
  NativeName?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Player?: InputMaybe<SortOrder>;
  Reddit?: InputMaybe<SortOrder>;
  Residency?: InputMaybe<SortOrder>;
  ResidencyFormer?: InputMaybe<SortOrder>;
  Role?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  SoloqueueIds?: InputMaybe<SortOrder>;
  Stream?: InputMaybe<SortOrder>;
  Team?: InputMaybe<SortOrder>;
  Team2?: InputMaybe<SortOrder>;
  Team2System?: InputMaybe<SortOrder>;
  TeamLast?: InputMaybe<SortOrder>;
  TeamSystem?: InputMaybe<SortOrder>;
  ToWildrift?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Weibo?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
};

export type PlayerMinAggregate = {
  __typename?: 'PlayerMinAggregate';
  Age?: Maybe<Scalars['Int']>;
  Askfm?: Maybe<Scalars['String']>;
  Birthdate?: Maybe<Scalars['DateTime']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  Contract?: Maybe<Scalars['DateTime']>;
  Country?: Maybe<Scalars['String']>;
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsAutoTeam?: Maybe<Scalars['Boolean']>;
  IsLowContent?: Maybe<Scalars['Boolean']>;
  IsLowercase?: Maybe<Scalars['Boolean']>;
  IsPersonality?: Maybe<Scalars['Boolean']>;
  IsRetired?: Maybe<Scalars['Boolean']>;
  IsSubstitute?: Maybe<Scalars['Boolean']>;
  IsTrainee?: Maybe<Scalars['Boolean']>;
  Lolpros?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  NameAlphabet?: Maybe<Scalars['String']>;
  NameFull?: Maybe<Scalars['String']>;
  NationalityPrimary?: Maybe<Scalars['String']>;
  NativeName?: Maybe<Scalars['String']>;
  OverviewPage?: Maybe<Scalars['String']>;
  Player?: Maybe<Scalars['String']>;
  Reddit?: Maybe<Scalars['String']>;
  Residency?: Maybe<Scalars['String']>;
  ResidencyFormer?: Maybe<Scalars['String']>;
  Role?: Maybe<Scalars['String']>;
  Snapchat?: Maybe<Scalars['String']>;
  SoloqueueIds?: Maybe<Scalars['String']>;
  Stream?: Maybe<Scalars['String']>;
  Team?: Maybe<Scalars['String']>;
  Team2?: Maybe<Scalars['String']>;
  Team2System?: Maybe<Scalars['String']>;
  TeamLast?: Maybe<Scalars['String']>;
  TeamSystem?: Maybe<Scalars['String']>;
  ToWildrift?: Maybe<Scalars['Boolean']>;
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
  Weibo?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
};

export type PlayerMinOrderByAggregateInput = {
  Age?: InputMaybe<SortOrder>;
  Askfm?: InputMaybe<SortOrder>;
  Birthdate?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Contract?: InputMaybe<SortOrder>;
  Country?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsAutoTeam?: InputMaybe<SortOrder>;
  IsLowContent?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  IsPersonality?: InputMaybe<SortOrder>;
  IsRetired?: InputMaybe<SortOrder>;
  IsSubstitute?: InputMaybe<SortOrder>;
  IsTrainee?: InputMaybe<SortOrder>;
  Lolpros?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  NameAlphabet?: InputMaybe<SortOrder>;
  NameFull?: InputMaybe<SortOrder>;
  NationalityPrimary?: InputMaybe<SortOrder>;
  NativeName?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Player?: InputMaybe<SortOrder>;
  Reddit?: InputMaybe<SortOrder>;
  Residency?: InputMaybe<SortOrder>;
  ResidencyFormer?: InputMaybe<SortOrder>;
  Role?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  SoloqueueIds?: InputMaybe<SortOrder>;
  Stream?: InputMaybe<SortOrder>;
  Team?: InputMaybe<SortOrder>;
  Team2?: InputMaybe<SortOrder>;
  Team2System?: InputMaybe<SortOrder>;
  TeamLast?: InputMaybe<SortOrder>;
  TeamSystem?: InputMaybe<SortOrder>;
  ToWildrift?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Weibo?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
};

export type PlayerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PlayerOrderByWithAggregationInput = {
  Age?: InputMaybe<SortOrder>;
  Askfm?: InputMaybe<SortOrder>;
  Birthdate?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Contract?: InputMaybe<SortOrder>;
  Country?: InputMaybe<SortOrder>;
  CurrentTeams?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  FavChamps?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsAutoTeam?: InputMaybe<SortOrder>;
  IsLowContent?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  IsPersonality?: InputMaybe<SortOrder>;
  IsRetired?: InputMaybe<SortOrder>;
  IsSubstitute?: InputMaybe<SortOrder>;
  IsTrainee?: InputMaybe<SortOrder>;
  Lolpros?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  NameAlphabet?: InputMaybe<SortOrder>;
  NameFull?: InputMaybe<SortOrder>;
  Nationality?: InputMaybe<SortOrder>;
  NationalityPrimary?: InputMaybe<SortOrder>;
  NativeName?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Player?: InputMaybe<SortOrder>;
  Reddit?: InputMaybe<SortOrder>;
  Residency?: InputMaybe<SortOrder>;
  ResidencyFormer?: InputMaybe<SortOrder>;
  Role?: InputMaybe<SortOrder>;
  RoleLast?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  SoloqueueIds?: InputMaybe<SortOrder>;
  Stream?: InputMaybe<SortOrder>;
  Team?: InputMaybe<SortOrder>;
  Team2?: InputMaybe<SortOrder>;
  Team2System?: InputMaybe<SortOrder>;
  TeamLast?: InputMaybe<SortOrder>;
  TeamSystem?: InputMaybe<SortOrder>;
  ToWildrift?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Weibo?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<PlayerAvgOrderByAggregateInput>;
  _count?: InputMaybe<PlayerCountOrderByAggregateInput>;
  _max?: InputMaybe<PlayerMaxOrderByAggregateInput>;
  _min?: InputMaybe<PlayerMinOrderByAggregateInput>;
  _sum?: InputMaybe<PlayerSumOrderByAggregateInput>;
};

export type PlayerOrderByWithRelationInput = {
  Age?: InputMaybe<SortOrder>;
  Askfm?: InputMaybe<SortOrder>;
  Birthdate?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Contract?: InputMaybe<SortOrder>;
  Country?: InputMaybe<SortOrder>;
  CurrentTeams?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  FavChamps?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsAutoTeam?: InputMaybe<SortOrder>;
  IsLowContent?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  IsPersonality?: InputMaybe<SortOrder>;
  IsRetired?: InputMaybe<SortOrder>;
  IsSubstitute?: InputMaybe<SortOrder>;
  IsTrainee?: InputMaybe<SortOrder>;
  Lolpros?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  NameAlphabet?: InputMaybe<SortOrder>;
  NameFull?: InputMaybe<SortOrder>;
  Nationality?: InputMaybe<SortOrder>;
  NationalityPrimary?: InputMaybe<SortOrder>;
  NativeName?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Player?: InputMaybe<SortOrder>;
  Reddit?: InputMaybe<SortOrder>;
  Residency?: InputMaybe<SortOrder>;
  ResidencyFormer?: InputMaybe<SortOrder>;
  Role?: InputMaybe<SortOrder>;
  RoleLast?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  SoloqueueIds?: InputMaybe<SortOrder>;
  Stream?: InputMaybe<SortOrder>;
  Team?: InputMaybe<SortOrder>;
  Team2?: InputMaybe<SortOrder>;
  Team2System?: InputMaybe<SortOrder>;
  TeamLast?: InputMaybe<SortOrder>;
  TeamSystem?: InputMaybe<SortOrder>;
  ToWildrift?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Weibo?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
  playerRedirects?: InputMaybe<PlayerRedirectOrderByRelationAggregateInput>;
  summoners?: InputMaybe<SummonerOrderByRelationAggregateInput>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
};

export type PlayerRedirect = {
  __typename?: 'PlayerRedirect';
  /** Case & special character insensitive list of redirects to team page, including the page itself */
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  /** ID without disambiguation */
  ID: Scalars['String'];
  /** Player's current page name */
  OverviewPage: Scalars['String'];
  player: Player;
};

export type PlayerRedirectAvgAggregate = {
  __typename?: 'PlayerRedirectAvgAggregate';
  Cargo_ID?: Maybe<Scalars['Float']>;
  Cargo_pageID?: Maybe<Scalars['Float']>;
  Cargo_pageNamespace?: Maybe<Scalars['Float']>;
};

export type PlayerRedirectAvgOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type PlayerRedirectCountAggregate = {
  __typename?: 'PlayerRedirectCountAggregate';
  AllName: Scalars['Int'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['Int'];
  ID: Scalars['Int'];
  OverviewPage: Scalars['Int'];
  _all: Scalars['Int'];
};

export type PlayerRedirectCountOrderByAggregateInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
};

export type PlayerRedirectCreateInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  ID: Scalars['String'];
  player: PlayerCreateNestedOneWithoutPlayerRedirectsInput;
};

export type PlayerRedirectCreateManyInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  ID: Scalars['String'];
  OverviewPage: Scalars['String'];
};

export type PlayerRedirectCreateManyPlayerInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  ID: Scalars['String'];
};

export type PlayerRedirectCreateManyPlayerInputEnvelope = {
  data: Array<PlayerRedirectCreateManyPlayerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PlayerRedirectCreateNestedManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<PlayerRedirectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlayerRedirectCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<PlayerRedirectCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<PlayerRedirectCreateManyPlayerInputEnvelope>;
};

export type PlayerRedirectCreateOrConnectWithoutPlayerInput = {
  create: PlayerRedirectCreateWithoutPlayerInput;
  where: PlayerRedirectWhereUniqueInput;
};

export type PlayerRedirectCreateWithoutPlayerInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  ID: Scalars['String'];
};

export type PlayerRedirectGroupBy = {
  __typename?: 'PlayerRedirectGroupBy';
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  ID: Scalars['String'];
  OverviewPage: Scalars['String'];
  _avg?: Maybe<PlayerRedirectAvgAggregate>;
  _count?: Maybe<PlayerRedirectCountAggregate>;
  _max?: Maybe<PlayerRedirectMaxAggregate>;
  _min?: Maybe<PlayerRedirectMinAggregate>;
  _sum?: Maybe<PlayerRedirectSumAggregate>;
};

export type PlayerRedirectListRelationFilter = {
  every?: InputMaybe<PlayerRedirectWhereInput>;
  none?: InputMaybe<PlayerRedirectWhereInput>;
  some?: InputMaybe<PlayerRedirectWhereInput>;
};

export type PlayerRedirectMaxAggregate = {
  __typename?: 'PlayerRedirectMaxAggregate';
  AllName?: Maybe<Scalars['String']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  OverviewPage?: Maybe<Scalars['String']>;
};

export type PlayerRedirectMaxOrderByAggregateInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
};

export type PlayerRedirectMinAggregate = {
  __typename?: 'PlayerRedirectMinAggregate';
  AllName?: Maybe<Scalars['String']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  OverviewPage?: Maybe<Scalars['String']>;
};

export type PlayerRedirectMinOrderByAggregateInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
};

export type PlayerRedirectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type PlayerRedirectOrderByWithAggregationInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<PlayerRedirectAvgOrderByAggregateInput>;
  _count?: InputMaybe<PlayerRedirectCountOrderByAggregateInput>;
  _max?: InputMaybe<PlayerRedirectMaxOrderByAggregateInput>;
  _min?: InputMaybe<PlayerRedirectMinOrderByAggregateInput>;
  _sum?: InputMaybe<PlayerRedirectSumOrderByAggregateInput>;
};

export type PlayerRedirectOrderByWithRelationInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  ID?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  player?: InputMaybe<PlayerOrderByWithRelationInput>;
};

export enum PlayerRedirectScalarFieldEnum {
  AllName = 'AllName',
  CargoId = 'Cargo_ID',
  CargoPageId = 'Cargo_pageID',
  CargoPageName = 'Cargo_pageName',
  CargoPageNamespace = 'Cargo_pageNamespace',
  CargoPageTitle = 'Cargo_pageTitle',
  Id = 'ID',
  OverviewPage = 'OverviewPage'
}

export type PlayerRedirectScalarWhereInput = {
  AND?: InputMaybe<Array<PlayerRedirectScalarWhereInput>>;
  AllName?: InputMaybe<StringFilter>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  ID?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<PlayerRedirectScalarWhereInput>>;
  OR?: InputMaybe<Array<PlayerRedirectScalarWhereInput>>;
  OverviewPage?: InputMaybe<StringFilter>;
};

export type PlayerRedirectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PlayerRedirectScalarWhereWithAggregatesInput>>;
  AllName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_ID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_pageNamespace?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageTitle?: InputMaybe<StringWithAggregatesFilter>;
  ID?: InputMaybe<StringWithAggregatesFilter>;
  NOT?: InputMaybe<Array<PlayerRedirectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<PlayerRedirectScalarWhereWithAggregatesInput>>;
  OverviewPage?: InputMaybe<StringWithAggregatesFilter>;
};

export type PlayerRedirectSumAggregate = {
  __typename?: 'PlayerRedirectSumAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
};

export type PlayerRedirectSumOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type PlayerRedirectUpdateInput = {
  AllName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneRequiredWithoutPlayerRedirectsInput>;
};

export type PlayerRedirectUpdateManyMutationInput = {
  AllName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerRedirectUpdateManyWithWhereWithoutPlayerInput = {
  data: PlayerRedirectUpdateManyMutationInput;
  where: PlayerRedirectScalarWhereInput;
};

export type PlayerRedirectUpdateManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<PlayerRedirectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlayerRedirectCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<PlayerRedirectCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<PlayerRedirectCreateManyPlayerInputEnvelope>;
  delete?: InputMaybe<Array<PlayerRedirectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlayerRedirectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlayerRedirectWhereUniqueInput>>;
  set?: InputMaybe<Array<PlayerRedirectWhereUniqueInput>>;
  update?: InputMaybe<Array<PlayerRedirectUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: InputMaybe<Array<PlayerRedirectUpdateManyWithWhereWithoutPlayerInput>>;
  upsert?: InputMaybe<Array<PlayerRedirectUpsertWithWhereUniqueWithoutPlayerInput>>;
};

export type PlayerRedirectUpdateWithWhereUniqueWithoutPlayerInput = {
  data: PlayerRedirectUpdateWithoutPlayerInput;
  where: PlayerRedirectWhereUniqueInput;
};

export type PlayerRedirectUpdateWithoutPlayerInput = {
  AllName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type PlayerRedirectUpsertWithWhereUniqueWithoutPlayerInput = {
  create: PlayerRedirectCreateWithoutPlayerInput;
  update: PlayerRedirectUpdateWithoutPlayerInput;
  where: PlayerRedirectWhereUniqueInput;
};

export type PlayerRedirectWhereInput = {
  AND?: InputMaybe<Array<PlayerRedirectWhereInput>>;
  AllName?: InputMaybe<StringFilter>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  ID?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<PlayerRedirectWhereInput>>;
  OR?: InputMaybe<Array<PlayerRedirectWhereInput>>;
  OverviewPage?: InputMaybe<StringFilter>;
  player?: InputMaybe<PlayerRelationFilter>;
};

export type PlayerRedirectWhereUniqueInput = {
  AllName?: InputMaybe<Scalars['String']>;
};

export type PlayerRelationFilter = {
  is?: InputMaybe<PlayerWhereInput>;
  isNot?: InputMaybe<PlayerWhereInput>;
};

export enum PlayerScalarFieldEnum {
  Age = 'Age',
  Askfm = 'Askfm',
  Birthdate = 'Birthdate',
  CargoId = 'Cargo_ID',
  CargoPageId = 'Cargo_pageID',
  CargoPageName = 'Cargo_pageName',
  CargoPageNamespace = 'Cargo_pageNamespace',
  CargoPageTitle = 'Cargo_pageTitle',
  Contract = 'Contract',
  Country = 'Country',
  CurrentTeams = 'CurrentTeams',
  Discord = 'Discord',
  Facebook = 'Facebook',
  FavChamps = 'FavChamps',
  Id = 'ID',
  Image = 'Image',
  Instagram = 'Instagram',
  IsAutoTeam = 'IsAutoTeam',
  IsLowContent = 'IsLowContent',
  IsLowercase = 'IsLowercase',
  IsPersonality = 'IsPersonality',
  IsRetired = 'IsRetired',
  IsSubstitute = 'IsSubstitute',
  IsTrainee = 'IsTrainee',
  Lolpros = 'Lolpros',
  Name = 'Name',
  NameAlphabet = 'NameAlphabet',
  NameFull = 'NameFull',
  Nationality = 'Nationality',
  NationalityPrimary = 'NationalityPrimary',
  NativeName = 'NativeName',
  OverviewPage = 'OverviewPage',
  Player = 'Player',
  Reddit = 'Reddit',
  Residency = 'Residency',
  ResidencyFormer = 'ResidencyFormer',
  Role = 'Role',
  RoleLast = 'RoleLast',
  Snapchat = 'Snapchat',
  SoloqueueIds = 'SoloqueueIds',
  Stream = 'Stream',
  Team = 'Team',
  Team2 = 'Team2',
  Team2System = 'Team2System',
  TeamLast = 'TeamLast',
  TeamSystem = 'TeamSystem',
  ToWildrift = 'ToWildrift',
  Twitter = 'Twitter',
  Vk = 'Vk',
  Website = 'Website',
  Weibo = 'Weibo',
  Youtube = 'Youtube'
}

export type PlayerScalarWhereInput = {
  AND?: InputMaybe<Array<PlayerScalarWhereInput>>;
  Age?: InputMaybe<IntNullableFilter>;
  Askfm?: InputMaybe<StringNullableFilter>;
  Birthdate?: InputMaybe<DateTimeNullableFilter>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  Contract?: InputMaybe<DateTimeNullableFilter>;
  Country?: InputMaybe<StringNullableFilter>;
  CurrentTeams?: InputMaybe<StringNullableListFilter>;
  Discord?: InputMaybe<StringNullableFilter>;
  Facebook?: InputMaybe<StringNullableFilter>;
  FavChamps?: InputMaybe<StringNullableListFilter>;
  ID?: InputMaybe<StringFilter>;
  Image?: InputMaybe<StringNullableFilter>;
  Instagram?: InputMaybe<StringNullableFilter>;
  IsAutoTeam?: InputMaybe<BoolFilter>;
  IsLowContent?: InputMaybe<BoolFilter>;
  IsLowercase?: InputMaybe<BoolFilter>;
  IsPersonality?: InputMaybe<BoolFilter>;
  IsRetired?: InputMaybe<BoolFilter>;
  IsSubstitute?: InputMaybe<BoolNullableFilter>;
  IsTrainee?: InputMaybe<BoolNullableFilter>;
  Lolpros?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<PlayerScalarWhereInput>>;
  Name?: InputMaybe<StringNullableFilter>;
  NameAlphabet?: InputMaybe<StringNullableFilter>;
  NameFull?: InputMaybe<StringNullableFilter>;
  Nationality?: InputMaybe<StringNullableListFilter>;
  NationalityPrimary?: InputMaybe<StringNullableFilter>;
  NativeName?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<PlayerScalarWhereInput>>;
  OverviewPage?: InputMaybe<StringFilter>;
  Player?: InputMaybe<StringFilter>;
  Reddit?: InputMaybe<StringNullableFilter>;
  Residency?: InputMaybe<StringNullableFilter>;
  ResidencyFormer?: InputMaybe<StringNullableFilter>;
  Role?: InputMaybe<StringNullableFilter>;
  RoleLast?: InputMaybe<StringNullableListFilter>;
  Snapchat?: InputMaybe<StringNullableFilter>;
  SoloqueueIds?: InputMaybe<StringNullableFilter>;
  Stream?: InputMaybe<StringNullableFilter>;
  Team?: InputMaybe<StringNullableFilter>;
  Team2?: InputMaybe<StringNullableFilter>;
  Team2System?: InputMaybe<StringFilter>;
  TeamLast?: InputMaybe<StringNullableFilter>;
  TeamSystem?: InputMaybe<StringFilter>;
  ToWildrift?: InputMaybe<BoolFilter>;
  Twitter?: InputMaybe<StringNullableFilter>;
  Vk?: InputMaybe<StringNullableFilter>;
  Website?: InputMaybe<StringNullableFilter>;
  Weibo?: InputMaybe<StringNullableFilter>;
  Youtube?: InputMaybe<StringNullableFilter>;
};

export type PlayerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<PlayerScalarWhereWithAggregatesInput>>;
  Age?: InputMaybe<IntNullableWithAggregatesFilter>;
  Askfm?: InputMaybe<StringNullableWithAggregatesFilter>;
  Birthdate?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  Cargo_ID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_pageNamespace?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageTitle?: InputMaybe<StringWithAggregatesFilter>;
  Contract?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  Country?: InputMaybe<StringNullableWithAggregatesFilter>;
  CurrentTeams?: InputMaybe<StringNullableListFilter>;
  Discord?: InputMaybe<StringNullableWithAggregatesFilter>;
  Facebook?: InputMaybe<StringNullableWithAggregatesFilter>;
  FavChamps?: InputMaybe<StringNullableListFilter>;
  ID?: InputMaybe<StringWithAggregatesFilter>;
  Image?: InputMaybe<StringNullableWithAggregatesFilter>;
  Instagram?: InputMaybe<StringNullableWithAggregatesFilter>;
  IsAutoTeam?: InputMaybe<BoolWithAggregatesFilter>;
  IsLowContent?: InputMaybe<BoolWithAggregatesFilter>;
  IsLowercase?: InputMaybe<BoolWithAggregatesFilter>;
  IsPersonality?: InputMaybe<BoolWithAggregatesFilter>;
  IsRetired?: InputMaybe<BoolWithAggregatesFilter>;
  IsSubstitute?: InputMaybe<BoolNullableWithAggregatesFilter>;
  IsTrainee?: InputMaybe<BoolNullableWithAggregatesFilter>;
  Lolpros?: InputMaybe<StringNullableWithAggregatesFilter>;
  NOT?: InputMaybe<Array<PlayerScalarWhereWithAggregatesInput>>;
  Name?: InputMaybe<StringNullableWithAggregatesFilter>;
  NameAlphabet?: InputMaybe<StringNullableWithAggregatesFilter>;
  NameFull?: InputMaybe<StringNullableWithAggregatesFilter>;
  Nationality?: InputMaybe<StringNullableListFilter>;
  NationalityPrimary?: InputMaybe<StringNullableWithAggregatesFilter>;
  NativeName?: InputMaybe<StringNullableWithAggregatesFilter>;
  OR?: InputMaybe<Array<PlayerScalarWhereWithAggregatesInput>>;
  OverviewPage?: InputMaybe<StringWithAggregatesFilter>;
  Player?: InputMaybe<StringWithAggregatesFilter>;
  Reddit?: InputMaybe<StringNullableWithAggregatesFilter>;
  Residency?: InputMaybe<StringNullableWithAggregatesFilter>;
  ResidencyFormer?: InputMaybe<StringNullableWithAggregatesFilter>;
  Role?: InputMaybe<StringNullableWithAggregatesFilter>;
  RoleLast?: InputMaybe<StringNullableListFilter>;
  Snapchat?: InputMaybe<StringNullableWithAggregatesFilter>;
  SoloqueueIds?: InputMaybe<StringNullableWithAggregatesFilter>;
  Stream?: InputMaybe<StringNullableWithAggregatesFilter>;
  Team?: InputMaybe<StringNullableWithAggregatesFilter>;
  Team2?: InputMaybe<StringNullableWithAggregatesFilter>;
  Team2System?: InputMaybe<StringWithAggregatesFilter>;
  TeamLast?: InputMaybe<StringNullableWithAggregatesFilter>;
  TeamSystem?: InputMaybe<StringWithAggregatesFilter>;
  ToWildrift?: InputMaybe<BoolWithAggregatesFilter>;
  Twitter?: InputMaybe<StringNullableWithAggregatesFilter>;
  Vk?: InputMaybe<StringNullableWithAggregatesFilter>;
  Website?: InputMaybe<StringNullableWithAggregatesFilter>;
  Weibo?: InputMaybe<StringNullableWithAggregatesFilter>;
  Youtube?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type PlayerSumAggregate = {
  __typename?: 'PlayerSumAggregate';
  Age?: Maybe<Scalars['Int']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
};

export type PlayerSumOrderByAggregateInput = {
  Age?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type PlayerUpdateCurrentTeamsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PlayerUpdateFavChampsInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PlayerUpdateInput = {
  Age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Askfm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Contract?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CurrentTeams?: InputMaybe<PlayerUpdateCurrentTeamsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  FavChamps?: InputMaybe<PlayerUpdateFavChampsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsAutoTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowContent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsPersonality?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsRetired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsSubstitute?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  IsTrainee?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Lolpros?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameAlphabet?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameFull?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Nationality?: InputMaybe<PlayerUpdateNationalityInput>;
  NationalityPrimary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  Player?: InputMaybe<StringFieldUpdateOperationsInput>;
  Reddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Residency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ResidencyFormer?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Role?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RoleLast?: InputMaybe<PlayerUpdateRoleLastInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SoloqueueIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Stream?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2System?: InputMaybe<StringFieldUpdateOperationsInput>;
  TeamLast?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamSystem?: InputMaybe<StringFieldUpdateOperationsInput>;
  ToWildrift?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Weibo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerRedirects?: InputMaybe<PlayerRedirectUpdateManyWithoutPlayerInput>;
  summoners?: InputMaybe<SummonerUpdateManyWithoutPlayerInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersInput>;
};

export type PlayerUpdateManyMutationInput = {
  Age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Askfm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Contract?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CurrentTeams?: InputMaybe<PlayerUpdateCurrentTeamsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  FavChamps?: InputMaybe<PlayerUpdateFavChampsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsAutoTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowContent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsPersonality?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsRetired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsSubstitute?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  IsTrainee?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Lolpros?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameAlphabet?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameFull?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Nationality?: InputMaybe<PlayerUpdateNationalityInput>;
  NationalityPrimary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  Player?: InputMaybe<StringFieldUpdateOperationsInput>;
  Reddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Residency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ResidencyFormer?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Role?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RoleLast?: InputMaybe<PlayerUpdateRoleLastInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SoloqueueIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Stream?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2System?: InputMaybe<StringFieldUpdateOperationsInput>;
  TeamLast?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamSystem?: InputMaybe<StringFieldUpdateOperationsInput>;
  ToWildrift?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Weibo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type PlayerUpdateManyWithWhereWithoutTeamInput = {
  data: PlayerUpdateManyMutationInput;
  where: PlayerScalarWhereInput;
};

export type PlayerUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PlayerCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<PlayerCreateWithoutTeamInput>>;
  createMany?: InputMaybe<PlayerCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<PlayerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  set?: InputMaybe<Array<PlayerWhereUniqueInput>>;
  update?: InputMaybe<Array<PlayerUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<PlayerUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<PlayerUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type PlayerUpdateNationalityInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PlayerUpdateOneRequiredWithoutPlayerRedirectsInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutPlayerRedirectsInput>;
  create?: InputMaybe<PlayerCreateWithoutPlayerRedirectsInput>;
  update?: InputMaybe<PlayerUpdateWithoutPlayerRedirectsInput>;
  upsert?: InputMaybe<PlayerUpsertWithoutPlayerRedirectsInput>;
};

export type PlayerUpdateOneWithoutSummonersInput = {
  connect?: InputMaybe<PlayerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<PlayerCreateOrConnectWithoutSummonersInput>;
  create?: InputMaybe<PlayerCreateWithoutSummonersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<PlayerUpdateWithoutSummonersInput>;
  upsert?: InputMaybe<PlayerUpsertWithoutSummonersInput>;
};

export type PlayerUpdateRoleLastInput = {
  push?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['String']>>;
};

export type PlayerUpdateWithWhereUniqueWithoutTeamInput = {
  data: PlayerUpdateWithoutTeamInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerUpdateWithoutPlayerRedirectsInput = {
  Age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Askfm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Contract?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CurrentTeams?: InputMaybe<PlayerUpdateCurrentTeamsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  FavChamps?: InputMaybe<PlayerUpdateFavChampsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsAutoTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowContent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsPersonality?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsRetired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsSubstitute?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  IsTrainee?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Lolpros?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameAlphabet?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameFull?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Nationality?: InputMaybe<PlayerUpdateNationalityInput>;
  NationalityPrimary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  Player?: InputMaybe<StringFieldUpdateOperationsInput>;
  Reddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Residency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ResidencyFormer?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Role?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RoleLast?: InputMaybe<PlayerUpdateRoleLastInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SoloqueueIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Stream?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2System?: InputMaybe<StringFieldUpdateOperationsInput>;
  TeamLast?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamSystem?: InputMaybe<StringFieldUpdateOperationsInput>;
  ToWildrift?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Weibo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  summoners?: InputMaybe<SummonerUpdateManyWithoutPlayerInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersInput>;
};

export type PlayerUpdateWithoutSummonersInput = {
  Age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Askfm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Contract?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CurrentTeams?: InputMaybe<PlayerUpdateCurrentTeamsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  FavChamps?: InputMaybe<PlayerUpdateFavChampsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsAutoTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowContent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsPersonality?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsRetired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsSubstitute?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  IsTrainee?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Lolpros?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameAlphabet?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameFull?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Nationality?: InputMaybe<PlayerUpdateNationalityInput>;
  NationalityPrimary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  Player?: InputMaybe<StringFieldUpdateOperationsInput>;
  Reddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Residency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ResidencyFormer?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Role?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RoleLast?: InputMaybe<PlayerUpdateRoleLastInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SoloqueueIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Stream?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2System?: InputMaybe<StringFieldUpdateOperationsInput>;
  TeamLast?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamSystem?: InputMaybe<StringFieldUpdateOperationsInput>;
  ToWildrift?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Weibo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerRedirects?: InputMaybe<PlayerRedirectUpdateManyWithoutPlayerInput>;
  team?: InputMaybe<TeamUpdateOneWithoutPlayersInput>;
};

export type PlayerUpdateWithoutTeamInput = {
  Age?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  Askfm?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Birthdate?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Contract?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  Country?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  CurrentTeams?: InputMaybe<PlayerUpdateCurrentTeamsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  FavChamps?: InputMaybe<PlayerUpdateFavChampsInput>;
  ID?: InputMaybe<StringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsAutoTeam?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowContent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsPersonality?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsRetired?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsSubstitute?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  IsTrainee?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Lolpros?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameAlphabet?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NameFull?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Nationality?: InputMaybe<PlayerUpdateNationalityInput>;
  NationalityPrimary?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  NativeName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  Player?: InputMaybe<StringFieldUpdateOperationsInput>;
  Reddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Residency?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  ResidencyFormer?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Role?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RoleLast?: InputMaybe<PlayerUpdateRoleLastInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  SoloqueueIds?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Stream?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Team2System?: InputMaybe<StringFieldUpdateOperationsInput>;
  TeamLast?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamSystem?: InputMaybe<StringFieldUpdateOperationsInput>;
  ToWildrift?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Weibo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  playerRedirects?: InputMaybe<PlayerRedirectUpdateManyWithoutPlayerInput>;
  summoners?: InputMaybe<SummonerUpdateManyWithoutPlayerInput>;
};

export type PlayerUpsertWithWhereUniqueWithoutTeamInput = {
  create: PlayerCreateWithoutTeamInput;
  update: PlayerUpdateWithoutTeamInput;
  where: PlayerWhereUniqueInput;
};

export type PlayerUpsertWithoutPlayerRedirectsInput = {
  create: PlayerCreateWithoutPlayerRedirectsInput;
  update: PlayerUpdateWithoutPlayerRedirectsInput;
};

export type PlayerUpsertWithoutSummonersInput = {
  create: PlayerCreateWithoutSummonersInput;
  update: PlayerUpdateWithoutSummonersInput;
};

export type PlayerWhereInput = {
  AND?: InputMaybe<Array<PlayerWhereInput>>;
  Age?: InputMaybe<IntNullableFilter>;
  Askfm?: InputMaybe<StringNullableFilter>;
  Birthdate?: InputMaybe<DateTimeNullableFilter>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  Contract?: InputMaybe<DateTimeNullableFilter>;
  Country?: InputMaybe<StringNullableFilter>;
  CurrentTeams?: InputMaybe<StringNullableListFilter>;
  Discord?: InputMaybe<StringNullableFilter>;
  Facebook?: InputMaybe<StringNullableFilter>;
  FavChamps?: InputMaybe<StringNullableListFilter>;
  ID?: InputMaybe<StringFilter>;
  Image?: InputMaybe<StringNullableFilter>;
  Instagram?: InputMaybe<StringNullableFilter>;
  IsAutoTeam?: InputMaybe<BoolFilter>;
  IsLowContent?: InputMaybe<BoolFilter>;
  IsLowercase?: InputMaybe<BoolFilter>;
  IsPersonality?: InputMaybe<BoolFilter>;
  IsRetired?: InputMaybe<BoolFilter>;
  IsSubstitute?: InputMaybe<BoolNullableFilter>;
  IsTrainee?: InputMaybe<BoolNullableFilter>;
  Lolpros?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<PlayerWhereInput>>;
  Name?: InputMaybe<StringNullableFilter>;
  NameAlphabet?: InputMaybe<StringNullableFilter>;
  NameFull?: InputMaybe<StringNullableFilter>;
  Nationality?: InputMaybe<StringNullableListFilter>;
  NationalityPrimary?: InputMaybe<StringNullableFilter>;
  NativeName?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<PlayerWhereInput>>;
  OverviewPage?: InputMaybe<StringFilter>;
  Player?: InputMaybe<StringFilter>;
  Reddit?: InputMaybe<StringNullableFilter>;
  Residency?: InputMaybe<StringNullableFilter>;
  ResidencyFormer?: InputMaybe<StringNullableFilter>;
  Role?: InputMaybe<StringNullableFilter>;
  RoleLast?: InputMaybe<StringNullableListFilter>;
  Snapchat?: InputMaybe<StringNullableFilter>;
  SoloqueueIds?: InputMaybe<StringNullableFilter>;
  Stream?: InputMaybe<StringNullableFilter>;
  Team?: InputMaybe<StringNullableFilter>;
  Team2?: InputMaybe<StringNullableFilter>;
  Team2System?: InputMaybe<StringFilter>;
  TeamLast?: InputMaybe<StringNullableFilter>;
  TeamSystem?: InputMaybe<StringFilter>;
  ToWildrift?: InputMaybe<BoolFilter>;
  Twitter?: InputMaybe<StringNullableFilter>;
  Vk?: InputMaybe<StringNullableFilter>;
  Website?: InputMaybe<StringNullableFilter>;
  Weibo?: InputMaybe<StringNullableFilter>;
  Youtube?: InputMaybe<StringNullableFilter>;
  playerRedirects?: InputMaybe<PlayerRedirectListRelationFilter>;
  summoners?: InputMaybe<SummonerListRelationFilter>;
  team?: InputMaybe<TeamRelationFilter>;
};

export type PlayerWhereUniqueInput = {
  Cargo_pageID?: InputMaybe<Scalars['Int']>;
  Cargo_pageName?: InputMaybe<Scalars['String']>;
  OverviewPage?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accounts: Array<Account>;
  aggregateAccount: AggregateAccount;
  aggregateLeague: AggregateLeague;
  aggregateLeagueEntry: AggregateLeagueEntry;
  aggregateMatch: AggregateMatch;
  aggregateMatchParticipant: AggregateMatchParticipant;
  aggregateMatchTeam: AggregateMatchTeam;
  aggregatePlayer: AggregatePlayer;
  aggregatePlayerRedirect: AggregatePlayerRedirect;
  aggregateRegion: AggregateRegion;
  aggregateSession: AggregateSession;
  aggregateSummoner: AggregateSummoner;
  aggregateTeam: AggregateTeam;
  aggregateTeamRedirect: AggregateTeamRedirect;
  aggregateUser: AggregateUser;
  aggregateVerificationToken: AggregateVerificationToken;
  findFirstAccount?: Maybe<Account>;
  findFirstLeague?: Maybe<League>;
  findFirstLeagueEntry?: Maybe<LeagueEntry>;
  findFirstMatch?: Maybe<Match>;
  findFirstMatchParticipant?: Maybe<MatchParticipant>;
  findFirstMatchTeam?: Maybe<MatchTeam>;
  findFirstPlayer?: Maybe<Player>;
  findFirstPlayerRedirect?: Maybe<PlayerRedirect>;
  findFirstRegion?: Maybe<Region>;
  findFirstSession?: Maybe<Session>;
  findFirstSummoner?: Maybe<Summoner>;
  findFirstTeam?: Maybe<Team>;
  findFirstTeamRedirect?: Maybe<TeamRedirect>;
  findFirstUser?: Maybe<User>;
  findFirstVerificationToken?: Maybe<VerificationToken>;
  groupByAccount: Array<AccountGroupBy>;
  groupByLeague: Array<LeagueGroupBy>;
  groupByLeagueEntry: Array<LeagueEntryGroupBy>;
  groupByMatch: Array<MatchGroupBy>;
  groupByMatchParticipant: Array<MatchParticipantGroupBy>;
  groupByMatchTeam: Array<MatchTeamGroupBy>;
  groupByPlayer: Array<PlayerGroupBy>;
  groupByPlayerRedirect: Array<PlayerRedirectGroupBy>;
  groupByRegion: Array<RegionGroupBy>;
  groupBySession: Array<SessionGroupBy>;
  groupBySummoner: Array<SummonerGroupBy>;
  groupByTeam: Array<TeamGroupBy>;
  groupByTeamRedirect: Array<TeamRedirectGroupBy>;
  groupByUser: Array<UserGroupBy>;
  groupByVerificationToken: Array<VerificationTokenGroupBy>;
  league?: Maybe<League>;
  leagueEntries: Array<LeagueEntry>;
  leagueEntry?: Maybe<LeagueEntry>;
  leagues: Array<League>;
  match?: Maybe<Match>;
  matchParticipant?: Maybe<MatchParticipant>;
  matchParticipants: Array<MatchParticipant>;
  matchTeam?: Maybe<MatchTeam>;
  matchTeams: Array<MatchTeam>;
  matches: Array<Match>;
  player?: Maybe<Player>;
  playerRedirect?: Maybe<PlayerRedirect>;
  playerRedirects: Array<PlayerRedirect>;
  players: Array<Player>;
  region?: Maybe<Region>;
  regions: Array<Region>;
  searchPlayers: Array<Player>;
  searchTeams: Array<Team>;
  session?: Maybe<Session>;
  sessions: Array<Session>;
  summoner?: Maybe<Summoner>;
  summoners: Array<Summoner>;
  team?: Maybe<Team>;
  teamRedirect?: Maybe<TeamRedirect>;
  teamRedirects: Array<TeamRedirect>;
  teams: Array<Team>;
  user?: Maybe<User>;
  users: Array<User>;
  verificationToken?: Maybe<VerificationToken>;
  verificationTokens: Array<VerificationToken>;
};


export type QueryAccountArgs = {
  where: AccountWhereUniqueInput;
};


export type QueryAccountsArgs = {
  cursor?: InputMaybe<AccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type QueryAggregateAccountArgs = {
  cursor?: InputMaybe<AccountWhereUniqueInput>;
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type QueryAggregateLeagueArgs = {
  cursor?: InputMaybe<LeagueWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LeagueOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueWhereInput>;
};


export type QueryAggregateLeagueEntryArgs = {
  cursor?: InputMaybe<LeagueEntryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<LeagueEntryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueEntryWhereInput>;
};


export type QueryAggregateMatchArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchWhereInput>;
};


export type QueryAggregateMatchParticipantArgs = {
  cursor?: InputMaybe<MatchParticipantWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type QueryAggregateMatchTeamArgs = {
  cursor?: InputMaybe<MatchTeamWhereUniqueInput>;
  orderBy?: InputMaybe<Array<MatchTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchTeamWhereInput>;
};


export type QueryAggregatePlayerArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerWhereInput>;
};


export type QueryAggregatePlayerRedirectArgs = {
  cursor?: InputMaybe<PlayerRedirectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<PlayerRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type QueryAggregateRegionArgs = {
  cursor?: InputMaybe<RegionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<RegionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionWhereInput>;
};


export type QueryAggregateSessionArgs = {
  cursor?: InputMaybe<SessionWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryAggregateSummonerArgs = {
  cursor?: InputMaybe<SummonerWhereUniqueInput>;
  orderBy?: InputMaybe<Array<SummonerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SummonerWhereInput>;
};


export type QueryAggregateTeamArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamWhereInput>;
};


export type QueryAggregateTeamRedirectArgs = {
  cursor?: InputMaybe<TeamRedirectWhereUniqueInput>;
  orderBy?: InputMaybe<Array<TeamRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamRedirectWhereInput>;
};


export type QueryAggregateUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryAggregateVerificationTokenArgs = {
  cursor?: InputMaybe<VerificationTokenWhereUniqueInput>;
  orderBy?: InputMaybe<Array<VerificationTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationTokenWhereInput>;
};


export type QueryFindFirstAccountArgs = {
  cursor?: InputMaybe<AccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type QueryFindFirstLeagueArgs = {
  cursor?: InputMaybe<LeagueWhereUniqueInput>;
  distinct?: InputMaybe<Array<LeagueScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LeagueOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueWhereInput>;
};


export type QueryFindFirstLeagueEntryArgs = {
  cursor?: InputMaybe<LeagueEntryWhereUniqueInput>;
  distinct?: InputMaybe<Array<LeagueEntryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LeagueEntryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueEntryWhereInput>;
};


export type QueryFindFirstMatchArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchWhereInput>;
};


export type QueryFindFirstMatchParticipantArgs = {
  cursor?: InputMaybe<MatchParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type QueryFindFirstMatchTeamArgs = {
  cursor?: InputMaybe<MatchTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchTeamWhereInput>;
};


export type QueryFindFirstPlayerArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerWhereInput>;
};


export type QueryFindFirstPlayerRedirectArgs = {
  cursor?: InputMaybe<PlayerRedirectWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerRedirectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type QueryFindFirstRegionArgs = {
  cursor?: InputMaybe<RegionWhereUniqueInput>;
  distinct?: InputMaybe<Array<RegionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RegionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionWhereInput>;
};


export type QueryFindFirstSessionArgs = {
  cursor?: InputMaybe<SessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<SessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryFindFirstSummonerArgs = {
  cursor?: InputMaybe<SummonerWhereUniqueInput>;
  distinct?: InputMaybe<Array<SummonerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SummonerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SummonerWhereInput>;
};


export type QueryFindFirstTeamArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamWhereInput>;
};


export type QueryFindFirstTeamRedirectArgs = {
  cursor?: InputMaybe<TeamRedirectWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamRedirectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamRedirectWhereInput>;
};


export type QueryFindFirstUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryFindFirstVerificationTokenArgs = {
  cursor?: InputMaybe<VerificationTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<VerificationTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VerificationTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationTokenWhereInput>;
};


export type QueryGroupByAccountArgs = {
  by: Array<AccountScalarFieldEnum>;
  having?: InputMaybe<AccountScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<AccountOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type QueryGroupByLeagueArgs = {
  by: Array<LeagueScalarFieldEnum>;
  having?: InputMaybe<LeagueScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LeagueOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueWhereInput>;
};


export type QueryGroupByLeagueEntryArgs = {
  by: Array<LeagueEntryScalarFieldEnum>;
  having?: InputMaybe<LeagueEntryScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<LeagueEntryOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueEntryWhereInput>;
};


export type QueryGroupByMatchArgs = {
  by: Array<MatchScalarFieldEnum>;
  having?: InputMaybe<MatchScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MatchOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchWhereInput>;
};


export type QueryGroupByMatchParticipantArgs = {
  by: Array<MatchParticipantScalarFieldEnum>;
  having?: InputMaybe<MatchParticipantScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type QueryGroupByMatchTeamArgs = {
  by: Array<MatchTeamScalarFieldEnum>;
  having?: InputMaybe<MatchTeamScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<MatchTeamOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchTeamWhereInput>;
};


export type QueryGroupByPlayerArgs = {
  by: Array<PlayerScalarFieldEnum>;
  having?: InputMaybe<PlayerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerWhereInput>;
};


export type QueryGroupByPlayerRedirectArgs = {
  by: Array<PlayerRedirectScalarFieldEnum>;
  having?: InputMaybe<PlayerRedirectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<PlayerRedirectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type QueryGroupByRegionArgs = {
  by: Array<RegionScalarFieldEnum>;
  having?: InputMaybe<RegionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<RegionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionWhereInput>;
};


export type QueryGroupBySessionArgs = {
  by: Array<SessionScalarFieldEnum>;
  having?: InputMaybe<SessionScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SessionOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QueryGroupBySummonerArgs = {
  by: Array<SummonerScalarFieldEnum>;
  having?: InputMaybe<SummonerScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<SummonerOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SummonerWhereInput>;
};


export type QueryGroupByTeamArgs = {
  by: Array<TeamScalarFieldEnum>;
  having?: InputMaybe<TeamScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TeamOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamWhereInput>;
};


export type QueryGroupByTeamRedirectArgs = {
  by: Array<TeamRedirectScalarFieldEnum>;
  having?: InputMaybe<TeamRedirectScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<TeamRedirectOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamRedirectWhereInput>;
};


export type QueryGroupByUserArgs = {
  by: Array<UserScalarFieldEnum>;
  having?: InputMaybe<UserScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<UserOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryGroupByVerificationTokenArgs = {
  by: Array<VerificationTokenScalarFieldEnum>;
  having?: InputMaybe<VerificationTokenScalarWhereWithAggregatesInput>;
  orderBy?: InputMaybe<Array<VerificationTokenOrderByWithAggregationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationTokenWhereInput>;
};


export type QueryLeagueArgs = {
  where: LeagueWhereUniqueInput;
};


export type QueryLeagueEntriesArgs = {
  cursor?: InputMaybe<LeagueEntryWhereUniqueInput>;
  distinct?: InputMaybe<Array<LeagueEntryScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LeagueEntryOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueEntryWhereInput>;
};


export type QueryLeagueEntryArgs = {
  where: LeagueEntryWhereUniqueInput;
};


export type QueryLeaguesArgs = {
  cursor?: InputMaybe<LeagueWhereUniqueInput>;
  distinct?: InputMaybe<Array<LeagueScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LeagueOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueWhereInput>;
};


export type QueryMatchArgs = {
  where: MatchWhereUniqueInput;
};


export type QueryMatchParticipantArgs = {
  where: MatchParticipantWhereUniqueInput;
};


export type QueryMatchParticipantsArgs = {
  cursor?: InputMaybe<MatchParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};


export type QueryMatchTeamArgs = {
  where: MatchTeamWhereUniqueInput;
};


export type QueryMatchTeamsArgs = {
  cursor?: InputMaybe<MatchTeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchTeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchTeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchTeamWhereInput>;
};


export type QueryMatchesArgs = {
  cursor?: InputMaybe<MatchWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchWhereInput>;
};


export type QueryPlayerArgs = {
  where: PlayerWhereUniqueInput;
};


export type QueryPlayerRedirectArgs = {
  where: PlayerRedirectWhereUniqueInput;
};


export type QueryPlayerRedirectsArgs = {
  cursor?: InputMaybe<PlayerRedirectWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerRedirectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerRedirectWhereInput>;
};


export type QueryPlayersArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerWhereInput>;
};


export type QueryRegionArgs = {
  where: RegionWhereUniqueInput;
};


export type QueryRegionsArgs = {
  cursor?: InputMaybe<RegionWhereUniqueInput>;
  distinct?: InputMaybe<Array<RegionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<RegionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RegionWhereInput>;
};


export type QuerySearchPlayersArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySearchTeamsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySessionArgs = {
  where: SessionWhereUniqueInput;
};


export type QuerySessionsArgs = {
  cursor?: InputMaybe<SessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<SessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};


export type QuerySummonerArgs = {
  where: SummonerWhereUniqueInput;
};


export type QuerySummonersArgs = {
  cursor?: InputMaybe<SummonerWhereUniqueInput>;
  distinct?: InputMaybe<Array<SummonerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SummonerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SummonerWhereInput>;
};


export type QueryTeamArgs = {
  where: TeamWhereUniqueInput;
};


export type QueryTeamRedirectArgs = {
  where: TeamRedirectWhereUniqueInput;
};


export type QueryTeamRedirectsArgs = {
  cursor?: InputMaybe<TeamRedirectWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamRedirectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamRedirectWhereInput>;
};


export type QueryTeamsArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamWhereInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryVerificationTokenArgs = {
  where: VerificationTokenWhereUniqueInput;
};


export type QueryVerificationTokensArgs = {
  cursor?: InputMaybe<VerificationTokenWhereUniqueInput>;
  distinct?: InputMaybe<Array<VerificationTokenScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<VerificationTokenOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<VerificationTokenWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum Queue {
  RankedFlexSr = 'RANKED_FLEX_SR',
  RankedFlexTt = 'RANKED_FLEX_TT',
  RankedSolo_5x5 = 'RANKED_SOLO_5x5',
  RankedTft = 'RANKED_TFT',
  RankedTftPairs = 'RANKED_TFT_PAIRS'
}

export type Region = {
  __typename?: 'Region';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsCurrent: Scalars['Boolean'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['String'];
  /** TODO: delete this */
  RegionMedium: Scalars['String'];
  _count?: Maybe<RegionCount>;
  leagues: Array<League>;
  teams: Array<Team>;
};


export type RegionLeaguesArgs = {
  cursor?: InputMaybe<LeagueWhereUniqueInput>;
  distinct?: InputMaybe<Array<LeagueScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<LeagueOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<LeagueWhereInput>;
};


export type RegionTeamsArgs = {
  cursor?: InputMaybe<TeamWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamWhereInput>;
};

export type RegionAvgAggregate = {
  __typename?: 'RegionAvgAggregate';
  Cargo_ID?: Maybe<Scalars['Float']>;
  Cargo_pageID?: Maybe<Scalars['Float']>;
  Cargo_pageNamespace?: Maybe<Scalars['Float']>;
  Priority?: Maybe<Scalars['Float']>;
};

export type RegionAvgOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
};

export type RegionCount = {
  __typename?: 'RegionCount';
  leagues: Scalars['Int'];
  teams: Scalars['Int'];
};

export type RegionCountAggregate = {
  __typename?: 'RegionCountAggregate';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['Int'];
  IsCurrent: Scalars['Int'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['Int'];
  RegionMedium: Scalars['Int'];
  _all: Scalars['Int'];
};

export type RegionCountOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsCurrent?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
  RegionLong?: InputMaybe<SortOrder>;
  RegionMedium?: InputMaybe<SortOrder>;
};

export type RegionCreateInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsCurrent: Scalars['Boolean'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['String'];
  RegionMedium: Scalars['String'];
  leagues?: InputMaybe<LeagueCreateNestedManyWithoutRegionInput>;
  teams?: InputMaybe<TeamCreateNestedManyWithoutRegionInput>;
};

export type RegionCreateManyInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsCurrent: Scalars['Boolean'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['String'];
  RegionMedium: Scalars['String'];
};

export type RegionCreateNestedOneWithoutLeaguesInput = {
  connect?: InputMaybe<RegionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RegionCreateOrConnectWithoutLeaguesInput>;
  create?: InputMaybe<RegionCreateWithoutLeaguesInput>;
};

export type RegionCreateNestedOneWithoutTeamsInput = {
  connect?: InputMaybe<RegionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RegionCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<RegionCreateWithoutTeamsInput>;
};

export type RegionCreateOrConnectWithoutLeaguesInput = {
  create: RegionCreateWithoutLeaguesInput;
  where: RegionWhereUniqueInput;
};

export type RegionCreateOrConnectWithoutTeamsInput = {
  create: RegionCreateWithoutTeamsInput;
  where: RegionWhereUniqueInput;
};

export type RegionCreateWithoutLeaguesInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsCurrent: Scalars['Boolean'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['String'];
  RegionMedium: Scalars['String'];
  teams?: InputMaybe<TeamCreateNestedManyWithoutRegionInput>;
};

export type RegionCreateWithoutTeamsInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsCurrent: Scalars['Boolean'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['String'];
  RegionMedium: Scalars['String'];
  leagues?: InputMaybe<LeagueCreateNestedManyWithoutRegionInput>;
};

export type RegionGroupBy = {
  __typename?: 'RegionGroupBy';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  IsCurrent: Scalars['Boolean'];
  Priority: Scalars['Int'];
  RegionLong: Scalars['String'];
  RegionMedium: Scalars['String'];
  _avg?: Maybe<RegionAvgAggregate>;
  _count?: Maybe<RegionCountAggregate>;
  _max?: Maybe<RegionMaxAggregate>;
  _min?: Maybe<RegionMinAggregate>;
  _sum?: Maybe<RegionSumAggregate>;
};

export type RegionMaxAggregate = {
  __typename?: 'RegionMaxAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  IsCurrent?: Maybe<Scalars['Boolean']>;
  Priority?: Maybe<Scalars['Int']>;
  RegionLong?: Maybe<Scalars['String']>;
  RegionMedium?: Maybe<Scalars['String']>;
};

export type RegionMaxOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsCurrent?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
  RegionLong?: InputMaybe<SortOrder>;
  RegionMedium?: InputMaybe<SortOrder>;
};

export type RegionMinAggregate = {
  __typename?: 'RegionMinAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  IsCurrent?: Maybe<Scalars['Boolean']>;
  Priority?: Maybe<Scalars['Int']>;
  RegionLong?: Maybe<Scalars['String']>;
  RegionMedium?: Maybe<Scalars['String']>;
};

export type RegionMinOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsCurrent?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
  RegionLong?: InputMaybe<SortOrder>;
  RegionMedium?: InputMaybe<SortOrder>;
};

export type RegionOrderByWithAggregationInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsCurrent?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
  RegionLong?: InputMaybe<SortOrder>;
  RegionMedium?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<RegionAvgOrderByAggregateInput>;
  _count?: InputMaybe<RegionCountOrderByAggregateInput>;
  _max?: InputMaybe<RegionMaxOrderByAggregateInput>;
  _min?: InputMaybe<RegionMinOrderByAggregateInput>;
  _sum?: InputMaybe<RegionSumOrderByAggregateInput>;
};

export type RegionOrderByWithRelationInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  IsCurrent?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
  RegionLong?: InputMaybe<SortOrder>;
  RegionMedium?: InputMaybe<SortOrder>;
  leagues?: InputMaybe<LeagueOrderByRelationAggregateInput>;
  teams?: InputMaybe<TeamOrderByRelationAggregateInput>;
};

export type RegionRelationFilter = {
  is?: InputMaybe<RegionWhereInput>;
  isNot?: InputMaybe<RegionWhereInput>;
};

export enum RegionScalarFieldEnum {
  CargoId = 'Cargo_ID',
  CargoPageId = 'Cargo_pageID',
  CargoPageName = 'Cargo_pageName',
  CargoPageNamespace = 'Cargo_pageNamespace',
  CargoPageTitle = 'Cargo_pageTitle',
  IsCurrent = 'IsCurrent',
  Priority = 'Priority',
  RegionLong = 'RegionLong',
  RegionMedium = 'RegionMedium'
}

export type RegionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<RegionScalarWhereWithAggregatesInput>>;
  Cargo_ID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_pageNamespace?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageTitle?: InputMaybe<StringWithAggregatesFilter>;
  IsCurrent?: InputMaybe<BoolWithAggregatesFilter>;
  NOT?: InputMaybe<Array<RegionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<RegionScalarWhereWithAggregatesInput>>;
  Priority?: InputMaybe<IntWithAggregatesFilter>;
  RegionLong?: InputMaybe<StringWithAggregatesFilter>;
  RegionMedium?: InputMaybe<StringWithAggregatesFilter>;
};

export type RegionSumAggregate = {
  __typename?: 'RegionSumAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Priority?: Maybe<Scalars['Int']>;
};

export type RegionSumOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Priority?: InputMaybe<SortOrder>;
};

export type RegionUpdateInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsCurrent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  RegionLong?: InputMaybe<StringFieldUpdateOperationsInput>;
  RegionMedium?: InputMaybe<StringFieldUpdateOperationsInput>;
  leagues?: InputMaybe<LeagueUpdateManyWithoutRegionInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutRegionInput>;
};

export type RegionUpdateManyMutationInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsCurrent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  RegionLong?: InputMaybe<StringFieldUpdateOperationsInput>;
  RegionMedium?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type RegionUpdateOneRequiredWithoutLeaguesInput = {
  connect?: InputMaybe<RegionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RegionCreateOrConnectWithoutLeaguesInput>;
  create?: InputMaybe<RegionCreateWithoutLeaguesInput>;
  update?: InputMaybe<RegionUpdateWithoutLeaguesInput>;
  upsert?: InputMaybe<RegionUpsertWithoutLeaguesInput>;
};

export type RegionUpdateOneRequiredWithoutTeamsInput = {
  connect?: InputMaybe<RegionWhereUniqueInput>;
  connectOrCreate?: InputMaybe<RegionCreateOrConnectWithoutTeamsInput>;
  create?: InputMaybe<RegionCreateWithoutTeamsInput>;
  update?: InputMaybe<RegionUpdateWithoutTeamsInput>;
  upsert?: InputMaybe<RegionUpsertWithoutTeamsInput>;
};

export type RegionUpdateWithoutLeaguesInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsCurrent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  RegionLong?: InputMaybe<StringFieldUpdateOperationsInput>;
  RegionMedium?: InputMaybe<StringFieldUpdateOperationsInput>;
  teams?: InputMaybe<TeamUpdateManyWithoutRegionInput>;
};

export type RegionUpdateWithoutTeamsInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  IsCurrent?: InputMaybe<BoolFieldUpdateOperationsInput>;
  Priority?: InputMaybe<IntFieldUpdateOperationsInput>;
  RegionLong?: InputMaybe<StringFieldUpdateOperationsInput>;
  RegionMedium?: InputMaybe<StringFieldUpdateOperationsInput>;
  leagues?: InputMaybe<LeagueUpdateManyWithoutRegionInput>;
};

export type RegionUpsertWithoutLeaguesInput = {
  create: RegionCreateWithoutLeaguesInput;
  update: RegionUpdateWithoutLeaguesInput;
};

export type RegionUpsertWithoutTeamsInput = {
  create: RegionCreateWithoutTeamsInput;
  update: RegionUpdateWithoutTeamsInput;
};

export type RegionWhereInput = {
  AND?: InputMaybe<Array<RegionWhereInput>>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  IsCurrent?: InputMaybe<BoolFilter>;
  NOT?: InputMaybe<Array<RegionWhereInput>>;
  OR?: InputMaybe<Array<RegionWhereInput>>;
  Priority?: InputMaybe<IntFilter>;
  RegionLong?: InputMaybe<StringFilter>;
  RegionMedium?: InputMaybe<StringFilter>;
  leagues?: InputMaybe<LeagueListRelationFilter>;
  teams?: InputMaybe<TeamListRelationFilter>;
};

export type RegionWhereUniqueInput = {
  RegionLong?: InputMaybe<Scalars['String']>;
};

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['DateTime'];
  expires: Scalars['DateTime'];
  id: Scalars['String'];
  sessionToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type SessionCountAggregate = {
  __typename?: 'SessionCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  expires: Scalars['Int'];
  id: Scalars['Int'];
  sessionToken: Scalars['Int'];
  updatedAt: Scalars['Int'];
  userId: Scalars['Int'];
};

export type SessionCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sessionToken?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SessionCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  sessionToken: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutSessionsInput;
};

export type SessionCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  sessionToken: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  userId: Scalars['String'];
};

export type SessionCreateManyUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  sessionToken: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SessionCreateManyUserInputEnvelope = {
  data: Array<SessionCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SessionCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutUserInput>>;
  createMany?: InputMaybe<SessionCreateManyUserInputEnvelope>;
};

export type SessionCreateOrConnectWithoutUserInput = {
  create: SessionCreateWithoutUserInput;
  where: SessionWhereUniqueInput;
};

export type SessionCreateWithoutUserInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires: Scalars['DateTime'];
  id?: InputMaybe<Scalars['String']>;
  sessionToken: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SessionGroupBy = {
  __typename?: 'SessionGroupBy';
  _count?: Maybe<SessionCountAggregate>;
  _max?: Maybe<SessionMaxAggregate>;
  _min?: Maybe<SessionMinAggregate>;
  createdAt: Scalars['DateTime'];
  expires: Scalars['DateTime'];
  id: Scalars['String'];
  sessionToken: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['String'];
};

export type SessionListRelationFilter = {
  every?: InputMaybe<SessionWhereInput>;
  none?: InputMaybe<SessionWhereInput>;
  some?: InputMaybe<SessionWhereInput>;
};

export type SessionMaxAggregate = {
  __typename?: 'SessionMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sessionToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SessionMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sessionToken?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SessionMinAggregate = {
  __typename?: 'SessionMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  sessionToken?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type SessionMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sessionToken?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SessionOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SessionOrderByWithAggregationInput = {
  _count?: InputMaybe<SessionCountOrderByAggregateInput>;
  _max?: InputMaybe<SessionMaxOrderByAggregateInput>;
  _min?: InputMaybe<SessionMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sessionToken?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type SessionOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  sessionToken?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  user?: InputMaybe<UserOrderByWithRelationInput>;
  userId?: InputMaybe<SortOrder>;
};

export enum SessionScalarFieldEnum {
  CreatedAt = 'createdAt',
  Expires = 'expires',
  Id = 'id',
  SessionToken = 'sessionToken',
  UpdatedAt = 'updatedAt',
  UserId = 'userId'
}

export type SessionScalarWhereInput = {
  AND?: InputMaybe<Array<SessionScalarWhereInput>>;
  NOT?: InputMaybe<Array<SessionScalarWhereInput>>;
  OR?: InputMaybe<Array<SessionScalarWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expires?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionToken?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SessionScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SessionScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SessionScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SessionScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  expires?: InputMaybe<DateTimeWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  sessionToken?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  userId?: InputMaybe<StringWithAggregatesFilter>;
};

export type SessionUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sessionToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  user?: InputMaybe<UserUpdateOneRequiredWithoutSessionsInput>;
};

export type SessionUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sessionToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SessionUpdateManyWithWhereWithoutUserInput = {
  data: SessionUpdateManyMutationInput;
  where: SessionScalarWhereInput;
};

export type SessionUpdateManyWithoutUserInput = {
  connect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SessionCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<SessionCreateWithoutUserInput>>;
  createMany?: InputMaybe<SessionCreateManyUserInputEnvelope>;
  delete?: InputMaybe<Array<SessionWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SessionScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SessionWhereUniqueInput>>;
  set?: InputMaybe<Array<SessionWhereUniqueInput>>;
  update?: InputMaybe<Array<SessionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: InputMaybe<Array<SessionUpdateManyWithWhereWithoutUserInput>>;
  upsert?: InputMaybe<Array<SessionUpsertWithWhereUniqueWithoutUserInput>>;
};

export type SessionUpdateWithWhereUniqueWithoutUserInput = {
  data: SessionUpdateWithoutUserInput;
  where: SessionWhereUniqueInput;
};

export type SessionUpdateWithoutUserInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  sessionToken?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type SessionUpsertWithWhereUniqueWithoutUserInput = {
  create: SessionCreateWithoutUserInput;
  update: SessionUpdateWithoutUserInput;
  where: SessionWhereUniqueInput;
};

export type SessionWhereInput = {
  AND?: InputMaybe<Array<SessionWhereInput>>;
  NOT?: InputMaybe<Array<SessionWhereInput>>;
  OR?: InputMaybe<Array<SessionWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expires?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  sessionToken?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type SessionWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
  sessionToken?: InputMaybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']>>;
  has?: InputMaybe<Scalars['String']>;
  hasEvery?: InputMaybe<Array<Scalars['String']>>;
  hasSome?: InputMaybe<Array<Scalars['String']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Summoner = {
  __typename?: 'Summoner';
  _count?: Maybe<SummonerCount>;
  /** Encrypted account ID. Max length 56 characters. */
  accountId: Scalars['String'];
  /** from riot account */
  gameName: Scalars['String'];
  /** Encrypted summoner ID. Max length 63 characters. */
  id: Scalars['String'];
  leagueEntry?: Maybe<LeagueEntry>;
  /** Summoner name. */
  name: Scalars['String'];
  participants: Array<MatchParticipant>;
  platform: Platform;
  player?: Maybe<Player>;
  playerOverviewPage?: Maybe<Scalars['String']>;
  /** ID of the summoner icon associated with the summoner. */
  profileIconId: Scalars['Int'];
  /** Encrypted PUUID. Exact length of 78 characters. */
  puuid: Scalars['String'];
  recent?: Maybe<Scalars['DateTime']>;
  /** Date summoner was last modified specified as epoch milliseconds. The following events will update this timestamp: summoner name change, summoner level change, or profile icon change. */
  revisionDate: Scalars['BigInt'];
  /** Summoner level associated with the summoner. */
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser: Scalars['Boolean'];
};


export type SummonerParticipantsArgs = {
  cursor?: InputMaybe<MatchParticipantWhereUniqueInput>;
  distinct?: InputMaybe<Array<MatchParticipantScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<MatchParticipantOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<MatchParticipantWhereInput>;
};

export type SummonerAccountIdPlatformCompoundUniqueInput = {
  accountId: Scalars['String'];
  platform: Platform;
};

export type SummonerAvgAggregate = {
  __typename?: 'SummonerAvgAggregate';
  profileIconId?: Maybe<Scalars['Float']>;
  revisionDate?: Maybe<Scalars['Float']>;
  summonerLevel?: Maybe<Scalars['Float']>;
};

export type SummonerAvgOrderByAggregateInput = {
  profileIconId?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
};

export type SummonerCount = {
  __typename?: 'SummonerCount';
  participants: Scalars['Int'];
};

export type SummonerCountAggregate = {
  __typename?: 'SummonerCountAggregate';
  _all: Scalars['Int'];
  accountId: Scalars['Int'];
  gameName: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
  platform: Scalars['Int'];
  playerOverviewPage: Scalars['Int'];
  profileIconId: Scalars['Int'];
  puuid: Scalars['Int'];
  revisionDate: Scalars['Int'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['Int'];
  trackedByUser: Scalars['Int'];
};

export type SummonerCountOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  playerOverviewPage?: InputMaybe<SortOrder>;
  profileIconId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
  tagLine?: InputMaybe<SortOrder>;
  trackedByUser?: InputMaybe<SortOrder>;
};

export type SummonerCreateInput = {
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  leagueEntry?: InputMaybe<LeagueEntryCreateNestedOneWithoutSummonerInput>;
  name: Scalars['String'];
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutSummonerInput>;
  platform: Platform;
  player?: InputMaybe<PlayerCreateNestedOneWithoutSummonersInput>;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerCreateManyInput = {
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  platform: Platform;
  playerOverviewPage?: InputMaybe<Scalars['String']>;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerCreateManyPlayerInput = {
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  platform: Platform;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerCreateManyPlayerInputEnvelope = {
  data: Array<SummonerCreateManyPlayerInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerCreateNestedManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<SummonerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SummonerCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<SummonerCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<SummonerCreateManyPlayerInputEnvelope>;
};

export type SummonerCreateNestedOneWithoutLeagueEntryInput = {
  connect?: InputMaybe<SummonerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SummonerCreateOrConnectWithoutLeagueEntryInput>;
  create?: InputMaybe<SummonerCreateWithoutLeagueEntryInput>;
};

export type SummonerCreateNestedOneWithoutParticipantsInput = {
  connect?: InputMaybe<SummonerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SummonerCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<SummonerCreateWithoutParticipantsInput>;
};

export type SummonerCreateOrConnectWithoutLeagueEntryInput = {
  create: SummonerCreateWithoutLeagueEntryInput;
  where: SummonerWhereUniqueInput;
};

export type SummonerCreateOrConnectWithoutParticipantsInput = {
  create: SummonerCreateWithoutParticipantsInput;
  where: SummonerWhereUniqueInput;
};

export type SummonerCreateOrConnectWithoutPlayerInput = {
  create: SummonerCreateWithoutPlayerInput;
  where: SummonerWhereUniqueInput;
};

export type SummonerCreateWithoutLeagueEntryInput = {
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutSummonerInput>;
  platform: Platform;
  player?: InputMaybe<PlayerCreateNestedOneWithoutSummonersInput>;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerCreateWithoutParticipantsInput = {
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  leagueEntry?: InputMaybe<LeagueEntryCreateNestedOneWithoutSummonerInput>;
  name: Scalars['String'];
  platform: Platform;
  player?: InputMaybe<PlayerCreateNestedOneWithoutSummonersInput>;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerCreateWithoutPlayerInput = {
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  leagueEntry?: InputMaybe<LeagueEntryCreateNestedOneWithoutSummonerInput>;
  name: Scalars['String'];
  participants?: InputMaybe<MatchParticipantCreateNestedManyWithoutSummonerInput>;
  platform: Platform;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser?: InputMaybe<Scalars['Boolean']>;
};

export type SummonerGameNameTagLineCompoundUniqueInput = {
  gameName: Scalars['String'];
  tagLine: Scalars['String'];
};

export type SummonerGroupBy = {
  __typename?: 'SummonerGroupBy';
  _avg?: Maybe<SummonerAvgAggregate>;
  _count?: Maybe<SummonerCountAggregate>;
  _max?: Maybe<SummonerMaxAggregate>;
  _min?: Maybe<SummonerMinAggregate>;
  _sum?: Maybe<SummonerSumAggregate>;
  accountId: Scalars['String'];
  gameName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  platform: Platform;
  playerOverviewPage?: Maybe<Scalars['String']>;
  profileIconId: Scalars['Int'];
  puuid: Scalars['String'];
  revisionDate: Scalars['BigInt'];
  summonerLevel: Scalars['Int'];
  tagLine: Scalars['String'];
  trackedByUser: Scalars['Boolean'];
};

export type SummonerIdPlatformCompoundUniqueInput = {
  id: Scalars['String'];
  platform: Platform;
};

export type SummonerListRelationFilter = {
  every?: InputMaybe<SummonerWhereInput>;
  none?: InputMaybe<SummonerWhereInput>;
  some?: InputMaybe<SummonerWhereInput>;
};

export type SummonerMaxAggregate = {
  __typename?: 'SummonerMaxAggregate';
  accountId?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  playerOverviewPage?: Maybe<Scalars['String']>;
  profileIconId?: Maybe<Scalars['Int']>;
  puuid?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['BigInt']>;
  summonerLevel?: Maybe<Scalars['Int']>;
  tagLine?: Maybe<Scalars['String']>;
  trackedByUser?: Maybe<Scalars['Boolean']>;
};

export type SummonerMaxOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  playerOverviewPage?: InputMaybe<SortOrder>;
  profileIconId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
  tagLine?: InputMaybe<SortOrder>;
  trackedByUser?: InputMaybe<SortOrder>;
};

export type SummonerMinAggregate = {
  __typename?: 'SummonerMinAggregate';
  accountId?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  platform?: Maybe<Platform>;
  playerOverviewPage?: Maybe<Scalars['String']>;
  profileIconId?: Maybe<Scalars['Int']>;
  puuid?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['BigInt']>;
  summonerLevel?: Maybe<Scalars['Int']>;
  tagLine?: Maybe<Scalars['String']>;
  trackedByUser?: Maybe<Scalars['Boolean']>;
};

export type SummonerMinOrderByAggregateInput = {
  accountId?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  playerOverviewPage?: InputMaybe<SortOrder>;
  profileIconId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
  tagLine?: InputMaybe<SortOrder>;
  trackedByUser?: InputMaybe<SortOrder>;
};

export type SummonerOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type SummonerOrderByWithAggregationInput = {
  _avg?: InputMaybe<SummonerAvgOrderByAggregateInput>;
  _count?: InputMaybe<SummonerCountOrderByAggregateInput>;
  _max?: InputMaybe<SummonerMaxOrderByAggregateInput>;
  _min?: InputMaybe<SummonerMinOrderByAggregateInput>;
  _sum?: InputMaybe<SummonerSumOrderByAggregateInput>;
  accountId?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  platform?: InputMaybe<SortOrder>;
  playerOverviewPage?: InputMaybe<SortOrder>;
  profileIconId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
  tagLine?: InputMaybe<SortOrder>;
  trackedByUser?: InputMaybe<SortOrder>;
};

export type SummonerOrderByWithRelationInput = {
  accountId?: InputMaybe<SortOrder>;
  gameName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  leagueEntry?: InputMaybe<LeagueEntryOrderByWithRelationInput>;
  name?: InputMaybe<SortOrder>;
  participants?: InputMaybe<MatchParticipantOrderByRelationAggregateInput>;
  platform?: InputMaybe<SortOrder>;
  player?: InputMaybe<PlayerOrderByWithRelationInput>;
  playerOverviewPage?: InputMaybe<SortOrder>;
  profileIconId?: InputMaybe<SortOrder>;
  puuid?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
  tagLine?: InputMaybe<SortOrder>;
  trackedByUser?: InputMaybe<SortOrder>;
};

export type SummonerRelationFilter = {
  is?: InputMaybe<SummonerWhereInput>;
  isNot?: InputMaybe<SummonerWhereInput>;
};

export enum SummonerScalarFieldEnum {
  AccountId = 'accountId',
  GameName = 'gameName',
  Id = 'id',
  Name = 'name',
  Platform = 'platform',
  PlayerOverviewPage = 'playerOverviewPage',
  ProfileIconId = 'profileIconId',
  Puuid = 'puuid',
  RevisionDate = 'revisionDate',
  SummonerLevel = 'summonerLevel',
  TagLine = 'tagLine',
  TrackedByUser = 'trackedByUser'
}

export type SummonerScalarWhereInput = {
  AND?: InputMaybe<Array<SummonerScalarWhereInput>>;
  NOT?: InputMaybe<Array<SummonerScalarWhereInput>>;
  OR?: InputMaybe<Array<SummonerScalarWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  gameName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  platform?: InputMaybe<EnumPlatformFilter>;
  playerOverviewPage?: InputMaybe<StringNullableFilter>;
  profileIconId?: InputMaybe<IntFilter>;
  puuid?: InputMaybe<StringFilter>;
  revisionDate?: InputMaybe<BigIntFilter>;
  summonerLevel?: InputMaybe<IntFilter>;
  tagLine?: InputMaybe<StringFilter>;
  trackedByUser?: InputMaybe<BoolFilter>;
};

export type SummonerScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<SummonerScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<SummonerScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<SummonerScalarWhereWithAggregatesInput>>;
  accountId?: InputMaybe<StringWithAggregatesFilter>;
  gameName?: InputMaybe<StringWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
  platform?: InputMaybe<EnumPlatformWithAggregatesFilter>;
  playerOverviewPage?: InputMaybe<StringNullableWithAggregatesFilter>;
  profileIconId?: InputMaybe<IntWithAggregatesFilter>;
  puuid?: InputMaybe<StringWithAggregatesFilter>;
  revisionDate?: InputMaybe<BigIntWithAggregatesFilter>;
  summonerLevel?: InputMaybe<IntWithAggregatesFilter>;
  tagLine?: InputMaybe<StringWithAggregatesFilter>;
  trackedByUser?: InputMaybe<BoolWithAggregatesFilter>;
};

export type SummonerSumAggregate = {
  __typename?: 'SummonerSumAggregate';
  profileIconId?: Maybe<Scalars['Int']>;
  revisionDate?: Maybe<Scalars['BigInt']>;
  summonerLevel?: Maybe<Scalars['Int']>;
};

export type SummonerSumOrderByAggregateInput = {
  profileIconId?: InputMaybe<SortOrder>;
  revisionDate?: InputMaybe<SortOrder>;
  summonerLevel?: InputMaybe<SortOrder>;
};

export type SummonerUpdateInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  leagueEntry?: InputMaybe<LeagueEntryUpdateOneWithoutSummonerInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutSummonerInput>;
  platform?: InputMaybe<EnumPlatformFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneWithoutSummonersInput>;
  profileIconId?: InputMaybe<IntFieldUpdateOperationsInput>;
  puuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisionDate?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  summonerLevel?: InputMaybe<IntFieldUpdateOperationsInput>;
  tagLine?: InputMaybe<StringFieldUpdateOperationsInput>;
  trackedByUser?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SummonerUpdateManyMutationInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<EnumPlatformFieldUpdateOperationsInput>;
  profileIconId?: InputMaybe<IntFieldUpdateOperationsInput>;
  puuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisionDate?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  summonerLevel?: InputMaybe<IntFieldUpdateOperationsInput>;
  tagLine?: InputMaybe<StringFieldUpdateOperationsInput>;
  trackedByUser?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SummonerUpdateManyWithWhereWithoutPlayerInput = {
  data: SummonerUpdateManyMutationInput;
  where: SummonerScalarWhereInput;
};

export type SummonerUpdateManyWithoutPlayerInput = {
  connect?: InputMaybe<Array<SummonerWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<SummonerCreateOrConnectWithoutPlayerInput>>;
  create?: InputMaybe<Array<SummonerCreateWithoutPlayerInput>>;
  createMany?: InputMaybe<SummonerCreateManyPlayerInputEnvelope>;
  delete?: InputMaybe<Array<SummonerWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<SummonerScalarWhereInput>>;
  disconnect?: InputMaybe<Array<SummonerWhereUniqueInput>>;
  set?: InputMaybe<Array<SummonerWhereUniqueInput>>;
  update?: InputMaybe<Array<SummonerUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: InputMaybe<Array<SummonerUpdateManyWithWhereWithoutPlayerInput>>;
  upsert?: InputMaybe<Array<SummonerUpsertWithWhereUniqueWithoutPlayerInput>>;
};

export type SummonerUpdateOneRequiredWithoutLeagueEntryInput = {
  connect?: InputMaybe<SummonerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SummonerCreateOrConnectWithoutLeagueEntryInput>;
  create?: InputMaybe<SummonerCreateWithoutLeagueEntryInput>;
  update?: InputMaybe<SummonerUpdateWithoutLeagueEntryInput>;
  upsert?: InputMaybe<SummonerUpsertWithoutLeagueEntryInput>;
};

export type SummonerUpdateOneRequiredWithoutParticipantsInput = {
  connect?: InputMaybe<SummonerWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SummonerCreateOrConnectWithoutParticipantsInput>;
  create?: InputMaybe<SummonerCreateWithoutParticipantsInput>;
  update?: InputMaybe<SummonerUpdateWithoutParticipantsInput>;
  upsert?: InputMaybe<SummonerUpsertWithoutParticipantsInput>;
};

export type SummonerUpdateWithWhereUniqueWithoutPlayerInput = {
  data: SummonerUpdateWithoutPlayerInput;
  where: SummonerWhereUniqueInput;
};

export type SummonerUpdateWithoutLeagueEntryInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutSummonerInput>;
  platform?: InputMaybe<EnumPlatformFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneWithoutSummonersInput>;
  profileIconId?: InputMaybe<IntFieldUpdateOperationsInput>;
  puuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisionDate?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  summonerLevel?: InputMaybe<IntFieldUpdateOperationsInput>;
  tagLine?: InputMaybe<StringFieldUpdateOperationsInput>;
  trackedByUser?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SummonerUpdateWithoutParticipantsInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  leagueEntry?: InputMaybe<LeagueEntryUpdateOneWithoutSummonerInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  platform?: InputMaybe<EnumPlatformFieldUpdateOperationsInput>;
  player?: InputMaybe<PlayerUpdateOneWithoutSummonersInput>;
  profileIconId?: InputMaybe<IntFieldUpdateOperationsInput>;
  puuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisionDate?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  summonerLevel?: InputMaybe<IntFieldUpdateOperationsInput>;
  tagLine?: InputMaybe<StringFieldUpdateOperationsInput>;
  trackedByUser?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SummonerUpdateWithoutPlayerInput = {
  accountId?: InputMaybe<StringFieldUpdateOperationsInput>;
  gameName?: InputMaybe<StringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  leagueEntry?: InputMaybe<LeagueEntryUpdateOneWithoutSummonerInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
  participants?: InputMaybe<MatchParticipantUpdateManyWithoutSummonerInput>;
  platform?: InputMaybe<EnumPlatformFieldUpdateOperationsInput>;
  profileIconId?: InputMaybe<IntFieldUpdateOperationsInput>;
  puuid?: InputMaybe<StringFieldUpdateOperationsInput>;
  revisionDate?: InputMaybe<BigIntFieldUpdateOperationsInput>;
  summonerLevel?: InputMaybe<IntFieldUpdateOperationsInput>;
  tagLine?: InputMaybe<StringFieldUpdateOperationsInput>;
  trackedByUser?: InputMaybe<BoolFieldUpdateOperationsInput>;
};

export type SummonerUpsertWithWhereUniqueWithoutPlayerInput = {
  create: SummonerCreateWithoutPlayerInput;
  update: SummonerUpdateWithoutPlayerInput;
  where: SummonerWhereUniqueInput;
};

export type SummonerUpsertWithoutLeagueEntryInput = {
  create: SummonerCreateWithoutLeagueEntryInput;
  update: SummonerUpdateWithoutLeagueEntryInput;
};

export type SummonerUpsertWithoutParticipantsInput = {
  create: SummonerCreateWithoutParticipantsInput;
  update: SummonerUpdateWithoutParticipantsInput;
};

export type SummonerWhereInput = {
  AND?: InputMaybe<Array<SummonerWhereInput>>;
  NOT?: InputMaybe<Array<SummonerWhereInput>>;
  OR?: InputMaybe<Array<SummonerWhereInput>>;
  accountId?: InputMaybe<StringFilter>;
  gameName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  leagueEntry?: InputMaybe<LeagueEntryRelationFilter>;
  name?: InputMaybe<StringFilter>;
  participants?: InputMaybe<MatchParticipantListRelationFilter>;
  platform?: InputMaybe<EnumPlatformFilter>;
  player?: InputMaybe<PlayerRelationFilter>;
  playerOverviewPage?: InputMaybe<StringNullableFilter>;
  profileIconId?: InputMaybe<IntFilter>;
  puuid?: InputMaybe<StringFilter>;
  revisionDate?: InputMaybe<BigIntFilter>;
  summonerLevel?: InputMaybe<IntFilter>;
  tagLine?: InputMaybe<StringFilter>;
  trackedByUser?: InputMaybe<BoolFilter>;
};

export type SummonerWhereUniqueInput = {
  accountId_platform?: InputMaybe<SummonerAccountIdPlatformCompoundUniqueInput>;
  gameName_tagLine?: InputMaybe<SummonerGameNameTagLineCompoundUniqueInput>;
  id_platform?: InputMaybe<SummonerIdPlatformCompoundUniqueInput>;
  puuid?: InputMaybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  /** Organizations have a potential for relatively annoying disambiguations. Let's just call them by their page name here. Let's also try to avoid needing a redirects table. */
  OrganizationPage?: Maybe<Scalars['String']>;
  /** Use this instead of _pageName/Title */
  OverviewPage: Scalars['String'];
  Region: Scalars['String'];
  /** Name of the team renamed to or null */
  RenamedTo?: Maybe<Scalars['String']>;
  RosterPhoto?: Maybe<Scalars['String']>;
  /** The short name of the team. This should agree with Riot always (except for like Academy teams), if not please report it to us!!! */
  Short: Scalars['String'];
  Snapchat?: Maybe<Scalars['String']>;
  Subreddit?: Maybe<Scalars['String']>;
  TeamLocation?: Maybe<Scalars['String']>;
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  /** For PMT */
  Website?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
  _count?: Maybe<TeamCount>;
  players: Array<Player>;
  region: Region;
  teamRedirects: Array<TeamRedirect>;
};


export type TeamPlayersArgs = {
  cursor?: InputMaybe<PlayerWhereUniqueInput>;
  distinct?: InputMaybe<Array<PlayerScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PlayerOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<PlayerWhereInput>;
};


export type TeamTeamRedirectsArgs = {
  cursor?: InputMaybe<TeamRedirectWhereUniqueInput>;
  distinct?: InputMaybe<Array<TeamRedirectScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<TeamRedirectOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<TeamRedirectWhereInput>;
};

export type TeamAvgAggregate = {
  __typename?: 'TeamAvgAggregate';
  Cargo_ID?: Maybe<Scalars['Float']>;
  Cargo_pageID?: Maybe<Scalars['Float']>;
  Cargo_pageNamespace?: Maybe<Scalars['Float']>;
};

export type TeamAvgOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type TeamCount = {
  __typename?: 'TeamCount';
  players: Scalars['Int'];
  teamRedirects: Scalars['Int'];
};

export type TeamCountAggregate = {
  __typename?: 'TeamCountAggregate';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['Int'];
  Discord: Scalars['Int'];
  Facebook: Scalars['Int'];
  Image: Scalars['Int'];
  Instagram: Scalars['Int'];
  IsDisbanded: Scalars['Int'];
  IsLowercase: Scalars['Int'];
  Location: Scalars['Int'];
  Name: Scalars['Int'];
  OrganizationPage: Scalars['Int'];
  OverviewPage: Scalars['Int'];
  Region: Scalars['Int'];
  RenamedTo: Scalars['Int'];
  RosterPhoto: Scalars['Int'];
  Short: Scalars['Int'];
  Snapchat: Scalars['Int'];
  Subreddit: Scalars['Int'];
  TeamLocation: Scalars['Int'];
  Twitter: Scalars['Int'];
  Vk: Scalars['Int'];
  Website: Scalars['Int'];
  Youtube: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TeamCountOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsDisbanded?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  Location?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  OrganizationPage?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  RenamedTo?: InputMaybe<SortOrder>;
  RosterPhoto?: InputMaybe<SortOrder>;
  Short?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  Subreddit?: InputMaybe<SortOrder>;
  TeamLocation?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
};

export type TeamCreateInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  OrganizationPage?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  RenamedTo?: InputMaybe<Scalars['String']>;
  RosterPhoto?: InputMaybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: InputMaybe<Scalars['String']>;
  Subreddit?: InputMaybe<Scalars['String']>;
  TeamLocation?: InputMaybe<Scalars['String']>;
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  region: RegionCreateNestedOneWithoutTeamsInput;
  teamRedirects?: InputMaybe<TeamRedirectCreateNestedManyWithoutTeamInput>;
};

export type TeamCreateManyInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  OrganizationPage?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Region: Scalars['String'];
  RenamedTo?: InputMaybe<Scalars['String']>;
  RosterPhoto?: InputMaybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: InputMaybe<Scalars['String']>;
  Subreddit?: InputMaybe<Scalars['String']>;
  TeamLocation?: InputMaybe<Scalars['String']>;
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
};

export type TeamCreateManyRegionInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  OrganizationPage?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  RenamedTo?: InputMaybe<Scalars['String']>;
  RosterPhoto?: InputMaybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: InputMaybe<Scalars['String']>;
  Subreddit?: InputMaybe<Scalars['String']>;
  TeamLocation?: InputMaybe<Scalars['String']>;
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
};

export type TeamCreateManyRegionInputEnvelope = {
  data: Array<TeamCreateManyRegionInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TeamCreateNestedManyWithoutRegionInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutRegionInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutRegionInput>>;
  createMany?: InputMaybe<TeamCreateManyRegionInputEnvelope>;
};

export type TeamCreateNestedOneWithoutPlayersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutPlayersInput>;
  create?: InputMaybe<TeamCreateWithoutPlayersInput>;
};

export type TeamCreateNestedOneWithoutTeamRedirectsInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTeamRedirectsInput>;
  create?: InputMaybe<TeamCreateWithoutTeamRedirectsInput>;
};

export type TeamCreateOrConnectWithoutPlayersInput = {
  create: TeamCreateWithoutPlayersInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutRegionInput = {
  create: TeamCreateWithoutRegionInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateOrConnectWithoutTeamRedirectsInput = {
  create: TeamCreateWithoutTeamRedirectsInput;
  where: TeamWhereUniqueInput;
};

export type TeamCreateWithoutPlayersInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  OrganizationPage?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  RenamedTo?: InputMaybe<Scalars['String']>;
  RosterPhoto?: InputMaybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: InputMaybe<Scalars['String']>;
  Subreddit?: InputMaybe<Scalars['String']>;
  TeamLocation?: InputMaybe<Scalars['String']>;
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  region: RegionCreateNestedOneWithoutTeamsInput;
  teamRedirects?: InputMaybe<TeamRedirectCreateNestedManyWithoutTeamInput>;
};

export type TeamCreateWithoutRegionInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  OrganizationPage?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  RenamedTo?: InputMaybe<Scalars['String']>;
  RosterPhoto?: InputMaybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: InputMaybe<Scalars['String']>;
  Subreddit?: InputMaybe<Scalars['String']>;
  TeamLocation?: InputMaybe<Scalars['String']>;
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  teamRedirects?: InputMaybe<TeamRedirectCreateNestedManyWithoutTeamInput>;
};

export type TeamCreateWithoutTeamRedirectsInput = {
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: InputMaybe<Scalars['String']>;
  Facebook?: InputMaybe<Scalars['String']>;
  Image?: InputMaybe<Scalars['String']>;
  Instagram?: InputMaybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: InputMaybe<Scalars['Boolean']>;
  Location?: InputMaybe<Scalars['String']>;
  Name?: InputMaybe<Scalars['String']>;
  OrganizationPage?: InputMaybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  RenamedTo?: InputMaybe<Scalars['String']>;
  RosterPhoto?: InputMaybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: InputMaybe<Scalars['String']>;
  Subreddit?: InputMaybe<Scalars['String']>;
  TeamLocation?: InputMaybe<Scalars['String']>;
  Twitter?: InputMaybe<Scalars['String']>;
  Vk?: InputMaybe<Scalars['String']>;
  Website?: InputMaybe<Scalars['String']>;
  Youtube?: InputMaybe<Scalars['String']>;
  players?: InputMaybe<PlayerCreateNestedManyWithoutTeamInput>;
  region: RegionCreateNestedOneWithoutTeamsInput;
};

export type TeamGroupBy = {
  __typename?: 'TeamGroupBy';
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsDisbanded: Scalars['Boolean'];
  IsLowercase?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OrganizationPage?: Maybe<Scalars['String']>;
  OverviewPage: Scalars['String'];
  Region: Scalars['String'];
  RenamedTo?: Maybe<Scalars['String']>;
  RosterPhoto?: Maybe<Scalars['String']>;
  Short: Scalars['String'];
  Snapchat?: Maybe<Scalars['String']>;
  Subreddit?: Maybe<Scalars['String']>;
  TeamLocation?: Maybe<Scalars['String']>;
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
  _avg?: Maybe<TeamAvgAggregate>;
  _count?: Maybe<TeamCountAggregate>;
  _max?: Maybe<TeamMaxAggregate>;
  _min?: Maybe<TeamMinAggregate>;
  _sum?: Maybe<TeamSumAggregate>;
};

export type TeamListRelationFilter = {
  every?: InputMaybe<TeamWhereInput>;
  none?: InputMaybe<TeamWhereInput>;
  some?: InputMaybe<TeamWhereInput>;
};

export type TeamMaxAggregate = {
  __typename?: 'TeamMaxAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsDisbanded?: Maybe<Scalars['Boolean']>;
  IsLowercase?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OrganizationPage?: Maybe<Scalars['String']>;
  OverviewPage?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  RenamedTo?: Maybe<Scalars['String']>;
  RosterPhoto?: Maybe<Scalars['String']>;
  Short?: Maybe<Scalars['String']>;
  Snapchat?: Maybe<Scalars['String']>;
  Subreddit?: Maybe<Scalars['String']>;
  TeamLocation?: Maybe<Scalars['String']>;
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
};

export type TeamMaxOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsDisbanded?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  Location?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  OrganizationPage?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  RenamedTo?: InputMaybe<SortOrder>;
  RosterPhoto?: InputMaybe<SortOrder>;
  Short?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  Subreddit?: InputMaybe<SortOrder>;
  TeamLocation?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
};

export type TeamMinAggregate = {
  __typename?: 'TeamMinAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  Discord?: Maybe<Scalars['String']>;
  Facebook?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  Instagram?: Maybe<Scalars['String']>;
  IsDisbanded?: Maybe<Scalars['Boolean']>;
  IsLowercase?: Maybe<Scalars['Boolean']>;
  Location?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  OrganizationPage?: Maybe<Scalars['String']>;
  OverviewPage?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  RenamedTo?: Maybe<Scalars['String']>;
  RosterPhoto?: Maybe<Scalars['String']>;
  Short?: Maybe<Scalars['String']>;
  Snapchat?: Maybe<Scalars['String']>;
  Subreddit?: Maybe<Scalars['String']>;
  TeamLocation?: Maybe<Scalars['String']>;
  Twitter?: Maybe<Scalars['String']>;
  Vk?: Maybe<Scalars['String']>;
  Website?: Maybe<Scalars['String']>;
  Youtube?: Maybe<Scalars['String']>;
};

export type TeamMinOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsDisbanded?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  Location?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  OrganizationPage?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  RenamedTo?: InputMaybe<SortOrder>;
  RosterPhoto?: InputMaybe<SortOrder>;
  Short?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  Subreddit?: InputMaybe<SortOrder>;
  TeamLocation?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
};

export type TeamOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TeamOrderByWithAggregationInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsDisbanded?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  Location?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  OrganizationPage?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  RenamedTo?: InputMaybe<SortOrder>;
  RosterPhoto?: InputMaybe<SortOrder>;
  Short?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  Subreddit?: InputMaybe<SortOrder>;
  TeamLocation?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<TeamAvgOrderByAggregateInput>;
  _count?: InputMaybe<TeamCountOrderByAggregateInput>;
  _max?: InputMaybe<TeamMaxOrderByAggregateInput>;
  _min?: InputMaybe<TeamMinOrderByAggregateInput>;
  _sum?: InputMaybe<TeamSumOrderByAggregateInput>;
};

export type TeamOrderByWithRelationInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  Discord?: InputMaybe<SortOrder>;
  Facebook?: InputMaybe<SortOrder>;
  Image?: InputMaybe<SortOrder>;
  Instagram?: InputMaybe<SortOrder>;
  IsDisbanded?: InputMaybe<SortOrder>;
  IsLowercase?: InputMaybe<SortOrder>;
  Location?: InputMaybe<SortOrder>;
  Name?: InputMaybe<SortOrder>;
  OrganizationPage?: InputMaybe<SortOrder>;
  OverviewPage?: InputMaybe<SortOrder>;
  Region?: InputMaybe<SortOrder>;
  RenamedTo?: InputMaybe<SortOrder>;
  RosterPhoto?: InputMaybe<SortOrder>;
  Short?: InputMaybe<SortOrder>;
  Snapchat?: InputMaybe<SortOrder>;
  Subreddit?: InputMaybe<SortOrder>;
  TeamLocation?: InputMaybe<SortOrder>;
  Twitter?: InputMaybe<SortOrder>;
  Vk?: InputMaybe<SortOrder>;
  Website?: InputMaybe<SortOrder>;
  Youtube?: InputMaybe<SortOrder>;
  players?: InputMaybe<PlayerOrderByRelationAggregateInput>;
  region?: InputMaybe<RegionOrderByWithRelationInput>;
  teamRedirects?: InputMaybe<TeamRedirectOrderByRelationAggregateInput>;
};

export type TeamRedirect = {
  __typename?: 'TeamRedirect';
  /** Case & special character insensitive list of redirects to team page, including the page itself */
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  /** Not in use. Null for the page the content belongs to; holds a value of the redirect for each redirect */
  OtherName?: Maybe<Scalars['String']>;
  /** Not in use. A unique field for each line, if we wanted case-sensitive field values, which we don't */
  UniqueLine?: Maybe<Scalars['String']>;
  team: Team;
};

export type TeamRedirectAvgAggregate = {
  __typename?: 'TeamRedirectAvgAggregate';
  Cargo_ID?: Maybe<Scalars['Float']>;
  Cargo_pageID?: Maybe<Scalars['Float']>;
  Cargo_pageNamespace?: Maybe<Scalars['Float']>;
};

export type TeamRedirectAvgOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type TeamRedirectCountAggregate = {
  __typename?: 'TeamRedirectCountAggregate';
  AllName: Scalars['Int'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['Int'];
  OtherName: Scalars['Int'];
  UniqueLine: Scalars['Int'];
  _all: Scalars['Int'];
};

export type TeamRedirectCountOrderByAggregateInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  OtherName?: InputMaybe<SortOrder>;
  UniqueLine?: InputMaybe<SortOrder>;
};

export type TeamRedirectCreateInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  OtherName?: InputMaybe<Scalars['String']>;
  UniqueLine?: InputMaybe<Scalars['String']>;
  team: TeamCreateNestedOneWithoutTeamRedirectsInput;
};

export type TeamRedirectCreateManyInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  OtherName?: InputMaybe<Scalars['String']>;
  UniqueLine?: InputMaybe<Scalars['String']>;
};

export type TeamRedirectCreateManyTeamInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  OtherName?: InputMaybe<Scalars['String']>;
  UniqueLine?: InputMaybe<Scalars['String']>;
};

export type TeamRedirectCreateManyTeamInputEnvelope = {
  data: Array<TeamRedirectCreateManyTeamInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type TeamRedirectCreateNestedManyWithoutTeamInput = {
  connect?: InputMaybe<Array<TeamRedirectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamRedirectCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<TeamRedirectCreateWithoutTeamInput>>;
  createMany?: InputMaybe<TeamRedirectCreateManyTeamInputEnvelope>;
};

export type TeamRedirectCreateOrConnectWithoutTeamInput = {
  create: TeamRedirectCreateWithoutTeamInput;
  where: TeamRedirectWhereUniqueInput;
};

export type TeamRedirectCreateWithoutTeamInput = {
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  OtherName?: InputMaybe<Scalars['String']>;
  UniqueLine?: InputMaybe<Scalars['String']>;
};

export type TeamRedirectGroupBy = {
  __typename?: 'TeamRedirectGroupBy';
  AllName: Scalars['String'];
  Cargo_ID: Scalars['Int'];
  Cargo_pageID: Scalars['Int'];
  Cargo_pageName: Scalars['String'];
  Cargo_pageNamespace: Scalars['Int'];
  Cargo_pageTitle: Scalars['String'];
  OtherName?: Maybe<Scalars['String']>;
  UniqueLine?: Maybe<Scalars['String']>;
  _avg?: Maybe<TeamRedirectAvgAggregate>;
  _count?: Maybe<TeamRedirectCountAggregate>;
  _max?: Maybe<TeamRedirectMaxAggregate>;
  _min?: Maybe<TeamRedirectMinAggregate>;
  _sum?: Maybe<TeamRedirectSumAggregate>;
};

export type TeamRedirectListRelationFilter = {
  every?: InputMaybe<TeamRedirectWhereInput>;
  none?: InputMaybe<TeamRedirectWhereInput>;
  some?: InputMaybe<TeamRedirectWhereInput>;
};

export type TeamRedirectMaxAggregate = {
  __typename?: 'TeamRedirectMaxAggregate';
  AllName?: Maybe<Scalars['String']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  OtherName?: Maybe<Scalars['String']>;
  UniqueLine?: Maybe<Scalars['String']>;
};

export type TeamRedirectMaxOrderByAggregateInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  OtherName?: InputMaybe<SortOrder>;
  UniqueLine?: InputMaybe<SortOrder>;
};

export type TeamRedirectMinAggregate = {
  __typename?: 'TeamRedirectMinAggregate';
  AllName?: Maybe<Scalars['String']>;
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageName?: Maybe<Scalars['String']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
  Cargo_pageTitle?: Maybe<Scalars['String']>;
  OtherName?: Maybe<Scalars['String']>;
  UniqueLine?: Maybe<Scalars['String']>;
};

export type TeamRedirectMinOrderByAggregateInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  OtherName?: InputMaybe<SortOrder>;
  UniqueLine?: InputMaybe<SortOrder>;
};

export type TeamRedirectOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type TeamRedirectOrderByWithAggregationInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  OtherName?: InputMaybe<SortOrder>;
  UniqueLine?: InputMaybe<SortOrder>;
  _avg?: InputMaybe<TeamRedirectAvgOrderByAggregateInput>;
  _count?: InputMaybe<TeamRedirectCountOrderByAggregateInput>;
  _max?: InputMaybe<TeamRedirectMaxOrderByAggregateInput>;
  _min?: InputMaybe<TeamRedirectMinOrderByAggregateInput>;
  _sum?: InputMaybe<TeamRedirectSumOrderByAggregateInput>;
};

export type TeamRedirectOrderByWithRelationInput = {
  AllName?: InputMaybe<SortOrder>;
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageName?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
  Cargo_pageTitle?: InputMaybe<SortOrder>;
  OtherName?: InputMaybe<SortOrder>;
  UniqueLine?: InputMaybe<SortOrder>;
  team?: InputMaybe<TeamOrderByWithRelationInput>;
};

export enum TeamRedirectScalarFieldEnum {
  AllName = 'AllName',
  CargoId = 'Cargo_ID',
  CargoPageId = 'Cargo_pageID',
  CargoPageName = 'Cargo_pageName',
  CargoPageNamespace = 'Cargo_pageNamespace',
  CargoPageTitle = 'Cargo_pageTitle',
  OtherName = 'OtherName',
  UniqueLine = 'UniqueLine'
}

export type TeamRedirectScalarWhereInput = {
  AND?: InputMaybe<Array<TeamRedirectScalarWhereInput>>;
  AllName?: InputMaybe<StringFilter>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TeamRedirectScalarWhereInput>>;
  OR?: InputMaybe<Array<TeamRedirectScalarWhereInput>>;
  OtherName?: InputMaybe<StringNullableFilter>;
  UniqueLine?: InputMaybe<StringNullableFilter>;
};

export type TeamRedirectScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TeamRedirectScalarWhereWithAggregatesInput>>;
  AllName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_ID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_pageNamespace?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageTitle?: InputMaybe<StringWithAggregatesFilter>;
  NOT?: InputMaybe<Array<TeamRedirectScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<TeamRedirectScalarWhereWithAggregatesInput>>;
  OtherName?: InputMaybe<StringNullableWithAggregatesFilter>;
  UniqueLine?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type TeamRedirectSumAggregate = {
  __typename?: 'TeamRedirectSumAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
};

export type TeamRedirectSumOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type TeamRedirectUpdateInput = {
  AllName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  OtherName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  UniqueLine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  team?: InputMaybe<TeamUpdateOneRequiredWithoutTeamRedirectsInput>;
};

export type TeamRedirectUpdateManyMutationInput = {
  AllName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  OtherName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  UniqueLine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TeamRedirectUpdateManyWithWhereWithoutTeamInput = {
  data: TeamRedirectUpdateManyMutationInput;
  where: TeamRedirectScalarWhereInput;
};

export type TeamRedirectUpdateManyWithoutTeamInput = {
  connect?: InputMaybe<Array<TeamRedirectWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamRedirectCreateOrConnectWithoutTeamInput>>;
  create?: InputMaybe<Array<TeamRedirectCreateWithoutTeamInput>>;
  createMany?: InputMaybe<TeamRedirectCreateManyTeamInputEnvelope>;
  delete?: InputMaybe<Array<TeamRedirectWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamRedirectScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamRedirectWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamRedirectWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamRedirectUpdateWithWhereUniqueWithoutTeamInput>>;
  updateMany?: InputMaybe<Array<TeamRedirectUpdateManyWithWhereWithoutTeamInput>>;
  upsert?: InputMaybe<Array<TeamRedirectUpsertWithWhereUniqueWithoutTeamInput>>;
};

export type TeamRedirectUpdateWithWhereUniqueWithoutTeamInput = {
  data: TeamRedirectUpdateWithoutTeamInput;
  where: TeamRedirectWhereUniqueInput;
};

export type TeamRedirectUpdateWithoutTeamInput = {
  AllName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  OtherName?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  UniqueLine?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TeamRedirectUpsertWithWhereUniqueWithoutTeamInput = {
  create: TeamRedirectCreateWithoutTeamInput;
  update: TeamRedirectUpdateWithoutTeamInput;
  where: TeamRedirectWhereUniqueInput;
};

export type TeamRedirectWhereInput = {
  AND?: InputMaybe<Array<TeamRedirectWhereInput>>;
  AllName?: InputMaybe<StringFilter>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  NOT?: InputMaybe<Array<TeamRedirectWhereInput>>;
  OR?: InputMaybe<Array<TeamRedirectWhereInput>>;
  OtherName?: InputMaybe<StringNullableFilter>;
  UniqueLine?: InputMaybe<StringNullableFilter>;
  team?: InputMaybe<TeamRelationFilter>;
};

export type TeamRedirectWhereUniqueInput = {
  AllName?: InputMaybe<Scalars['String']>;
};

export type TeamRelationFilter = {
  is?: InputMaybe<TeamWhereInput>;
  isNot?: InputMaybe<TeamWhereInput>;
};

export enum TeamScalarFieldEnum {
  CargoId = 'Cargo_ID',
  CargoPageId = 'Cargo_pageID',
  CargoPageName = 'Cargo_pageName',
  CargoPageNamespace = 'Cargo_pageNamespace',
  CargoPageTitle = 'Cargo_pageTitle',
  Discord = 'Discord',
  Facebook = 'Facebook',
  Image = 'Image',
  Instagram = 'Instagram',
  IsDisbanded = 'IsDisbanded',
  IsLowercase = 'IsLowercase',
  Location = 'Location',
  Name = 'Name',
  OrganizationPage = 'OrganizationPage',
  OverviewPage = 'OverviewPage',
  Region = 'Region',
  RenamedTo = 'RenamedTo',
  RosterPhoto = 'RosterPhoto',
  Short = 'Short',
  Snapchat = 'Snapchat',
  Subreddit = 'Subreddit',
  TeamLocation = 'TeamLocation',
  Twitter = 'Twitter',
  Vk = 'Vk',
  Website = 'Website',
  Youtube = 'Youtube'
}

export type TeamScalarWhereInput = {
  AND?: InputMaybe<Array<TeamScalarWhereInput>>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  Discord?: InputMaybe<StringNullableFilter>;
  Facebook?: InputMaybe<StringNullableFilter>;
  Image?: InputMaybe<StringNullableFilter>;
  Instagram?: InputMaybe<StringNullableFilter>;
  IsDisbanded?: InputMaybe<BoolFilter>;
  IsLowercase?: InputMaybe<BoolNullableFilter>;
  Location?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<TeamScalarWhereInput>>;
  Name?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<TeamScalarWhereInput>>;
  OrganizationPage?: InputMaybe<StringNullableFilter>;
  OverviewPage?: InputMaybe<StringFilter>;
  Region?: InputMaybe<StringFilter>;
  RenamedTo?: InputMaybe<StringNullableFilter>;
  RosterPhoto?: InputMaybe<StringNullableFilter>;
  Short?: InputMaybe<StringFilter>;
  Snapchat?: InputMaybe<StringNullableFilter>;
  Subreddit?: InputMaybe<StringNullableFilter>;
  TeamLocation?: InputMaybe<StringNullableFilter>;
  Twitter?: InputMaybe<StringNullableFilter>;
  Vk?: InputMaybe<StringNullableFilter>;
  Website?: InputMaybe<StringNullableFilter>;
  Youtube?: InputMaybe<StringNullableFilter>;
};

export type TeamScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<TeamScalarWhereWithAggregatesInput>>;
  Cargo_ID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageID?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageName?: InputMaybe<StringWithAggregatesFilter>;
  Cargo_pageNamespace?: InputMaybe<IntWithAggregatesFilter>;
  Cargo_pageTitle?: InputMaybe<StringWithAggregatesFilter>;
  Discord?: InputMaybe<StringNullableWithAggregatesFilter>;
  Facebook?: InputMaybe<StringNullableWithAggregatesFilter>;
  Image?: InputMaybe<StringNullableWithAggregatesFilter>;
  Instagram?: InputMaybe<StringNullableWithAggregatesFilter>;
  IsDisbanded?: InputMaybe<BoolWithAggregatesFilter>;
  IsLowercase?: InputMaybe<BoolNullableWithAggregatesFilter>;
  Location?: InputMaybe<StringNullableWithAggregatesFilter>;
  NOT?: InputMaybe<Array<TeamScalarWhereWithAggregatesInput>>;
  Name?: InputMaybe<StringNullableWithAggregatesFilter>;
  OR?: InputMaybe<Array<TeamScalarWhereWithAggregatesInput>>;
  OrganizationPage?: InputMaybe<StringNullableWithAggregatesFilter>;
  OverviewPage?: InputMaybe<StringWithAggregatesFilter>;
  Region?: InputMaybe<StringWithAggregatesFilter>;
  RenamedTo?: InputMaybe<StringNullableWithAggregatesFilter>;
  RosterPhoto?: InputMaybe<StringNullableWithAggregatesFilter>;
  Short?: InputMaybe<StringWithAggregatesFilter>;
  Snapchat?: InputMaybe<StringNullableWithAggregatesFilter>;
  Subreddit?: InputMaybe<StringNullableWithAggregatesFilter>;
  TeamLocation?: InputMaybe<StringNullableWithAggregatesFilter>;
  Twitter?: InputMaybe<StringNullableWithAggregatesFilter>;
  Vk?: InputMaybe<StringNullableWithAggregatesFilter>;
  Website?: InputMaybe<StringNullableWithAggregatesFilter>;
  Youtube?: InputMaybe<StringNullableWithAggregatesFilter>;
};

export type TeamSumAggregate = {
  __typename?: 'TeamSumAggregate';
  Cargo_ID?: Maybe<Scalars['Int']>;
  Cargo_pageID?: Maybe<Scalars['Int']>;
  Cargo_pageNamespace?: Maybe<Scalars['Int']>;
};

export type TeamSumOrderByAggregateInput = {
  Cargo_ID?: InputMaybe<SortOrder>;
  Cargo_pageID?: InputMaybe<SortOrder>;
  Cargo_pageNamespace?: InputMaybe<SortOrder>;
};

export type TeamUpdateInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsDisbanded?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OrganizationPage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  RenamedTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RosterPhoto?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Subreddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamInput>;
  region?: InputMaybe<RegionUpdateOneRequiredWithoutTeamsInput>;
  teamRedirects?: InputMaybe<TeamRedirectUpdateManyWithoutTeamInput>;
};

export type TeamUpdateManyMutationInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsDisbanded?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OrganizationPage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  RenamedTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RosterPhoto?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Subreddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
};

export type TeamUpdateManyWithWhereWithoutRegionInput = {
  data: TeamUpdateManyMutationInput;
  where: TeamScalarWhereInput;
};

export type TeamUpdateManyWithoutRegionInput = {
  connect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<TeamCreateOrConnectWithoutRegionInput>>;
  create?: InputMaybe<Array<TeamCreateWithoutRegionInput>>;
  createMany?: InputMaybe<TeamCreateManyRegionInputEnvelope>;
  delete?: InputMaybe<Array<TeamWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<TeamScalarWhereInput>>;
  disconnect?: InputMaybe<Array<TeamWhereUniqueInput>>;
  set?: InputMaybe<Array<TeamWhereUniqueInput>>;
  update?: InputMaybe<Array<TeamUpdateWithWhereUniqueWithoutRegionInput>>;
  updateMany?: InputMaybe<Array<TeamUpdateManyWithWhereWithoutRegionInput>>;
  upsert?: InputMaybe<Array<TeamUpsertWithWhereUniqueWithoutRegionInput>>;
};

export type TeamUpdateOneRequiredWithoutTeamRedirectsInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutTeamRedirectsInput>;
  create?: InputMaybe<TeamCreateWithoutTeamRedirectsInput>;
  update?: InputMaybe<TeamUpdateWithoutTeamRedirectsInput>;
  upsert?: InputMaybe<TeamUpsertWithoutTeamRedirectsInput>;
};

export type TeamUpdateOneWithoutPlayersInput = {
  connect?: InputMaybe<TeamWhereUniqueInput>;
  connectOrCreate?: InputMaybe<TeamCreateOrConnectWithoutPlayersInput>;
  create?: InputMaybe<TeamCreateWithoutPlayersInput>;
  delete?: InputMaybe<Scalars['Boolean']>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
  update?: InputMaybe<TeamUpdateWithoutPlayersInput>;
  upsert?: InputMaybe<TeamUpsertWithoutPlayersInput>;
};

export type TeamUpdateWithWhereUniqueWithoutRegionInput = {
  data: TeamUpdateWithoutRegionInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpdateWithoutPlayersInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsDisbanded?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OrganizationPage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  RenamedTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RosterPhoto?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Subreddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  region?: InputMaybe<RegionUpdateOneRequiredWithoutTeamsInput>;
  teamRedirects?: InputMaybe<TeamRedirectUpdateManyWithoutTeamInput>;
};

export type TeamUpdateWithoutRegionInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsDisbanded?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OrganizationPage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  RenamedTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RosterPhoto?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Subreddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamInput>;
  teamRedirects?: InputMaybe<TeamRedirectUpdateManyWithoutTeamInput>;
};

export type TeamUpdateWithoutTeamRedirectsInput = {
  Cargo_ID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageID?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageName?: InputMaybe<StringFieldUpdateOperationsInput>;
  Cargo_pageNamespace?: InputMaybe<IntFieldUpdateOperationsInput>;
  Cargo_pageTitle?: InputMaybe<StringFieldUpdateOperationsInput>;
  Discord?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Facebook?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Instagram?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  IsDisbanded?: InputMaybe<BoolFieldUpdateOperationsInput>;
  IsLowercase?: InputMaybe<NullableBoolFieldUpdateOperationsInput>;
  Location?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OrganizationPage?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  OverviewPage?: InputMaybe<StringFieldUpdateOperationsInput>;
  RenamedTo?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  RosterPhoto?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Short?: InputMaybe<StringFieldUpdateOperationsInput>;
  Snapchat?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Subreddit?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  TeamLocation?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Twitter?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Vk?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Website?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  Youtube?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  players?: InputMaybe<PlayerUpdateManyWithoutTeamInput>;
  region?: InputMaybe<RegionUpdateOneRequiredWithoutTeamsInput>;
};

export type TeamUpsertWithWhereUniqueWithoutRegionInput = {
  create: TeamCreateWithoutRegionInput;
  update: TeamUpdateWithoutRegionInput;
  where: TeamWhereUniqueInput;
};

export type TeamUpsertWithoutPlayersInput = {
  create: TeamCreateWithoutPlayersInput;
  update: TeamUpdateWithoutPlayersInput;
};

export type TeamUpsertWithoutTeamRedirectsInput = {
  create: TeamCreateWithoutTeamRedirectsInput;
  update: TeamUpdateWithoutTeamRedirectsInput;
};

export type TeamWhereInput = {
  AND?: InputMaybe<Array<TeamWhereInput>>;
  Cargo_ID?: InputMaybe<IntFilter>;
  Cargo_pageID?: InputMaybe<IntFilter>;
  Cargo_pageName?: InputMaybe<StringFilter>;
  Cargo_pageNamespace?: InputMaybe<IntFilter>;
  Cargo_pageTitle?: InputMaybe<StringFilter>;
  Discord?: InputMaybe<StringNullableFilter>;
  Facebook?: InputMaybe<StringNullableFilter>;
  Image?: InputMaybe<StringNullableFilter>;
  Instagram?: InputMaybe<StringNullableFilter>;
  IsDisbanded?: InputMaybe<BoolFilter>;
  IsLowercase?: InputMaybe<BoolNullableFilter>;
  Location?: InputMaybe<StringNullableFilter>;
  NOT?: InputMaybe<Array<TeamWhereInput>>;
  Name?: InputMaybe<StringNullableFilter>;
  OR?: InputMaybe<Array<TeamWhereInput>>;
  OrganizationPage?: InputMaybe<StringNullableFilter>;
  OverviewPage?: InputMaybe<StringFilter>;
  Region?: InputMaybe<StringFilter>;
  RenamedTo?: InputMaybe<StringNullableFilter>;
  RosterPhoto?: InputMaybe<StringNullableFilter>;
  Short?: InputMaybe<StringFilter>;
  Snapchat?: InputMaybe<StringNullableFilter>;
  Subreddit?: InputMaybe<StringNullableFilter>;
  TeamLocation?: InputMaybe<StringNullableFilter>;
  Twitter?: InputMaybe<StringNullableFilter>;
  Vk?: InputMaybe<StringNullableFilter>;
  Website?: InputMaybe<StringNullableFilter>;
  Youtube?: InputMaybe<StringNullableFilter>;
  players?: InputMaybe<PlayerListRelationFilter>;
  region?: InputMaybe<RegionRelationFilter>;
  teamRedirects?: InputMaybe<TeamRedirectListRelationFilter>;
};

export type TeamWhereUniqueInput = {
  Cargo_pageID?: InputMaybe<Scalars['Int']>;
  Cargo_pageName?: InputMaybe<Scalars['String']>;
  OverviewPage?: InputMaybe<Scalars['String']>;
};

export enum Tier {
  Bronze = 'BRONZE',
  Challenger = 'CHALLENGER',
  Diamond = 'DIAMOND',
  Emerald = 'EMERALD',
  Gold = 'GOLD',
  Grandmaster = 'GRANDMASTER',
  Iron = 'IRON',
  Master = 'MASTER',
  Platinum = 'PLATINUM',
  Silver = 'SILVER'
}

export type User = {
  __typename?: 'User';
  _count?: Maybe<UserCount>;
  accounts: Array<Account>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  sessions: Array<Session>;
  subscriptionEnd?: Maybe<Scalars['DateTime']>;
  subscriptionPlanId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};


export type UserAccountsArgs = {
  cursor?: InputMaybe<AccountWhereUniqueInput>;
  distinct?: InputMaybe<Array<AccountScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<AccountOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<AccountWhereInput>;
};


export type UserSessionsArgs = {
  cursor?: InputMaybe<SessionWhereUniqueInput>;
  distinct?: InputMaybe<Array<SessionScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<SessionOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SessionWhereInput>;
};

export type UserAvgAggregate = {
  __typename?: 'UserAvgAggregate';
  subscriptionPlanId?: Maybe<Scalars['Float']>;
};

export type UserAvgOrderByAggregateInput = {
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type UserCount = {
  __typename?: 'UserCount';
  accounts: Scalars['Int'];
  sessions: Scalars['Int'];
};

export type UserCountAggregate = {
  __typename?: 'UserCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  email: Scalars['Int'];
  emailVerified: Scalars['Int'];
  id: Scalars['Int'];
  image: Scalars['Int'];
  name: Scalars['Int'];
  password: Scalars['Int'];
  subscriptionEnd: Scalars['Int'];
  subscriptionPlanId: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type UserCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  subscriptionEnd?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserCreateInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<SessionCreateNestedManyWithoutUserInput>;
  subscriptionEnd?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  subscriptionEnd?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateNestedOneWithoutAccountsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAccountsInput>;
  create?: InputMaybe<UserCreateWithoutAccountsInput>;
};

export type UserCreateNestedOneWithoutSessionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSessionsInput>;
  create?: InputMaybe<UserCreateWithoutSessionsInput>;
};

export type UserCreateOrConnectWithoutAccountsInput = {
  create: UserCreateWithoutAccountsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutSessionsInput = {
  create: UserCreateWithoutSessionsInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutAccountsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  sessions?: InputMaybe<SessionCreateNestedManyWithoutUserInput>;
  subscriptionEnd?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutSessionsInput = {
  accounts?: InputMaybe<AccountCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  emailVerified?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  subscriptionEnd?: InputMaybe<Scalars['DateTime']>;
  subscriptionPlanId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserGroupBy = {
  __typename?: 'UserGroupBy';
  _avg?: Maybe<UserAvgAggregate>;
  _count?: Maybe<UserCountAggregate>;
  _max?: Maybe<UserMaxAggregate>;
  _min?: Maybe<UserMinAggregate>;
  _sum?: Maybe<UserSumAggregate>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subscriptionEnd?: Maybe<Scalars['DateTime']>;
  subscriptionPlanId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type UserMaxAggregate = {
  __typename?: 'UserMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subscriptionEnd?: Maybe<Scalars['DateTime']>;
  subscriptionPlanId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  subscriptionEnd?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserMinAggregate = {
  __typename?: 'UserMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  subscriptionEnd?: Maybe<Scalars['DateTime']>;
  subscriptionPlanId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UserMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  subscriptionEnd?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserOrderByWithAggregationInput = {
  _avg?: InputMaybe<UserAvgOrderByAggregateInput>;
  _count?: InputMaybe<UserCountOrderByAggregateInput>;
  _max?: InputMaybe<UserMaxOrderByAggregateInput>;
  _min?: InputMaybe<UserMinOrderByAggregateInput>;
  _sum?: InputMaybe<UserSumOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  subscriptionEnd?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  accounts?: InputMaybe<AccountOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  emailVerified?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  image?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  sessions?: InputMaybe<SessionOrderByRelationAggregateInput>;
  subscriptionEnd?: InputMaybe<SortOrder>;
  subscriptionPlanId?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  CreatedAt = 'createdAt',
  Email = 'email',
  EmailVerified = 'emailVerified',
  Id = 'id',
  Image = 'image',
  Name = 'name',
  Password = 'password',
  SubscriptionEnd = 'subscriptionEnd',
  SubscriptionPlanId = 'subscriptionPlanId',
  UpdatedAt = 'updatedAt'
}

export type UserScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<UserScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  email?: InputMaybe<StringNullableWithAggregatesFilter>;
  emailVerified?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  image?: InputMaybe<StringNullableWithAggregatesFilter>;
  name?: InputMaybe<StringNullableWithAggregatesFilter>;
  password?: InputMaybe<StringNullableWithAggregatesFilter>;
  subscriptionEnd?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  subscriptionPlanId?: InputMaybe<IntNullableWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type UserSumAggregate = {
  __typename?: 'UserSumAggregate';
  subscriptionPlanId?: Maybe<Scalars['Int']>;
};

export type UserSumOrderByAggregateInput = {
  subscriptionPlanId?: InputMaybe<SortOrder>;
};

export type UserUpdateInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sessions?: InputMaybe<SessionUpdateManyWithoutUserInput>;
  subscriptionEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  subscriptionPlanId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subscriptionEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  subscriptionPlanId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateOneRequiredWithoutAccountsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutAccountsInput>;
  create?: InputMaybe<UserCreateWithoutAccountsInput>;
  update?: InputMaybe<UserUpdateWithoutAccountsInput>;
  upsert?: InputMaybe<UserUpsertWithoutAccountsInput>;
};

export type UserUpdateOneRequiredWithoutSessionsInput = {
  connect?: InputMaybe<UserWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UserCreateOrConnectWithoutSessionsInput>;
  create?: InputMaybe<UserCreateWithoutSessionsInput>;
  update?: InputMaybe<UserUpdateWithoutSessionsInput>;
  upsert?: InputMaybe<UserUpsertWithoutSessionsInput>;
};

export type UserUpdateWithoutAccountsInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  sessions?: InputMaybe<SessionUpdateManyWithoutUserInput>;
  subscriptionEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  subscriptionPlanId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpdateWithoutSessionsInput = {
  accounts?: InputMaybe<AccountUpdateManyWithoutUserInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  email?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  emailVerified?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  image?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  name?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  password?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  subscriptionEnd?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  subscriptionPlanId?: InputMaybe<NullableIntFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type UserUpsertWithoutAccountsInput = {
  create: UserCreateWithoutAccountsInput;
  update: UserUpdateWithoutAccountsInput;
};

export type UserUpsertWithoutSessionsInput = {
  create: UserCreateWithoutSessionsInput;
  update: UserUpdateWithoutSessionsInput;
};

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  accounts?: InputMaybe<AccountListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  emailVerified?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringNullableFilter>;
  name?: InputMaybe<StringNullableFilter>;
  password?: InputMaybe<StringNullableFilter>;
  sessions?: InputMaybe<SessionListRelationFilter>;
  subscriptionEnd?: InputMaybe<DateTimeNullableFilter>;
  subscriptionPlanId?: InputMaybe<IntNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

export type VerificationToken = {
  __typename?: 'VerificationToken';
  createdAt: Scalars['DateTime'];
  expires: Scalars['DateTime'];
  identifier: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type VerificationTokenCountAggregate = {
  __typename?: 'VerificationTokenCountAggregate';
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  expires: Scalars['Int'];
  identifier: Scalars['Int'];
  token: Scalars['Int'];
  updatedAt: Scalars['Int'];
};

export type VerificationTokenCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerificationTokenCreateInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires: Scalars['DateTime'];
  identifier: Scalars['String'];
  token: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerificationTokenCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  expires: Scalars['DateTime'];
  identifier: Scalars['String'];
  token: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type VerificationTokenGroupBy = {
  __typename?: 'VerificationTokenGroupBy';
  _count?: Maybe<VerificationTokenCountAggregate>;
  _max?: Maybe<VerificationTokenMaxAggregate>;
  _min?: Maybe<VerificationTokenMinAggregate>;
  createdAt: Scalars['DateTime'];
  expires: Scalars['DateTime'];
  identifier: Scalars['String'];
  token: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type VerificationTokenIdentifierTokenCompoundUniqueInput = {
  identifier: Scalars['String'];
  token: Scalars['String'];
};

export type VerificationTokenMaxAggregate = {
  __typename?: 'VerificationTokenMaxAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  identifier?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerificationTokenMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerificationTokenMinAggregate = {
  __typename?: 'VerificationTokenMinAggregate';
  createdAt?: Maybe<Scalars['DateTime']>;
  expires?: Maybe<Scalars['DateTime']>;
  identifier?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type VerificationTokenMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerificationTokenOrderByWithAggregationInput = {
  _count?: InputMaybe<VerificationTokenCountOrderByAggregateInput>;
  _max?: InputMaybe<VerificationTokenMaxOrderByAggregateInput>;
  _min?: InputMaybe<VerificationTokenMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type VerificationTokenOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  expires?: InputMaybe<SortOrder>;
  identifier?: InputMaybe<SortOrder>;
  token?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export enum VerificationTokenScalarFieldEnum {
  CreatedAt = 'createdAt',
  Expires = 'expires',
  Identifier = 'identifier',
  Token = 'token',
  UpdatedAt = 'updatedAt'
}

export type VerificationTokenScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<VerificationTokenScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<VerificationTokenScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<VerificationTokenScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  expires?: InputMaybe<DateTimeWithAggregatesFilter>;
  identifier?: InputMaybe<StringWithAggregatesFilter>;
  token?: InputMaybe<StringWithAggregatesFilter>;
  updatedAt?: InputMaybe<DateTimeWithAggregatesFilter>;
};

export type VerificationTokenUpdateInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  identifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VerificationTokenUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  expires?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  identifier?: InputMaybe<StringFieldUpdateOperationsInput>;
  token?: InputMaybe<StringFieldUpdateOperationsInput>;
  updatedAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
};

export type VerificationTokenWhereInput = {
  AND?: InputMaybe<Array<VerificationTokenWhereInput>>;
  NOT?: InputMaybe<Array<VerificationTokenWhereInput>>;
  OR?: InputMaybe<Array<VerificationTokenWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expires?: InputMaybe<DateTimeFilter>;
  identifier?: InputMaybe<StringFilter>;
  token?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type VerificationTokenWhereUniqueInput = {
  identifier_token?: InputMaybe<VerificationTokenIdentifierTokenCompoundUniqueInput>;
  token?: InputMaybe<Scalars['String']>;
};

export type GlobalSearchQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']>;
}>;


export type GlobalSearchQuery = { __typename?: 'Query', players: Array<{ __typename?: 'Player', OverviewPage: string }>, teams: Array<{ __typename?: 'Team', OverviewPage: string }> };

export type LeagueEntryComponentArgsFragment = { __typename?: 'LeagueEntry', tier: Tier, rank: Division, leaguePoints: number, wins: number, losses: number };

export type WinRateComponentArgsFragment = { __typename?: 'LeagueEntry', wins: number, losses: number };

export type HistoryGameComponentArgsFragment = { __typename?: 'MatchParticipant', data: any, match: { __typename?: 'Match', id: string, gameId: any, platformId: string, queueId: number, gameDuration: number, gameVersion: string, gameEndTimestamp: any, participants: Array<{ __typename?: 'MatchParticipant', teamId: number, summoner: { __typename?: 'Summoner', gameName: string, tagLine: string, leagueEntry?: { __typename?: 'LeagueEntry', tier: Tier, leaguePoints: number } | null | undefined, player?: { __typename?: 'Player', OverviewPage: string, ID: string, team?: { __typename?: 'Team', OverviewPage: string, Short: string } | null | undefined } | null | undefined } }> }, team: { __typename?: 'MatchTeam', teamId: number, win: boolean, objectives?: any | null | undefined } };

export type SearchPlayersQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']>;
}>;


export type SearchPlayersQuery = { __typename?: 'Query', players: Array<{ __typename?: 'Player', OverviewPage: string }> };

export type PlayerLinkComponentArgsFragment = { __typename?: 'Player', OverviewPage: string, ID: string };

export type TeamLinkComponentNameArgsFragment = { __typename?: 'Team', OverviewPage: string, Name?: string | null | undefined };

export type TeamLinkComponentShortArgsFragment = { __typename?: 'Team', OverviewPage: string, Short: string };

export type PlayerGameHistoryQueryVariables = Exact<{
  pageName?: InputMaybe<Scalars['String']>;
}>;


export type PlayerGameHistoryQuery = { __typename?: 'Query', summoners: Array<{ __typename?: 'Summoner', puuid: string, platform: Platform, gameName: string, tagLine: string, leagueEntry?: { __typename?: 'LeagueEntry', tier: Tier, rank: Division, leaguePoints: number } | null | undefined }>, matchParticipants: Array<{ __typename?: 'MatchParticipant', puuid: string, data: any, match: { __typename?: 'Match', id: string, gameId: any, platformId: string, queueId: number, gameDuration: number, gameVersion: string, gameEndTimestamp: any, participants: Array<{ __typename?: 'MatchParticipant', teamId: number, summoner: { __typename?: 'Summoner', gameName: string, tagLine: string, leagueEntry?: { __typename?: 'LeagueEntry', tier: Tier, leaguePoints: number } | null | undefined, player?: { __typename?: 'Player', OverviewPage: string, ID: string, team?: { __typename?: 'Team', OverviewPage: string, Short: string } | null | undefined } | null | undefined } }> }, team: { __typename?: 'MatchTeam', teamId: number, win: boolean, objectives?: any | null | undefined } }> };

export type PlayerSummonersQueryVariables = Exact<{
  pageName: Scalars['String'];
}>;


export type PlayerSummonersQuery = { __typename?: 'Query', summoners: Array<{ __typename?: 'Summoner', id: string, platform: Platform, gameName: string, tagLine: string, profileIconId: number, recent?: any | null | undefined, leagueEntry?: { __typename?: 'LeagueEntry', tier: Tier, rank: Division, leaguePoints: number, wins: number, losses: number } | null | undefined }> };

export type PlayerPageLayoutQueryVariables = Exact<{
  pageName: Scalars['String'];
}>;


export type PlayerPageLayoutQuery = { __typename?: 'Query', findFirstPlayerRedirect?: { __typename?: 'PlayerRedirect', player: { __typename?: 'Player', OverviewPage: string, ID: string, Youtube?: string | null | undefined, Instagram?: string | null | undefined, Twitter?: string | null | undefined, Facebook?: string | null | undefined, Stream?: string | null | undefined, NameFull?: string | null | undefined, Birthdate?: any | null | undefined, team?: { __typename?: 'Team', OverviewPage: string, Name?: string | null | undefined } | null | undefined } } | null | undefined };

export type TierComponentArgsFragment = { __typename?: 'LeagueEntry', tier: Tier, rank: Division, leaguePoints: number };

export type SummonerGameHistoryQueryVariables = Exact<{
  gameName: Scalars['String'];
  tagLine: Scalars['String'];
}>;


export type SummonerGameHistoryQuery = { __typename?: 'Query', summoner?: { __typename?: 'Summoner', puuid: string } | null | undefined, matchParticipants: Array<{ __typename?: 'MatchParticipant', data: any, match: { __typename?: 'Match', id: string, gameId: any, platformId: string, queueId: number, gameDuration: number, gameVersion: string, gameEndTimestamp: any, participants: Array<{ __typename?: 'MatchParticipant', teamId: number, summoner: { __typename?: 'Summoner', gameName: string, tagLine: string, leagueEntry?: { __typename?: 'LeagueEntry', tier: Tier, leaguePoints: number } | null | undefined, player?: { __typename?: 'Player', OverviewPage: string, ID: string, team?: { __typename?: 'Team', OverviewPage: string, Short: string } | null | undefined } | null | undefined } }> }, team: { __typename?: 'MatchTeam', teamId: number, win: boolean, objectives?: any | null | undefined } }> };

export type SummonerPageLayoutQueryVariables = Exact<{
  gameName: Scalars['String'];
  tagLine: Scalars['String'];
}>;


export type SummonerPageLayoutQuery = { __typename?: 'Query', summoner?: { __typename?: 'Summoner', profileIconId: number, platform: Platform, trackedByUser: boolean, leagueEntry?: { __typename?: 'LeagueEntry', tier: Tier, rank: Division, leaguePoints: number, wins: number, losses: number } | null | undefined, player?: { __typename?: 'Player', Role?: string | null | undefined, OverviewPage: string, ID: string, team?: { __typename?: 'Team', OverviewPage: string, Short: string } | null | undefined } | null | undefined } | null | undefined };

export type MutateTrackedByUserMutationVariables = Exact<{
  gameName: Scalars['String'];
  tagLine: Scalars['String'];
  trackedByUser: Scalars['Boolean'];
}>;


export type MutateTrackedByUserMutation = { __typename?: 'Mutation', updateSummoner?: { __typename?: 'Summoner', trackedByUser: boolean } | null | undefined };

export type TeamOverviewQueryVariables = Exact<{
  pageName?: InputMaybe<Scalars['String']>;
}>;


export type TeamOverviewQuery = { __typename?: 'Query', team?: { __typename?: 'Team', players: Array<{ __typename?: 'Player', NameFull?: string | null | undefined, Role?: string | null | undefined, Birthdate?: any | null | undefined, OverviewPage: string, ID: string }> } | null | undefined };

export type TeamPageLayoutQueryVariables = Exact<{
  pageName?: InputMaybe<Scalars['String']>;
}>;


export type TeamPageLayoutQuery = { __typename?: 'Query', findFirstTeamRedirect?: { __typename?: 'TeamRedirect', team: { __typename?: 'Team', OverviewPage: string, Name?: string | null | undefined, Twitter?: string | null | undefined, Youtube?: string | null | undefined, Facebook?: string | null | undefined, Instagram?: string | null | undefined, Discord?: string | null | undefined, Subreddit?: string | null | undefined, Website?: string | null | undefined } } | null | undefined };

export const LeagueEntryComponentArgsFragmentDoc = gql`
    fragment LeagueEntryComponentArgs on LeagueEntry {
  tier
  rank
  leaguePoints
  wins
  losses
}
    `;
export const WinRateComponentArgsFragmentDoc = gql`
    fragment WinRateComponentArgs on LeagueEntry {
  wins
  losses
}
    `;
export const HistoryGameComponentArgsFragmentDoc = gql`
    fragment HistoryGameComponentArgs on MatchParticipant {
  data
  match {
    id
    gameId
    platformId
    queueId
    gameDuration
    gameVersion
    gameEndTimestamp
    participants {
      teamId
      summoner {
        gameName
        tagLine
        leagueEntry {
          tier
          leaguePoints
        }
        player {
          OverviewPage
          ID
          team {
            OverviewPage
            Short
          }
        }
      }
    }
  }
  team {
    teamId
    win
    objectives
  }
}
    `;
export const PlayerLinkComponentArgsFragmentDoc = gql`
    fragment PlayerLinkComponentArgs on Player {
  OverviewPage
  ID
}
    `;
export const TeamLinkComponentNameArgsFragmentDoc = gql`
    fragment TeamLinkComponentNameArgs on Team {
  OverviewPage
  Name
}
    `;
export const TeamLinkComponentShortArgsFragmentDoc = gql`
    fragment TeamLinkComponentShortArgs on Team {
  OverviewPage
  Short
}
    `;
export const TierComponentArgsFragmentDoc = gql`
    fragment TierComponentArgs on LeagueEntry {
  tier
  rank
  leaguePoints
}
    `;
export const GlobalSearchDocument = gql`
    query GlobalSearch($keyword: String) {
  players: searchPlayers(keyword: $keyword, take: 5) {
    OverviewPage
  }
  teams: searchTeams(keyword: $keyword, take: 5) {
    OverviewPage
  }
}
    `;

/**
 * __useGlobalSearchQuery__
 *
 * To run a query within a React component, call `useGlobalSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSearchQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGlobalSearchQuery(baseOptions?: Apollo.QueryHookOptions<GlobalSearchQuery, GlobalSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
      }
export function useGlobalSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GlobalSearchQuery, GlobalSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GlobalSearchQuery, GlobalSearchQueryVariables>(GlobalSearchDocument, options);
        }
export type GlobalSearchQueryHookResult = ReturnType<typeof useGlobalSearchQuery>;
export type GlobalSearchLazyQueryHookResult = ReturnType<typeof useGlobalSearchLazyQuery>;
export type GlobalSearchQueryResult = Apollo.QueryResult<GlobalSearchQuery, GlobalSearchQueryVariables>;
export const SearchPlayersDocument = gql`
    query SearchPlayers($keyword: String) {
  players: searchPlayers(keyword: $keyword) {
    OverviewPage
  }
}
    `;

/**
 * __useSearchPlayersQuery__
 *
 * To run a query within a React component, call `useSearchPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPlayersQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useSearchPlayersQuery(baseOptions?: Apollo.QueryHookOptions<SearchPlayersQuery, SearchPlayersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchPlayersQuery, SearchPlayersQueryVariables>(SearchPlayersDocument, options);
      }
export function useSearchPlayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchPlayersQuery, SearchPlayersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchPlayersQuery, SearchPlayersQueryVariables>(SearchPlayersDocument, options);
        }
export type SearchPlayersQueryHookResult = ReturnType<typeof useSearchPlayersQuery>;
export type SearchPlayersLazyQueryHookResult = ReturnType<typeof useSearchPlayersLazyQuery>;
export type SearchPlayersQueryResult = Apollo.QueryResult<SearchPlayersQuery, SearchPlayersQueryVariables>;
export const PlayerGameHistoryDocument = gql`
    query PlayerGameHistory($pageName: String) {
  summoners(where: {playerOverviewPage: {equals: $pageName}}) {
    puuid
    platform
    gameName
    tagLine
    leagueEntry {
      ...TierComponentArgs
    }
  }
  matchParticipants(
    take: 10
    orderBy: {match: {gameCreation: desc}}
    where: {summoner: {is: {playerOverviewPage: {equals: $pageName}}}}
  ) {
    ...HistoryGameComponentArgs
    puuid
  }
}
    ${TierComponentArgsFragmentDoc}
${HistoryGameComponentArgsFragmentDoc}`;

/**
 * __usePlayerGameHistoryQuery__
 *
 * To run a query within a React component, call `usePlayerGameHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerGameHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerGameHistoryQuery({
 *   variables: {
 *      pageName: // value for 'pageName'
 *   },
 * });
 */
export function usePlayerGameHistoryQuery(baseOptions?: Apollo.QueryHookOptions<PlayerGameHistoryQuery, PlayerGameHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerGameHistoryQuery, PlayerGameHistoryQueryVariables>(PlayerGameHistoryDocument, options);
      }
export function usePlayerGameHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerGameHistoryQuery, PlayerGameHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerGameHistoryQuery, PlayerGameHistoryQueryVariables>(PlayerGameHistoryDocument, options);
        }
export type PlayerGameHistoryQueryHookResult = ReturnType<typeof usePlayerGameHistoryQuery>;
export type PlayerGameHistoryLazyQueryHookResult = ReturnType<typeof usePlayerGameHistoryLazyQuery>;
export type PlayerGameHistoryQueryResult = Apollo.QueryResult<PlayerGameHistoryQuery, PlayerGameHistoryQueryVariables>;
export const PlayerSummonersDocument = gql`
    query PlayerSummoners($pageName: String!) {
  summoners(where: {player: {is: {OverviewPage: {equals: $pageName}}}}) {
    id
    platform
    gameName
    tagLine
    profileIconId
    leagueEntry {
      ...TierComponentArgs
      ...WinRateComponentArgs
    }
    recent
  }
}
    ${TierComponentArgsFragmentDoc}
${WinRateComponentArgsFragmentDoc}`;

/**
 * __usePlayerSummonersQuery__
 *
 * To run a query within a React component, call `usePlayerSummonersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerSummonersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerSummonersQuery({
 *   variables: {
 *      pageName: // value for 'pageName'
 *   },
 * });
 */
export function usePlayerSummonersQuery(baseOptions: Apollo.QueryHookOptions<PlayerSummonersQuery, PlayerSummonersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerSummonersQuery, PlayerSummonersQueryVariables>(PlayerSummonersDocument, options);
      }
export function usePlayerSummonersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerSummonersQuery, PlayerSummonersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerSummonersQuery, PlayerSummonersQueryVariables>(PlayerSummonersDocument, options);
        }
export type PlayerSummonersQueryHookResult = ReturnType<typeof usePlayerSummonersQuery>;
export type PlayerSummonersLazyQueryHookResult = ReturnType<typeof usePlayerSummonersLazyQuery>;
export type PlayerSummonersQueryResult = Apollo.QueryResult<PlayerSummonersQuery, PlayerSummonersQueryVariables>;
export const PlayerPageLayoutDocument = gql`
    query PlayerPageLayout($pageName: String!) {
  findFirstPlayerRedirect(
    where: {AllName: {equals: $pageName, mode: insensitive}}
  ) {
    player {
      OverviewPage
      ID
      team {
        ...TeamLinkComponentNameArgs
      }
      Youtube
      Instagram
      Twitter
      Facebook
      Stream
      NameFull
      Birthdate
    }
  }
}
    ${TeamLinkComponentNameArgsFragmentDoc}`;

/**
 * __usePlayerPageLayoutQuery__
 *
 * To run a query within a React component, call `usePlayerPageLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayerPageLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayerPageLayoutQuery({
 *   variables: {
 *      pageName: // value for 'pageName'
 *   },
 * });
 */
export function usePlayerPageLayoutQuery(baseOptions: Apollo.QueryHookOptions<PlayerPageLayoutQuery, PlayerPageLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlayerPageLayoutQuery, PlayerPageLayoutQueryVariables>(PlayerPageLayoutDocument, options);
      }
export function usePlayerPageLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlayerPageLayoutQuery, PlayerPageLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlayerPageLayoutQuery, PlayerPageLayoutQueryVariables>(PlayerPageLayoutDocument, options);
        }
export type PlayerPageLayoutQueryHookResult = ReturnType<typeof usePlayerPageLayoutQuery>;
export type PlayerPageLayoutLazyQueryHookResult = ReturnType<typeof usePlayerPageLayoutLazyQuery>;
export type PlayerPageLayoutQueryResult = Apollo.QueryResult<PlayerPageLayoutQuery, PlayerPageLayoutQueryVariables>;
export const SummonerGameHistoryDocument = gql`
    query SummonerGameHistory($gameName: String!, $tagLine: String!) {
  summoner(where: {gameName_tagLine: {gameName: $gameName, tagLine: $tagLine}}) {
    puuid
  }
  matchParticipants(
    take: 10
    orderBy: {match: {gameCreation: desc}}
    where: {summoner: {is: {gameName: {equals: $gameName}, tagLine: {equals: $tagLine}}}}
  ) {
    ...HistoryGameComponentArgs
  }
}
    ${HistoryGameComponentArgsFragmentDoc}`;

/**
 * __useSummonerGameHistoryQuery__
 *
 * To run a query within a React component, call `useSummonerGameHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummonerGameHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummonerGameHistoryQuery({
 *   variables: {
 *      gameName: // value for 'gameName'
 *      tagLine: // value for 'tagLine'
 *   },
 * });
 */
export function useSummonerGameHistoryQuery(baseOptions: Apollo.QueryHookOptions<SummonerGameHistoryQuery, SummonerGameHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummonerGameHistoryQuery, SummonerGameHistoryQueryVariables>(SummonerGameHistoryDocument, options);
      }
export function useSummonerGameHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummonerGameHistoryQuery, SummonerGameHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummonerGameHistoryQuery, SummonerGameHistoryQueryVariables>(SummonerGameHistoryDocument, options);
        }
export type SummonerGameHistoryQueryHookResult = ReturnType<typeof useSummonerGameHistoryQuery>;
export type SummonerGameHistoryLazyQueryHookResult = ReturnType<typeof useSummonerGameHistoryLazyQuery>;
export type SummonerGameHistoryQueryResult = Apollo.QueryResult<SummonerGameHistoryQuery, SummonerGameHistoryQueryVariables>;
export const SummonerPageLayoutDocument = gql`
    query SummonerPageLayout($gameName: String!, $tagLine: String!) {
  summoner(where: {gameName_tagLine: {gameName: $gameName, tagLine: $tagLine}}) {
    profileIconId
    platform
    leagueEntry {
      ...LeagueEntryComponentArgs
    }
    player {
      Role
      ...PlayerLinkComponentArgs
      team {
        ...TeamLinkComponentShortArgs
      }
    }
    trackedByUser
  }
}
    ${LeagueEntryComponentArgsFragmentDoc}
${PlayerLinkComponentArgsFragmentDoc}
${TeamLinkComponentShortArgsFragmentDoc}`;

/**
 * __useSummonerPageLayoutQuery__
 *
 * To run a query within a React component, call `useSummonerPageLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummonerPageLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummonerPageLayoutQuery({
 *   variables: {
 *      gameName: // value for 'gameName'
 *      tagLine: // value for 'tagLine'
 *   },
 * });
 */
export function useSummonerPageLayoutQuery(baseOptions: Apollo.QueryHookOptions<SummonerPageLayoutQuery, SummonerPageLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummonerPageLayoutQuery, SummonerPageLayoutQueryVariables>(SummonerPageLayoutDocument, options);
      }
export function useSummonerPageLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummonerPageLayoutQuery, SummonerPageLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummonerPageLayoutQuery, SummonerPageLayoutQueryVariables>(SummonerPageLayoutDocument, options);
        }
export type SummonerPageLayoutQueryHookResult = ReturnType<typeof useSummonerPageLayoutQuery>;
export type SummonerPageLayoutLazyQueryHookResult = ReturnType<typeof useSummonerPageLayoutLazyQuery>;
export type SummonerPageLayoutQueryResult = Apollo.QueryResult<SummonerPageLayoutQuery, SummonerPageLayoutQueryVariables>;
export const MutateTrackedByUserDocument = gql`
    mutation mutateTrackedByUser($gameName: String!, $tagLine: String!, $trackedByUser: Boolean!) {
  updateSummoner(
    data: {trackedByUser: {set: $trackedByUser}}
    where: {gameName_tagLine: {gameName: $gameName, tagLine: $tagLine}}
  ) {
    trackedByUser
  }
}
    `;
export type MutateTrackedByUserMutationFn = Apollo.MutationFunction<MutateTrackedByUserMutation, MutateTrackedByUserMutationVariables>;

/**
 * __useMutateTrackedByUserMutation__
 *
 * To run a mutation, you first call `useMutateTrackedByUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMutateTrackedByUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mutateTrackedByUserMutation, { data, loading, error }] = useMutateTrackedByUserMutation({
 *   variables: {
 *      gameName: // value for 'gameName'
 *      tagLine: // value for 'tagLine'
 *      trackedByUser: // value for 'trackedByUser'
 *   },
 * });
 */
export function useMutateTrackedByUserMutation(baseOptions?: Apollo.MutationHookOptions<MutateTrackedByUserMutation, MutateTrackedByUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MutateTrackedByUserMutation, MutateTrackedByUserMutationVariables>(MutateTrackedByUserDocument, options);
      }
export type MutateTrackedByUserMutationHookResult = ReturnType<typeof useMutateTrackedByUserMutation>;
export type MutateTrackedByUserMutationResult = Apollo.MutationResult<MutateTrackedByUserMutation>;
export type MutateTrackedByUserMutationOptions = Apollo.BaseMutationOptions<MutateTrackedByUserMutation, MutateTrackedByUserMutationVariables>;
export const TeamOverviewDocument = gql`
    query TeamOverview($pageName: String) {
  team(where: {OverviewPage: $pageName}) {
    players(
      where: {Role: {in: ["Top", "Jungle", "Mid", "Bot", "Support"]}}
      orderBy: {IsSubstitute: asc}
    ) {
      ...PlayerLinkComponentArgs
      NameFull
      Role
      Birthdate
    }
  }
}
    ${PlayerLinkComponentArgsFragmentDoc}`;

/**
 * __useTeamOverviewQuery__
 *
 * To run a query within a React component, call `useTeamOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOverviewQuery({
 *   variables: {
 *      pageName: // value for 'pageName'
 *   },
 * });
 */
export function useTeamOverviewQuery(baseOptions?: Apollo.QueryHookOptions<TeamOverviewQuery, TeamOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamOverviewQuery, TeamOverviewQueryVariables>(TeamOverviewDocument, options);
      }
export function useTeamOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamOverviewQuery, TeamOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamOverviewQuery, TeamOverviewQueryVariables>(TeamOverviewDocument, options);
        }
export type TeamOverviewQueryHookResult = ReturnType<typeof useTeamOverviewQuery>;
export type TeamOverviewLazyQueryHookResult = ReturnType<typeof useTeamOverviewLazyQuery>;
export type TeamOverviewQueryResult = Apollo.QueryResult<TeamOverviewQuery, TeamOverviewQueryVariables>;
export const TeamPageLayoutDocument = gql`
    query TeamPageLayout($pageName: String) {
  findFirstTeamRedirect(where: {AllName: {equals: $pageName, mode: insensitive}}) {
    team {
      OverviewPage
      Name
      Twitter
      Youtube
      Facebook
      Instagram
      Discord
      Subreddit
      Website
    }
  }
}
    `;

/**
 * __useTeamPageLayoutQuery__
 *
 * To run a query within a React component, call `useTeamPageLayoutQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPageLayoutQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPageLayoutQuery({
 *   variables: {
 *      pageName: // value for 'pageName'
 *   },
 * });
 */
export function useTeamPageLayoutQuery(baseOptions?: Apollo.QueryHookOptions<TeamPageLayoutQuery, TeamPageLayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamPageLayoutQuery, TeamPageLayoutQueryVariables>(TeamPageLayoutDocument, options);
      }
export function useTeamPageLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamPageLayoutQuery, TeamPageLayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamPageLayoutQuery, TeamPageLayoutQueryVariables>(TeamPageLayoutDocument, options);
        }
export type TeamPageLayoutQueryHookResult = ReturnType<typeof useTeamPageLayoutQuery>;
export type TeamPageLayoutLazyQueryHookResult = ReturnType<typeof useTeamPageLayoutLazyQuery>;
export type TeamPageLayoutQueryResult = Apollo.QueryResult<TeamPageLayoutQuery, TeamPageLayoutQueryVariables>;