import { ComponentPropsWithoutRef } from 'react'

export interface ImageProps extends ComponentPropsWithoutRef<'img'> {
  desc?: string
  size?: number
  background?: string
  circle?: boolean
}

const placeholder = `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1"><rect fill="gray" fill-opacity=".1" width="1" height="1"/></svg>`

export const Image: React.FC<ImageProps> = ({
  src,
  size,
  desc,
  background,
  circle = false,
  ...props
}) => {
  return (
    <img
      src={src ?? placeholder}
      width={size}
      height={size}
      alt={desc}
      title={desc}
      loading="lazy"
      onLoad={
        src && background
          ? (e) => e.currentTarget.classList.add(background)
          : undefined
      }
      style={{ borderRadius: circle ? '50%' : '10%' }}
      {...props}
    />
  )
}
