// https://stackoverflow.com/a/7394787/13151903
function _decodeHtml(html: string) {
  if (typeof html !== 'string' || typeof document === 'undefined') {
    return html
  }
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export function decodeHtml(html: string, rounds = 1) {
  let value = html
  for (let i = 0; i < rounds; i++) {
    value = _decodeHtml(value)
  }
  return value
}
