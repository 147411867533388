import { FormEvent } from 'react'

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function calcTextWidth(text: string, fontSize: string) {
  // re-use canvas object for better performance
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  if (!context) {
    return 0
  }

  context.font = `normal ${fontSize} Roboto`
  const { width } = context.measureText(text)
  canvas.remove()

  return width
}

export function getFormValues(e: FormEvent<HTMLFormElement>) {
  const formData = new FormData(e.currentTarget)
  return Object.fromEntries(formData.entries()) as any
}
