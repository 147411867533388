import { Params } from 'static-path'
import { Merge } from 'type-fest'
import urlcat from 'urlcat'

import { useTranslation } from '@yordlelabs/shared/web/util'
import { createUseRouterQuery } from '@yordlelabs/shared/web/util'
import { Link } from '@yordlelabs/website/util-components'

import { FlexList } from '../List'
import { FilterChip } from '../chip'

export function createTabView<
  SubPath extends string,
  TabMap,
  Tab extends keyof TabMap & string,
  DefaultTab extends Tab,
  UrlQueryWithTab extends Merge<
    Params<SubPath>,
    { tab?: [Exclude<Tab, DefaultTab>] }
  >,
>(scope: string, subpath: SubPath, tabMap: TabMap, defaultTab: DefaultTab) {
  const tabs = Object.keys(tabMap) as Tab[]

  const useRouterQuery = createUseRouterQuery<UrlQueryWithTab>()

  function getTabFromQuery(query: UrlQueryWithTab) {
    const [currentTab = defaultTab] = query.tab ?? []
    return currentTab as Tab
  }

  function useCurrentTab() {
    const query = useRouterQuery()
    return getTabFromQuery(query)
  }

  const TabItem: React.FC<{ tab: Tab }> = ({ tab }) => {
    const { t } = useTranslation()
    const { tab: _, ...query } = useRouterQuery()
    const currentTab = useCurrentTab()

    return (
      <FilterChip
        renderAs={Link}
        selected={tab === currentTab}
        href={urlcat(
          `/${scope}${subpath}${tab === defaultTab ? '' : `/${tab}`}`,
          query,
        )}
      >
        {t(tab)}
      </FilterChip>
    )
  }

  const Tablist: React.FC = () => {
    return (
      <FlexList kind="chip" className="mt-6 mb-4">
        {tabs.map((tab) => (
          <TabItem key={tab} tab={tab} />
        ))}
      </FlexList>
    )
  }

  const Layout: React.FC = ({ children }) => {
    const currentTab = useCurrentTab()
    return (
      <div className="container">
        {children}
        <Tablist />
        <div>{tabMap[currentTab]}</div>
      </div>
    )
  }

  return Layout
}
