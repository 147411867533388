import clsx from 'clsx'
import { useRef } from 'react'

export const StateLayer: React.FC = () => {
  const ref = useRef<HTMLSpanElement>(null)

  return (
    <span ref={ref}>
      <span
        className={clsx(
          'absolute inset-0 bg-current opacity-0 hover:opacity-hover active:opacity-pressed',
        )}
      ></span>
    </span>
  )
}
