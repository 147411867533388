// eslint-disable-next-line import/no-anonymous-default-export
export default `
@REM prevent echoing commands
@REM https://learn.microsoft.com/en-us/windows-server/administration/windows-commands/echo
@echo off

setlocal enableextensions enabledelayedexpansion
set GAME_PATH="C:\\Riot Games\\League of Legends"

if exist "{path}" (
	SET GAME_PATH={path})
cls

if exist "%GAME_PATH%\\Game" (
	cd /d "!GAME_PATH!\\Config"
	for /F "delims=" %%a in ('find "        locale: " LeagueClientSettings.yaml') do set "locale=%%a"
	for /F "tokens=2 delims=: " %%a in ("!locale!") do set "locale=%%a"

	cd /d "!GAME_PATH!\\Game"

	if exist "League of Legends.exe" (
		@start "" "League of Legends.exe" "spectator {authority} {key} {gameId} {platform}" "-UseRads" "-Locale=!locale!" "-GameBaseDir=.."
	)
)

@REM keep the window open
@REM https://learn.microsoft.com/en-us/windows-server/administration/windows-commands/pause
@pause
`
