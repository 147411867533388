import { useEffect, useState } from 'react'

import { mainAPI } from '@yordlelabs/ipc/preload'

export function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState<boolean>()
  // Use `isDesktop` to execute desktop-related code only after component mounted
  // to avoid hydration mismatching.
  useEffect(() => {
    setIsDesktop(process.env.NEXT_PUBLIC_MOCK_DESKTOP ? true : !!mainAPI)
  }, [])
  return isDesktop
}
