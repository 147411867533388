import { __IS_SERVER__ } from '@yordlelabs/util'

export * from './main.d'

export type WindowName = 'main'
export type ApiName = 'main'

function getPreload<T extends `${ApiName}API`>(name: T) {
  return __IS_SERVER__ ? undefined : window[name]
}

export const mainAPI = getPreload('mainAPI')
