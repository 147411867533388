import ms from 'ms'
import { Riot, general } from 'poro'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'
import useSWRImmutable from 'swr/immutable'

import {
  createAtom,
  createPersistAtom,
  interval,
} from '@yordlelabs/shared/web/util'
import { useMetaFile } from '@yordlelabs/shared/web/util-riot'
import { platforms } from '@yordlelabs/spectate/utils'

export const [nowState, useNow, useNowState, useSetNow] = createAtom(
  'now',
  Date.now(),
)

function useTimer() {
  const setNow = useSetNow()

  useEffect(() => {
    return interval(() => {
      // Heavy throttling of chained JS timers beginning in Chrome 88
      // https://developer.chrome.com/blog/timer-throttling-in-chrome-88/
      setNow(Date.now())
    }, ms('1s'))
  }, [setNow])
}

export const [
  platformState,
  usePlatform,
  usePlatformState,
  useSetPlatform,
  useRestorePlatform,
] = createPersistAtom('platform', platforms[0])

export const [
  versionState,
  useVersion,
  useVersionState,
  useSetVersion,
  useRestoreVersion,
] = createPersistAtom<string>('version')

function useLoadVersion() {
  const platform = usePlatform()
  const setVersion = useSetRecoilState(versionState)
  useSWRImmutable<Riot.General.Realm>(
    platform ? general.realm(platform) : null,
    {
      onSuccess: (json) => {
        const version = json.v
        console.log('version', version)
        setVersion(version)
      },
    },
  )
}

export const [
  championsState,
  useChampions,
  useChampionsState,
  useSetChampions,
] = createAtom<Riot.Data.BriefChampion[]>('champions')

function useLoadChampions() {
  const setChampions = useSetChampions()
  useMetaFile('champion', 'default', undefined, {
    onSuccess: (json) => {
      const champions = Object.values(json.data)
      console.log('champions', champions)
      setChampions(champions)
    },
  })
}

export function InitRecoil() {
  useTimer()
  useRestorePlatform()
  useRestoreVersion()
  useLoadVersion()
  useLoadChampions()
  return null
}
