import { ComponentProps, classed, deriveClassed } from '@tw-classed/react'
import clsx from 'clsx'
import { IconType } from 'react-icons'

import { StateLayer } from '../StateLayer'

interface BaseButtonProps extends ComponentProps<typeof BaseButton> {
  Icon?: IconType
}

const BaseButton = classed.button(
  'flex items-center justify-center rounded-md relative overflow-hidden',
  'disabled:text-on-disabled disabled:bg-disabled',
  {
    variants: {
      variant: {
        filled: 'bg-primary text-on-primary',
        outlined: 'text-primary outlined',
        text: '!px-3 !py-2.5 text-primary',
        tonal: 'bg-secondary-container text-on-secondary-container',
        icon: '!p-1 text-on-surface-variant',
      },
      size: {
        md: 'px-6 py-2.5',
        sm: 'px-4 py-1.5',
      },
    },
    defaultVariants: {
      variant: 'tonal',
      size: 'md',
    },
  },
)

export type ButtonProps = ComponentProps<typeof Button>
export const Button = deriveClassed<typeof BaseButton, BaseButtonProps>(
  ({ children, Icon, className, ...restProps }, ref) => {
    const isIcon = !children
    const { disabled } = restProps

    return (
      <BaseButton
        className={clsx(isIcon || 'typescale-label-large', className)}
        ref={ref}
        {...restProps}
      >
        {disabled || <StateLayer />}
        {Icon ? (
          <Icon size={isIcon ? 24 : 18} className={clsx(isIcon || 'mr-2')} />
        ) : null}
        {children}
      </BaseButton>
    )
  },
)
