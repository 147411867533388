import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

export function formatDuration(duration: number) {
  return dayjs.duration(duration).format('m:ss')
}

export function fromNow(date: dayjs.ConfigType) {
  return dayjs(date).fromNow()
}

export function formatSimpleDateTime(date: dayjs.ConfigType) {
  return dayjs(date).format('MM-DD HH:mm')
}

export function formatDateTime(date: dayjs.ConfigType) {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}

export function formatTime(date: dayjs.ConfigType, timezone?: string) {
  let d = dayjs(date)
  if (timezone) {
    d = d.tz(timezone)
  }
  return d.format('HH:mm')
}

export function formatSimpleDate(date: dayjs.ConfigType) {
  return dayjs(date).format('MM-DD')
}

export function formatDate(date: dayjs.ConfigType) {
  return dayjs(date).format('YYYY-MM-DD')
}
