import { Popover as HeadlessPopover } from '@headlessui/react'
import clsx from 'clsx'
import React from 'react'
import { IconType } from 'react-icons'

import { IconButton } from './button'
import { ClassName } from './utils'

export interface PopoverProps extends ClassName {
  Icon: IconType
}

export const Popover: React.FC<PopoverProps> = ({
  Icon,
  children,
  className,
}) => {
  return (
    <HeadlessPopover className={clsx('relative', className)}>
      <HeadlessPopover.Button as="div">
        <IconButton Icon={Icon} />
      </HeadlessPopover.Button>

      <HeadlessPopover.Panel
        className={
          'absolute right-0 mt-2 bg-surface2 py-3 px-4 shadow-2 z-10 rounded space-y-3'
        }
      >
        {children}
      </HeadlessPopover.Panel>
    </HeadlessPopover>
  )
}

export default Popover
