export function uniq<T>(array: T[]) {
  return Array.from(new Set(array))
}

export function capitalize(s: string) {
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1)
}

export function last<T>(array: Readonly<T[]>) {
  return array.slice(-1)[0]
}

export function group<T, K extends string | number>(
  array: T[],
  fn: (item: T) => K,
) {
  return array.reduce(
    (acc, item) => {
      const key = fn(item)
      acc[key] = (acc[key] || []).concat(item)
      return acc
    },
    {} as Record<K, T[]>,
  )
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {}

export function setImmediateInterval(
  callback: (...args: any[]) => void,
  ms: number,
) {
  callback()
  setInterval(callback, ms)
}
