import { zodResolver } from '@hookform/resolvers/zod'
import { DefaultValues, useForm } from 'react-hook-form'
import { AnyZodObject, z } from 'zod'

export function useZodForm<
  Schema extends AnyZodObject,
  FormData = z.infer<Schema>,
>(schema: Schema, defaultValues: DefaultValues<FormData>) {
  return useForm<FormData>({
    resolver: zodResolver(schema),
    defaultValues,
  })
}
