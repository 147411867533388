import clsx from 'clsx'
import { ComponentProps } from 'react'
import { type FieldError as TFieldError } from 'react-hook-form'

import { useTranslation } from '../../../utils'
import { Tag } from '../Tag'

interface FormProps extends ComponentProps<'form'> {}
export const Form: React.FC<FormProps> = ({ className, ...props }) => {
  return (
    <form className={clsx('flex flex-col space-y-4', className)} {...props} />
  )
}

interface LabelProps {
  name: string
}
export const Label: React.FC<LabelProps> = ({ name }) => {
  const { t } = useTranslation()
  return (
    <label
      className={'typescale-label-large text-on-surface-variant select-none'}
      htmlFor={name}
    >
      {t(name)}
    </label>
  )
}

interface TipsProps {
  name: string
  tag?: string
}
export const Tips: React.FC<TipsProps> = ({ name, tag }) => {
  const { t } = useTranslation()
  return (
    <p className="typescale-body-small text-outline space-x-2">
      {tag && <Tag>{tag}</Tag>}
      <span>{t(name)}</span>
    </p>
  )
}

interface SubformHeadingProps {}
export const SubformHeading: React.FC<SubformHeadingProps> = ({ children }) => {
  return <h2 className="typescale-title-medium mb-2">{children}</h2>
}

interface ErrorProps {
  error?: TFieldError
}
export const FieldError: React.FC<ErrorProps> = ({ error }) => {
  if (!error) return null

  return <p className="typescale-body-small text-error">{error.message}</p>
}
