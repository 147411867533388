import { useEffect, useState } from 'react'

import { mainAPI } from '@yordlelabs/ipc/preload'

export function useAppVersion() {
  const [version, setVersion] = useState<string>()

  useEffect(() => {
    mainAPI?.invokeGetVersion?.().then(setVersion)
  }, [])

  return version
}
