import { NextSeo, NextSeoProps } from 'next-seo'
import { TranslationQuery } from 'next-translate'

import { useTranslation } from '@yordlelabs/shared/web/util'

export interface SeoProps extends Omit<NextSeoProps, 'title'> {
  scope?: string
  title?: string | string[]
  translationQuery?: TranslationQuery
}

export const Seo: React.FC<SeoProps> = ({
  scope,
  translationQuery,
  title,
  description,
  ...seoProps
}) => {
  const { t } = useTranslation()
  title = title ?? t(`page.${scope}.title`, translationQuery)

  if (!title) {
    throw new Error('Title is empty')
  }

  if (!Array.isArray(title)) {
    title = [title]
  }

  return (
    <NextSeo
      title={[...title, t('common:yordlelabs')].join(' - ')}
      description={description ?? t('desc')}
      {...seoProps}
    />
  )
}
