import { forwardRef } from 'react'

import {
  TeamLinkComponentNameArgsFragment,
  TeamLinkComponentShortArgsFragment,
} from '@yordlelabs/shared/web/data-access/server'
import { Link, LinkPropsWithoutHref } from '@yordlelabs/website/util-components'

interface TeamLinkProps extends LinkPropsWithoutHref {
  team:
    | string
    | TeamLinkComponentShortArgsFragment
    | TeamLinkComponentNameArgsFragment
}

export const TeamLink = forwardRef<HTMLAnchorElement, TeamLinkProps>(
  function TeamLink({ team, children, ...props }, ref) {
    const isString = typeof team === 'string'

    const pageName = isString ? team : team.OverviewPage
    const nameToDisplay = isString
      ? team
      : 'Short' in team
        ? team.Short
        : team.Name ?? pageName

    return (
      <Link href={`/team/${pageName}`} title={pageName} {...props} ref={ref}>
        {children ?? nameToDisplay}
      </Link>
    )
  },
)
