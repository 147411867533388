import { Riot } from 'poro'

import scriptTemplate from './spectate.bat'

export function getSpectatingScript(
  game: {
    gameId: number
    platformId: string
    observers: { encryptionKey: string }
  },
  path = '',
  /**
   * @default spectator.{platform}.lol.pvp.net:8080
   * @see https://developer.mozilla.org/en-US/docs/Learn/Common_questions/Web_mechanics/What_is_a_URL#authority
   */
  authority?: string,
) {
  const platform = game.platformId.toLowerCase()
  const officialAuthority = `spectator.${platform}.lol.pvp.net:8080`

  return scriptTemplate
    .replaceAll('{path}', path)
    .replace('{authority}', authority ?? officialAuthority)
    .replace('{platform}', game.platformId)
    .replace('{gameId}', String(game.gameId))
    .replace('{key}', game.observers.encryptionKey)
}

export const platforms = process.env.NEXT_PUBLIC_PLATFORMS?.split(
  ',',
) as Riot.Platform[]

// https://en.wikipedia.org/wiki/Tz_database
export const mapToTimeZone = {
  [Riot.Platform.EUW]: 'Europe/London',
  [Riot.Platform.KR]: 'Asia/Seoul',
} as Record<Riot.Platform, string | undefined>

export function getGameDuration(gameStartTime: number, now = Date.now()) {
  return now - gameStartTime
}
