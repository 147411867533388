import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import { useTranslation } from '@yordlelabs/shared/web/util'

import { Label } from '../form'

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
  variant?: 'text' | 'hotkey' | 'checkbox'
}

const typeStyleMap = {
  text: {
    container: 'flex-col gap-2',
    input:
      'rounded bg-transparent typescale-body-medium text-on-surface px-3 py-2 placeholder-outline outlined',
  },
  hotkey: {
    container: 'justify-between',
    input:
      'w-28 h-6 rounded-sm bg-transparent typescale-body-medium text-on-surface px-2 py-2 placeholder-outline outlined',
  },
  checkbox: {
    container: 'justify-between',
    input: 'w-5 h-5 shrink-0',
  },
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { className, name, type = 'text', variant, ...restProps },
  ref,
) {
  const { t } = useTranslation()
  const desc = t(`${name}_desc`)
  const hasTranslation = desc.includes(' ') || desc.includes('，')

  if (!variant) {
    if (type === 'checkbox') {
      variant ??= 'checkbox'
    }
    variant ??= 'text'
  }

  return (
    <div className={clsx('flex', typeStyleMap[variant].container)}>
      {name && (
        <div className="space-y-1 leading-none">
          {name && <Label name={name} />}
          {hasTranslation && (
            <div className="typescale-body-small text-outline select-none">
              {desc}
            </div>
          )}
        </div>
      )}
      <input
        className={clsx(typeStyleMap[variant].input, className)}
        type={type}
        id={name}
        name={name}
        ref={ref}
        {...restProps}
      />
    </div>
  )
})
