import { forwardRef } from 'react'
import { SetOptional } from 'type-fest'

import { PlayerLinkComponentArgsFragment } from '@yordlelabs/shared/web/data-access/server'
import { Link, LinkPropsWithoutHref } from '@yordlelabs/website/util-components'

interface PlayerLinkProps extends LinkPropsWithoutHref {
  player: SetOptional<PlayerLinkComponentArgsFragment, 'ID'> | string
}

export const PlayerLink = forwardRef<HTMLAnchorElement, PlayerLinkProps>(
  function PlayerLink({ player, children, ...props }, ref) {
    const isString = typeof player === 'string'

    const pageName = isString ? player : player.OverviewPage
    const displayName = isString ? player : player.ID ?? player.OverviewPage

    return (
      <Link href={`/player/${pageName}`} title={pageName} {...props} ref={ref}>
        {children ?? displayName}
      </Link>
    )
  },
)
