
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import './styles.css'

import { ApolloProvider } from '@apollo/client'
import { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import { NextQueryParamProvider } from 'next-query-params'
import { Toaster } from 'react-hot-toast'
import { RecoilRoot } from 'recoil'
import { SWRConfig, useSWRConfig } from 'swr'

import { InitRecoil } from '@yordlelabs/shared/web/data-access/client'
import { apolloClient } from '@yordlelabs/shared/web/data-access/server'

import { GoogleTagManager } from '../components/base/GoogleTagManager'
import { Layout } from '../components/layout'

// https://swr.vercel.app/docs/error-handling#status-code-and-error-object
const fetcher = async (url: string) => {
  const res = await fetch(url)

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    // Attach extra info to the error object.
    throw {
      info: await res.json(),
      status: res.status,
    }
  }

  return res.json()
}

function CustomApp({
  Component,
  pageProps: { session, ...pageProps },
}: AppProps<{ session: any }>) {
  const { onErrorRetry } = useSWRConfig()

  return (
    <SWRConfig
      value={{
        fetcher,
        onErrorRetry(...args) {
          const [err] = args
          if (err.status === 401) return
          onErrorRetry(...args)
        },
      }}
    >
      <GoogleTagManager />
      <NextQueryParamProvider>
        <ApolloProvider client={apolloClient}>
          <RecoilRoot>
            <SessionProvider session={session}>
              <InitRecoil />
              <Layout>
                <Component {...pageProps} />
                <Toaster />
              </Layout>
            </SessionProvider>
          </RecoilRoot>
        </ApolloProvider>
      </NextQueryParamProvider>
    </SWRConfig>
  )
}

const __Page_Next_Translate__ = CustomApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  