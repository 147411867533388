import clsx from 'clsx'
import { useRouter } from 'next/router'

import { Header } from './Header'
import { Navigation, useNav } from './Navigation'
import { StatusBar } from './StatusBar'

export const Layout: React.FC = ({ children }) => {
  const { pathname } = useRouter()
  const isNavOpen = useNav()

  const withoutLayout = ['/overlay', '/obs'].some((r) => pathname.startsWith(r))
  if (withoutLayout) return <>{children}</>

  return (
    <div className="min-h-screen bg-background relative">
      <Header className="h-12 sticky top-0 bg-surface1 z-30" />
      <Navigation className={clsx('w-48 fixed top-12 bottom-6 z-20')} />
      <main
        className={clsx(
          isNavOpen === undefined ? 'sm:ml-48' : isNavOpen ? 'sm:ml-48' : '',
          'sm:p-3 sm:pb-9',
        )}
      >
        {children}
      </main>
      <StatusBar className="hidden sm:block fixed bottom-0 inset-x-0" />
      {/* <DeployNotification /> */}
    </div>
  )
}
