import { LeagueEntry, Prisma } from '@prisma/client'
import { Riot } from 'poro'

export enum SpectateEvent {
  Init = 'init',
  Add = 'add',
  Remove = 'remove',
}

export interface ExtendedCurrentGameParticipant
  extends Riot.DTO.CurrentGameParticipant {
  player?: Prisma.PlayerGetPayload<{ include: { team: true } }> | null
  leagueEntry?: LeagueEntry | null
  gameName: string
  tagLine: string
}

export interface ExtendedCurrentGameInfo extends Riot.DTO.CurrentGameInfo {
  participants: ExtendedCurrentGameParticipant[]
}

export enum Modifier {
  Alt = 'Alt',
  Control = 'Ctrl',
  Shift = 'Shift',
  Meta = 'Meta',
}

export interface Hotkey {
  modifiers: Modifier[]
  key: string
}
