import { useCallback } from 'react'

import { useChampions } from '../../data-access/client'

// TODO: process `patch` argument
export function useFindChampion(_patch?: string | null) {
  const champions = useChampions()

  return useCallback(
    (championIdOrName: number | string) => {
      return champions?.find((c) =>
        [Number(c.key), c.name].includes(championIdOrName),
      )
    },
    [champions],
  )
}
