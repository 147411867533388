import { CommunityDragon } from 'poro'

import { useLocale } from '@yordlelabs/shared/web/util'

const map = new Map<string, CommunityDragon>()

export function useCommunityDragon() {
  const locale = useLocale()
  return getCommunityDragon(locale)
}

function getCommunityDragon(language: string) {
  const key = language
  let cdragon = map.get(key)
  if (!cdragon) {
    // Due to the community update delay, use 'latest' to ensure resources are always available
    cdragon = new CommunityDragon('latest', language)
    map.set(key, cdragon)
  }
  return cdragon
}
