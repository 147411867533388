import * as Avatar from '@radix-ui/react-avatar'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { signOut, useSession } from 'next-auth/react'
import React from 'react'
import {
  MdMoreVert,
  MdOutlineDarkMode,
  MdOutlineLightMode,
} from 'react-icons/md'
import { RiUser3Line } from 'react-icons/ri'
import useDarkMode from 'use-dark-mode'

import { usePlatformState } from '@yordlelabs/shared/web/data-access/client'
import {
  ClassName,
  IconButton,
  Popover,
  Select,
} from '@yordlelabs/shared/web/ui'
import {
  formatDateTime,
  useAfterMount,
  useTranslation,
  useUser,
} from '@yordlelabs/shared/web/util'
import { platforms } from '@yordlelabs/spectate/utils'
import { __IS_SERVER__ } from '@yordlelabs/util'

import { localeMap, locales } from '../../../i18n'
import { Link, WithDivider } from '../base'
import * as DropdownMenu from '../ui/Dropdown'

import { NavigationToggle } from './Navigation'
import { Search } from './Search'
import { Update } from './Update'

type Locale = keyof typeof localeMap

export const Header: React.FC<ClassName> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <header
      className={clsx('flex items-center justify-between px-3', className)}
    >
      <div className="flex items-center gap-2">
        <NavigationToggle />

        <span className="typescale-title-medium text-on-surface">
          {t('yordlelabs')}
        </span>
      </div>

      <div className="flex gap-4">
        <div className="hidden sm:flex items-center gap-4">
          <Platform />
          <Language />
        </div>

        <div className="flex gap-2">
          <Update />
          <User />
          <Search />
          <ColorScheme />
          <Popover Icon={MdMoreVert} className="sm:hidden">
            <Platform />
            <Language />
          </Popover>
        </div>
      </div>
    </header>
  )
}

const Platform: React.FC = () => {
  const [platform, setPlatform] = usePlatformState()
  return (
    <Select
      name="server"
      value={platform}
      options={platforms}
      onChange={setPlatform}
      labelClassName="sm:hidden"
    />
  )
}

const Language: React.FC = () => {
  const { asPath, locale: currLocale, push } = useRouter()
  return (
    <Select
      name="language"
      defaultValue={currLocale}
      options={locales}
      onChange={(locale) => push(asPath, undefined, { locale })}
      getId={(locale) => localeMap[locale as Locale]}
      labelClassName="sm:hidden"
    />
  )
}

const ColorScheme: React.FC = () => {
  const { value, toggle } = useDarkMode(false, {
    classNameDark: 'dark',
    classNameLight: 'light',
    element: __IS_SERVER__ ? undefined : document.documentElement,
  })

  return useAfterMount(
    <IconButton
      Icon={value ? MdOutlineDarkMode : MdOutlineLightMode}
      onClick={toggle}
    />,
  )
}

const User: React.FC = () => {
  const { data: session } = useSession()
  const { t } = useTranslation()

  const user = session?.user

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <Avatar.Root>
          {user?.image ? (
            <img
              src={user.image}
              alt={user.name ?? 'User'}
              className="w-8 h-8 rounded-full cursor-pointer"
              // https://stackoverflow.com/questions/56242788/http-403-on-images-loaded-from-googleusercontent-com
              referrerPolicy="no-referrer"
            />
          ) : (
            <IconButton Icon={RiUser3Line} />
          )}
        </Avatar.Root>
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content className="mt-2 md:w-[240px]" align="end">
          <WithDivider divider={<DropdownMenu.Separator />}>
            {user ? (
              <div className="flex items-center justify-start gap-2 p-4">
                <div className="flex flex-col space-y-1 leading-none">
                  {user.name && (
                    <p className="font-medium text-on-surface">{user.name}</p>
                  )}
                  {user.email && (
                    <p className="w-[200px] truncate text-sm text-on-surface-variant">
                      {user.email}
                    </p>
                  )}
                  <Subscription />
                </div>
              </div>
            ) : (
              <div>
                <DropdownMenu.Item>
                  <Link href="/login" className="w-full">
                    {t('login')}
                  </Link>
                </DropdownMenu.Item>
              </div>
            )}

            <>
              {user && (
                <DropdownMenu.Item>
                  <Link
                    href={process.env.NEXT_PUBLIC_SUBSCRIPTION!}
                    className="w-full"
                  >
                    {t('subscription')}
                  </Link>
                </DropdownMenu.Item>
              )}
              <DropdownMenu.Item>
                <Link href="/settings" className="w-full">
                  {t('settings')}
                </Link>
              </DropdownMenu.Item>
            </>

            {user && (
              <DropdownMenu.Item
                onSelect={(event) => {
                  event.preventDefault()
                  signOut()
                }}
              >
                {t('signout')}
              </DropdownMenu.Item>
            )}
          </WithDivider>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

function Subscription() {
  const { user } = useUser()
  const { t } = useTranslation()

  if (!user?.subscriptionEnd) return null

  return (
    <div className="typescale-body-small text-on-surface-variant">
      {t('expire_date')}: {formatDateTime(user.subscriptionEnd)}
    </div>
  )
}
