import { useCallback, useState } from 'react'

export function useBoolean(initialState: boolean) {
  const [state, setState] = useState(initialState)

  const setToTrue = useCallback(() => {
    setState(true)
  }, [])
  const setToFalse = useCallback(() => {
    setState(false)
  }, [])

  return [state, setToTrue, setToFalse] as const
}
