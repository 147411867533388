/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ZodType } from 'zod'
type AnyFC = React.FC<any>

// @ts-ignore
ZodType.prototype.component = function (Component: AnyFC) {
  this._component = Component
  return this
}

// @ts-ignore
ZodType.prototype.desktop = function () {
  // Ensure validation pass in web.
  const type = this.optional()
  type._desktop = true
  return type
}
