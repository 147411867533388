const localeMap = {
  'en-US': 'English',
  'zh-CN': '简体中文',
}

const locales = Object.keys(localeMap)

module.exports = {
  localeMap,
  locales,
  defaultLocale: locales[0],
  extensionsRgx: /\.page\.tsx$/,
  pages: {
    '*': ['common'],
  },
}
