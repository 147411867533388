import { Riot } from 'poro'
import { SWRConfiguration } from 'swr'
import useSWRImmutable from 'swr/immutable'

import { Patch } from './patch'
import { DataDragonType, useDataDragon } from './useDataDragon'

export function useMetaFile<T extends Riot.Data.MetaFile>(
  metafile: T,
  type: DataDragonType,
  patch?: Patch,
  config?: SWRConfiguration<Riot.Data.MetaFileSchemaMap[T]>,
) {
  const ddragon = useDataDragon(type, patch)
  const { data } = useSWRImmutable<Riot.Data.MetaFileSchemaMap[T]>(
    ddragon && ddragon.meta(metafile),
    config,
  )
  return data
}
