import { useEffect } from 'react'
import {
  AtomEffect,
  RecoilState,
  SetterOrUpdater,
  atom,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil'

function localStorageEffect<T>(key: string): AtomEffect<T> {
  return ({ onSet }) => {
    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue))
    })
  }
}

function safeJsonParse<T = any>(s: string): T | undefined {
  try {
    return JSON.parse(s)
  } catch (err) {
    return
  }
}

type CreateRecoilHooksReturn<T> = [
  () => T,
  () => [T, SetterOrUpdater<T>],
  () => SetterOrUpdater<T>,
]

function createRecoilHooks<T>(
  state: RecoilState<T>,
): CreateRecoilHooksReturn<T> {
  const useValue = () => useRecoilValue(state)
  const useState = () => useRecoilState(state)
  const useSetState = () => useSetRecoilState(state)
  return [useValue, useState, useSetState]
}

type CreateAtomReturn<T> = [RecoilState<T>, ...CreateRecoilHooksReturn<T>]

export function createAtom<T>(key: string): CreateAtomReturn<T | undefined>
export function createAtom<T>(key: string, defaultValue: T): CreateAtomReturn<T>
export function createAtom<T>(key: string, defaultValue?: T) {
  const state = atom({
    key,
    default: defaultValue,
  })

  return [state, ...createRecoilHooks(state)]
}

export function createPersistAtom<T>(key: string, defaultValue?: T) {
  const state = atom<T | undefined>({
    key,
    // Ensure default state consistent in server and client.
    // See: https://observablehq.com/@werehamster/avoiding-hydration-mismatch-when-using-react-hooks
    default: undefined,
    effects_UNSTABLE: [localStorageEffect(key)],
  })

  const hooks = createRecoilHooks(state)

  function useRestoreState() {
    const setState = hooks[2]()

    // Restore state after component is mounted rather than in atom effects
    // to avoid hydration mismatching.
    useEffect(() => {
      const savedValue = localStorage.getItem(key)
      const value = safeJsonParse<T>(savedValue ?? '') ?? defaultValue
      if (value) {
        setState(value)
      }
    }, [setState])
  }

  return [state, ...hooks, useRestoreState] as const
}
