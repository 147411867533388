import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { MdSearch } from 'react-icons/md'

import {
  GlobalSearchQuery,
  useGlobalSearchQuery,
} from '@yordlelabs/shared/web/data-access/server'
import {
  IconButton,
  Modal,
  PlayerLink,
  TeamLink,
} from '@yordlelabs/shared/web/ui'
import { useTranslation } from '@yordlelabs/shared/web/util'
import { useBoolean } from '@yordlelabs/shared/web/util'
import { Maybe } from '@yordlelabs/util'

export const Search: React.FC = () => {
  const [isOpen, open, close] = useBoolean(false)
  const [keyword, setKeyword] = useState<string>()

  const { data } = useGlobalSearchQuery({
    variables: { keyword },
    skip: !keyword,
  })

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setKeyword(e.currentTarget.value)
  }, [])

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        close()
      }

      if (e.key === 'k' && e.ctrlKey) {
        open()
        e.preventDefault()
      }
    }
    document.body.addEventListener('keydown', handleKeyDown)

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown)
    }
  }, [close, open])

  return (
    <>
      <IconButton onClick={open} Icon={MdSearch} />

      <Modal isOpen={isOpen} close={close}>
        <input
          type="search"
          className="px-6 py-4 bg-surface1 typescale-title-large text-on-surface placeholder-outline rounded-lg"
          placeholder="Search"
          onChange={handleChange}
        />
        <div className="mt-6 space-y-4">
          <Block name="players" items={data?.players} close={close} />
          <Block name="teams" items={data?.teams} close={close} />
        </div>
      </Modal>
    </>
  )
}

type BlockProps = { close: () => void } & (
  | {
      name: 'players'
      items: Maybe<GlobalSearchQuery['players']>
    }
  | {
      name: 'teams'
      items: Maybe<GlobalSearchQuery['teams']>
    }
)

export const Block: React.FC<BlockProps> = ({ name, items, close }) => {
  const { t } = useTranslation()
  if (!items?.length) return null

  const linkProps = {
    className:
      'block px-3 py-1 rounded typescale-body-large text-on-surface focus-visible:relative',
    onClick: close,
  }

  return (
    <div>
      <div className="typescale-title-medium text-on-surface-variant">
        {t(name)}
      </div>
      <menu className="mt-1">
        {items.map((item) => (
          <li key={item.OverviewPage}>
            {name === 'players' ? (
              <PlayerLink player={item.OverviewPage} {...linkProps} />
            ) : (
              <TeamLink team={item.OverviewPage} {...linkProps} />
            )}
          </li>
        ))}
      </menu>
    </div>
  )
}
