import { ParsedUrlQuery } from 'querystring'

import { useRouter } from 'next/router'

export function createUseRouterQuery<T extends ParsedUrlQuery>() {
  return function useRouterQuery() {
    const { query } = useRouter()
    return query as T
  }
}
