import useLocalStorageState from 'use-local-storage-state'
import * as z from 'zod'
import '@yordlelabs/shared/web/zod'

export const SettingsSchema = z.object({
  game_path: z.string(),
})

export type Settings = z.TypeOf<typeof SettingsSchema>

export function useSettings() {
  return useLocalStorageState<Settings>('settings')
}
