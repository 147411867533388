import clsx from 'clsx'
import React, { ComponentPropsWithoutRef } from 'react'

interface ListProps extends ComponentPropsWithoutRef<'ul'> {
  children: React.ReactElement[] | false | null | undefined
}

const List = ({ children, ...props }: ListProps) => {
  return (
    <ul {...props}>
      {children &&
        React.Children.map(children, (child) => (
          <li key={child.key}>{child}</li>
        ))}
    </ul>
  )
}

const flexKindMap = {
  chip: 'gap-x-2 gap-y-4',
}

type FlexKind = keyof typeof flexKindMap

interface FlexListProps extends ListProps {
  kind: FlexKind
}

export const FlexList = ({ className, kind, ...props }: FlexListProps) => {
  return (
    <List
      className={clsx('flex flex-wrap', flexKindMap[kind], className)}
      {...props}
    />
  )
}

const gridKindMap = {
  card: 'gap-4',
}

type GridKind = keyof typeof gridKindMap

interface GridListProps extends ListProps {
  kind: GridKind
  minWidth: number
}

export const GridList = ({
  className,
  kind,
  minWidth,
  ...props
}: GridListProps) => {
  return (
    <List
      className={clsx('grid', gridKindMap[kind], className)}
      style={{
        gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}px, 1fr))`,
      }}
      {...props}
    />
  )
}
