import { useEffect, useMemo } from 'react'
import { useMap } from 'react-use'
import ReconnectingEventSource from 'reconnecting-eventsource'

import { serverurl } from '@yordlelabs/shared/web/util'
import {
  ExtendedCurrentGameInfo,
  SpectateEvent,
} from '@yordlelabs/spectate/types'
import { Maybe } from '@yordlelabs/util'

import { useFindChampion } from '../../utils/hooks/useFindChampion'

export type SubscriptionScope = 'summoner' | 'player' | 'team' | 'platform'

function parseEventData(event: Event) {
  const messageEvent = event as MessageEvent
  return JSON.parse(messageEvent.data)
}

export function useSubscribeCurrentGames(
  scope: SubscriptionScope,
  subpath: Maybe<string>,
) {
  const findChampion = useFindChampion()
  const [gameMap, { set, setAll, remove }] =
    useMap<Record<number, ExtendedCurrentGameInfo>>()
  const games = useMemo(() => Object.values(gameMap).reverse(), [gameMap])

  useEffect(() => {
    if (!subpath) return

    const es = new ReconnectingEventSource(
      serverurl(`/api/spectate/${scope}/${subpath}`),
    )

    es.addEventListener(SpectateEvent.Init as any, (event) => {
      const games: ExtendedCurrentGameInfo[] = parseEventData(event)
      const newGameMap: typeof gameMap = {}

      games.forEach((game) => {
        newGameMap[game.gameId] = game
      })

      console.log('game:init', games)

      setAll(newGameMap)
    })

    es.addEventListener(SpectateEvent.Add as any, (event) => {
      const game: ExtendedCurrentGameInfo = parseEventData(event)
      console.log('game:add', game)
      set(game.gameId, game)
    })

    es.addEventListener(SpectateEvent.Remove as any, (event) => {
      const gameId: number = parseEventData(event)
      console.log('game:remove', gameId)
      remove(gameId)
    })

    return () => {
      es.close()
    }
  }, [findChampion, remove, scope, set, setAll, subpath])

  return games
}
