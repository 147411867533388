import clsx from 'clsx'
import ms from 'ms'
import { useEffect, useState } from 'react'
import { MdOutlineFileDownload } from 'react-icons/md'

import { mainAPI } from '@yordlelabs/ipc/preload'
import { setImmediateInterval } from '@yordlelabs/util'

import { useTranslation } from '../../utils'
import { IconButton } from '../ui'

export const Update: React.FC = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    mainAPI?.onUpdateDownloaded(() => {
      setUpdateAvailable(true)
    })
  }, [])

  useEffect(() => {
    setImmediateInterval(() => {
      mainAPI?.invokeCheckForUpdates()
    }, ms('1h'))
  }, [])

  if (!updateAvailable) return null

  return (
    <IconButton
      title={t('relaunch')}
      Icon={MdOutlineFileDownload}
      className={clsx(updateAvailable ? 'text-green-500' : '')}
      onClick={() => {
        mainAPI?.sendRelaunch()
      }}
    />
  )
}
