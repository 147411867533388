import clsx from 'clsx'
import { useRouter } from 'next/router'
import { ComponentProps, useEffect, useState } from 'react'

import { mainAPI } from '@yordlelabs/ipc/preload'

import { useSettings, useVersion } from '../../data-access/client'
import { useAppVersion, useIsDesktop, useTranslation } from '../../utils'
import { ClassName } from '../ui'

export const StatusBar: React.FC<ClassName> = ({ className }) => {
  const isDesktop = useIsDesktop()
  return (
    <div className={clsx('bg-surface1 px-5', className)}>
      <div className="flex gap-[3px]">
        {isDesktop && <Version />}
        {isDesktop && <OverlayServer />}
        {isDesktop && <GameVersion />}
        <GamePath />
        {isDesktop && <ReplayApi />}
      </div>
    </div>
  )
}

const Version: React.FC = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const version = useAppVersion()
  const { t } = useTranslation()

  useEffect(() => {
    mainAPI?.onUpdateDownloaded(() => {
      setUpdateAvailable(true)
      console.log('update downloaded')
    })
  }, [])

  return (
    <StatusButton
      className={clsx(updateAvailable ? 'text-green-500' : '')}
      title={
        updateAvailable
          ? t('statusbar.update_available')
          : `${t('app_version')}: ${version}`
      }
      onClick={() => {
        if (updateAvailable) {
          mainAPI?.sendRelaunch()
        }
      }}
    >
      v{version}
    </StatusButton>
  )
}

const OverlayServer: React.FC = () => {
  const { t } = useTranslation()
  const [url, setUrl] = useState<string>()
  const overlayUrl = `${url}/overlay`

  useEffect(() => {
    if (mainAPI && mainAPI.invokeCheckOverlayServer) {
      mainAPI.invokeCheckOverlayServer().then(setUrl)
    }
  }, [])

  return (
    <StatusButton
      className="mr-auto"
      title={
        url
          ? `${t('spectating_overlay')} ${t('server')} URL: ${overlayUrl}`
          : t('spectating_overlay_is_not_available')
      }
      status={url ? 'info' : 'error'}
      onClick={() => url && window.open(overlayUrl, '_blank')}
    >
      {url ? url.replace('http://localhost', '') : t('spectating_overlay')}
    </StatusButton>
  )
}

const GameVersion: React.FC = () => {
  const [settings] = useSettings()
  const gamePath = settings?.game_path
  const version = useVersion()
  const { t } = useTranslation()
  const [valid, setValid] = useState<boolean>()

  useEffect(() => {
    if (mainAPI && mainAPI.invokeGetGameVersion && version && gamePath) {
      mainAPI.invokeGetGameVersion(gamePath).then((localVersion) => {
        // version: 13.18.1
        // local version: 13.18.5304653+branch.releases-13-18.code.public
        const [major, minor] = version.split('.')
        setValid(localVersion?.startsWith(`${major}.${minor}`))
      })
    }
  }, [gamePath, version])

  return (
    <StatusButton
      title={
        valid ? `${t('game_version')}: ${version}` : t('game_version_not_match')
      }
      status={valid ? 'info' : 'error'}
      disabled={!gamePath || valid === undefined}
    >
      v{version}
    </StatusButton>
  )
}

const GamePath: React.FC = () => {
  const [settings] = useSettings()
  const gamePath = settings?.game_path
  const { push } = useRouter()
  const { t } = useTranslation()
  const [valid, setValid] = useState(!!gamePath)

  useEffect(() => {
    if (mainAPI && mainAPI.invokeCheckGamePath && gamePath) {
      mainAPI.invokeCheckGamePath(gamePath).then(setValid)
    } else {
      setValid(!!gamePath)
    }
  }, [gamePath])

  return (
    <StatusButton
      onClick={() => push('/settings')}
      title={valid ? `${t('game_path')}: ${gamePath}` : t('game_path_is_wrong')}
      status={valid ? 'info' : 'error'}
    >
      {gamePath || t('game_path_is_wrong')}
    </StatusButton>
  )
}

const ReplayApi: React.FC = () => {
  const [replayApi, setReplayApi] = useState<boolean>()
  const [settings] = useSettings()
  const gamePath = settings?.game_path
  const { t } = useTranslation()

  useEffect(() => {
    if (mainAPI && mainAPI.invokeCheckReplayApi && gamePath) {
      mainAPI.invokeCheckReplayApi(gamePath).then(setReplayApi)
    }
  }, [gamePath])

  return (
    <StatusButton
      status={replayApi ? 'info' : 'error'}
      disabled={!gamePath || replayApi === undefined}
      title={'Replay API: ' + (replayApi ? t('enabled') : t('disabled'))}
      onClick={() => {
        mainAPI?.invokeOpenFile({ type: 'game_config', basePath: gamePath })
      }}
    >
      Replay API
    </StatusButton>
  )
}

interface StatusButtonProps extends ComponentProps<'button'> {
  status?: 'success' | 'error' | 'warning' | 'info'
}
const StatusButton: React.FC<StatusButtonProps> = ({
  className,
  children,
  status = 'info',
  ...props
}) => {
  const isError = status === 'error'
  return (
    <button
      className={clsx(
        'text-xs text-on-surface-variant py-1 px-[5px] font-mono',
        'hover:bg-gray-500/30 active:bg-gray-500/40',
        'disabled:pointer-events-none disabled:opacity-50',
        'flex items-center gap-1',
        isError && 'text-error',
        className,
      )}
      {...props}
    >
      {children}
    </button>
  )
}
