import { IconType } from 'react-icons'
import {
  RiCastFill,
  RiFacebookBoxFill,
  RiInstagramLine,
  RiPatreonFill,
  RiQqFill,
  RiRedditLine,
  RiTwitchFill,
  RiTwitterFill,
  RiYoutubeLine,
} from 'react-icons/ri'
import { SiDiscord } from 'react-icons/si'

import { Link } from '@yordlelabs/website/util-components'

type SocialType =
  | 'youtube'
  | 'instagram'
  | 'twitter'
  | 'facebook'
  | 'discord'
  | 'reddit'
  | 'stream'
  | 'patreon'
  | 'qq'
type SocialItem = {
  Icon: IconType
  getUrl?: (input: string) => string
}

const socialMap: Record<string, SocialItem> = {
  youtube: {
    Icon: RiYoutubeLine,
  },
  instagram: {
    Icon: RiInstagramLine,
    getUrl: (username: string) => `https://instagram.com/${username}`,
  },
  twitter: {
    Icon: RiTwitterFill,
    getUrl: (username: string) => `https://twitter.com/${username}`,
  },
  facebook: {
    Icon: RiFacebookBoxFill,
  },
  discord: {
    Icon: SiDiscord,
  },
  reddit: {
    Icon: RiRedditLine,
    getUrl: (subreddit: string) => `https://reddit.com/r/${subreddit}`,
  },
  stream: {
    Icon: RiCastFill,
  },
  patreon: {
    Icon: RiPatreonFill,
  },
  qq: {
    Icon: RiQqFill,
  },
}

interface SocialLinkProps {
  type: SocialType
  href?: string | null
  size?: number
}

export const SocialLink: React.FC<SocialLinkProps> = ({ type, href, size }) => {
  if (!href) return null

  const social = socialMap[type]
  let Icon = social.Icon

  if (type === 'stream' && href.includes('twitch.tv')) {
    Icon = RiTwitchFill
  }

  return (
    <Link
      href={social.getUrl?.(href) ?? href}
      className="text-outline/70 hover:text-on-surface-variant transition"
    >
      <Icon size={size} />
    </Link>
  )
}
