import { ComponentPropsWithoutRef } from 'react'
import { IconType } from 'react-icons'

import { Chip } from './Chip'

interface SuggestiveChipProps extends ComponentPropsWithoutRef<'button'> {
  selected?: boolean
  elevated?: boolean
  Icon?: IconType
}

export const SuggestiveChip: React.FC<SuggestiveChipProps> = ({ ...props }) => {
  return <Chip {...props} />
}
