import { Riot } from 'poro'

import { Link } from '@yordlelabs/website/util-components'

interface OPGGLinkProps {
  platform: Riot.Platform
  gameName: string
  tagLine: string
}

export const OPGGLink: React.FC<OPGGLinkProps> = ({
  platform,
  gameName,
  tagLine,
}) => {
  return (
    <Link
      href={`https://www.op.gg/summoners/${platform.toLocaleLowerCase()}/${gameName}-${tagLine}`}
    >
      OP.GG
    </Link>
  )
}
