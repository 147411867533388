import { ElementType } from 'react'
import { MdCheck } from 'react-icons/md'

import { Chip, ChipProps } from './Chip'

type FilterChipProps<T> = ChipProps<T> & {
  indicator?: boolean
}

export function FilterChip<T extends ElementType = 'button'>({
  Icon,
  indicator = false,
  ...props
}: FilterChipProps<T>) {
  const { selected } = props
  return <Chip Icon={selected && indicator ? MdCheck : Icon} {...props} />
}
